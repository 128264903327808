import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomQuill from "../components/global/CustomQuill";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SelfEstimateAddPage = () => {
  const [estimateContent, setEstimateContent] = useState(
    `<h2>☞ 상품 : </h2><p><br></p><h2>☞ 사이즈 : </h2><p><br></p><h2>☞ 후가공 : </h2><p><br></p><h2>☞ 수량 : </h2><p><br></p><h2>☞ 용도 : </h2><p><br></p><h2>☞ 기타 요청 사항 : </h2><p><br></p><h2>☞ 포장 : </h2><p><br></p><h2>☞ 배송 :</h2><p><br><br></p>`
  );

  const { data } = useQuery("userinfo", { enabled: false });
  const [snackbar, setSnackbar] = useState(null);
  const [title, setTitle] = useState("견적 문의드립니다.");
  const [manager, setManager] = useState("");
  const [phone, setPhone] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const insertEstimate = async () => {
    console.log("insertEstimate");
    const res = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/selfestimate",
      {
        ESTIMATE_TITLE: title,
        ESTIMATE_MANAGER: manager,
        ESTIMATE_TEL: phone,
        ESTIMATE_CONTENT: estimateContent,
        ESTIMATE_FILEURL: fileUrl,
        ESTIMATE_FILENAME: fileName,
        ESTIMATE_USER_ID: data?.USER_ID,
      }
    );
    if (res.status === 200) {
      setOpenDialog(true);
    } else {
      setSnackbar({
        children: "문의 등록에 실패하였습니다.",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (data) {
      setManager(data.USER_NM);
      setPhone(data.USER_TEL0);
    }
  }, [data]);

  useEffect(() => {
    console.log(estimateContent);
  }, [estimateContent]);

  const handleUploadFile = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".png,.jpg,.jpeg,.pdf,.ai,.zip");
    input.click();
    input.addEventListener("change", async () => {
      const file = input.files[0];

      //체크
      const allowedExtensions = [
        ".png",
        ".jpg",
        ".jpeg",
        ".pdf",
        ".ai",
        ".zip",
      ];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setSnackbar({
          children:
            "올바른 파일 형식을 선택해주세요. (png, jpg, jpeg, pdf, ai, zip)",
          severity: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "design");
      formData.append("dir", "estimate");
      formData.append("userid", data?.USER_ID);
      try {
        const result = await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/upload_global",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const fileUrl = result.data;
        setSnackbar({
          children: "파일이 업로드 되었습니다.",
          severity: "success",
        });
        setFileName(file.name);
        setFileUrl(fileUrl);
      } catch (error) {
        console.log(error);
        console.log("실패");
      }
    });
  };

  return (
    <>
      <SelfWrapper>
        <SelfHeader>
          <h1>견적 문의</h1>
        </SelfHeader>
        <SelfContent>
          <TextField
            label="제목"
            variant="outlined"
            sx={{ width: "700px", marginBottom: "16px" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div style={{ display: "flex", gap: "20px" }}>
            <TextField
              label="담당자명"
              variant="outlined"
              sx={{ width: "340px", marginBottom: "16px" }}
              value={manager}
              onChange={(e) => setManager(e.target.value)}
            />
            <TextField
              label="연락처"
              variant="outlined"
              sx={{ width: "340px", marginBottom: "16px" }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div style={{ width: "700px" }}>
            <CustomQuill
              setContent={setEstimateContent}
              initContent={estimateContent}
            />
          </div>
          <div style={{ width: "700px", marginTop: "16px" }}>
            <div style={{ border: "1px solid #ccc", padding: "16px" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    lineHeight: "36.5px",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  {fileName ? fileName : "첨부파일 없음"}
                </Typography>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ width: "50%" }}
                  onClick={handleUploadFile}
                >
                  파일 선택
                </Button>
              </div>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                  paddingTop: "16px",
                  color: "#999",
                }}
              >
                파일이 여러 건일 경우 zip으로 압축해서 업로드 해주세요.
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              sx={{ marginTop: "16px" }}
              onClick={insertEstimate}
            >
              문의 등록
            </Button>
            <Button
              variant="contained"
              sx={{ marginTop: "16px" }}
              onClick={() => navigate("/selfestimate")}
            >
              목록
            </Button>
          </div>
        </SelfContent>
      </SelfWrapper>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setSnackbar(null);
          }}
          autoHideDuration={3000}
        >
          <Alert
            {...snackbar}
            onClose={() => {
              setSnackbar(null);
            }}
          />
        </Snackbar>
      )}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          navigate("/selfestimate");
        }}
      >
        <DialogTitle>문의 등록</DialogTitle>
        <DialogContent>
          <DialogContentText>
            문의가 등록되었습니다.
            <br />
            빠른 시일 내에 답변 드리겠습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpenDialog(false);
              navigate("/selfestimate");
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelfEstimateAddPage;

const SelfWrapper = styled.div`
  width: 1140px;
  margin: 0 auto;
  padding-bottom: 100px;
  height: 100%;
  background-color: #fff;
`;

const SelfHeader = styled.div`
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 24px;
    font-weight: 600;
  }
`;

const SelfContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

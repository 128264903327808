import React, { useState } from "react";
import * as S from "../../styles/new_styles";

import facebook_static from "../../assets/icons/facebook_static.png";
import facebook_animated from "../../assets/icons/facebook_animated.gif";
import insta_static from "../../assets/icons/insta_static.png";
import insta_animated from "../../assets/icons/insta_animated.gif";
import youtube_static from "../../assets/icons/youtube_static.png";
import youtube_animated from "../../assets/icons/youtube_animated.gif";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Box, Button, List, ListItem } from "@mui/material";
import {
  ArrowDownward,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import styled from "styled-components";

const Footer2 = ({ openPopup2 }) => {
  const [facebookIcon, setFacebookIcon] = useState(facebook_static);
  const [instaIcon, setInstaIcon] = useState(insta_static);
  const [youtubeIcon, setYoutubeIcon] = useState(youtube_static);

  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  const handleSnsOver = (name) => {
    switch (name) {
      case "facebook":
        setFacebookIcon(facebook_animated);
        break;
      case "insta":
        setInstaIcon(insta_animated);
        break;

      case "youtube":
        setYoutubeIcon(youtube_animated);
        break;
      default:
        break;
    }
  };

  const handleSnsLeave = (name) => {
    switch (name) {
      case "facebook":
        setFacebookIcon(facebook_static);
        break;
      case "insta":
        setInstaIcon(insta_static);
        break;

      case "youtube":
        setYoutubeIcon(youtube_static);
        break;
      default:
        break;
    }
  };

  // 모바일

  const [InfoOpen, setInfoOpen] = useState(false);

  return (
    <>
      {isPc ? (
        <S.FooterLayout>
          <S.FooterContainer>
            <S.FooterBtnWrapper>
              <S.FooterBtnBox>
                <S.FooterBtnList>
                  <S.FooterBtnItem>
                    <S.FooterBtnText>회사소개</S.FooterBtnText>
                  </S.FooterBtnItem>
                  <S.FooterBtnItem>
                    <Link to={"/cs"}>
                      <S.FooterBtnText>고객센터</S.FooterBtnText>
                    </Link>
                  </S.FooterBtnItem>
                  <S.FooterBtnItem>
                    <S.FooterBtnText onClick={() => openPopup2(0)}>
                      이용약관
                    </S.FooterBtnText>
                  </S.FooterBtnItem>
                  <S.FooterBtnItem>
                    <S.FooterBtnText onClick={() => openPopup2(1)}>
                      개인정보처리방침
                    </S.FooterBtnText>
                  </S.FooterBtnItem>
                  <S.FooterBtnItem>
                    <Link to={"/notice"}>
                      <S.FooterBtnText>공지사항</S.FooterBtnText>
                    </Link>
                  </S.FooterBtnItem>
                  <S.FooterBtnItem>
                    <Link to={"/intro0"}>
                      <S.FooterBtnText>오시는길</S.FooterBtnText>
                    </Link>
                  </S.FooterBtnItem>
                </S.FooterBtnList>
                <S.FooterSnsList>
                  <S.FooterSnsItem
                    onMouseOver={() => {
                      handleSnsOver("facebook");
                    }}
                    onMouseLeave={() => {
                      handleSnsLeave("facebook");
                    }}
                  >
                    <S.FooterSnsIcon icon={facebookIcon} />
                  </S.FooterSnsItem>
                  <S.FooterSnsItem
                    onMouseOver={() => {
                      handleSnsOver("insta");
                    }}
                    onMouseLeave={() => {
                      handleSnsLeave("insta");
                    }}
                  >
                    <S.FooterSnsIcon icon={instaIcon} />
                  </S.FooterSnsItem>
                  <S.FooterSnsItem
                    onMouseOver={() => {
                      handleSnsOver("youtube");
                    }}
                    onMouseLeave={() => {
                      handleSnsLeave("youtube");
                    }}
                  >
                    <S.FooterSnsIcon icon={youtubeIcon} />
                  </S.FooterSnsItem>
                </S.FooterSnsList>
              </S.FooterBtnBox>
            </S.FooterBtnWrapper>
            <S.FooterAboutWrapper>
              <S.FooterAboutText>
                ㈜스노우화이트 대표이사 이규민 | 사업자등록번호 636-81-02878 |
                통신판매신고 2024-고양일산동-1527
              </S.FooterAboutText>
              <S.FooterAboutText>
                경기도 고양시 일산동구 장대길 42-13 (장항동) | 문의
                070-4109-1331 | snowwhite2024@naver.com
                {/* 고객만족센터 1588-0000 */}
              </S.FooterAboutText>
              <S.FooterAboutText>
                Copyright ⓒ 2024. ㈜스노우화이트. All rights reserved. | icons
                by{" "}
                <S.FooterLicenseLink href="https://icons8.com/">
                  Icons8
                </S.FooterLicenseLink>
              </S.FooterAboutText>
            </S.FooterAboutWrapper>
          </S.FooterContainer>
        </S.FooterLayout>
      ) : (
        <Box>
          <Box
            className="top"
            sx={{
              padding: "16px 0 0 0",
              textAlign: "center",
            }}
          >
            <Button
              endIcon={InfoOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              color="inherit"
              sx={{ fontWeight: "550" }}
              onClick={() => setInfoOpen(!InfoOpen)}
            >
              스노우화이트 사업자 정보
            </Button>
          </Box>

          <Box className="middle" display={InfoOpen ? "block" : "none"}>
            <InfoUl>
              <InfoLi>㈜스노우화이트</InfoLi>
              <InfoLi>대표이사 : 이규민</InfoLi>
              <InfoLi>경기도 고양시 일산동구 장대길 42-13 (장항동)</InfoLi>
              <InfoLi>사업자등록번호 636-81-02878</InfoLi>
              <InfoLi>통신판매신고 2024-고양일산동-1527</InfoLi>
            </InfoUl>
          </Box>

          <Box
            className="bottom"
            sx={{
              padding: "16px 0 32px 0",
              mb: "40px",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: "550",
            }}
          >
            <BottomUl>
              <BottomLi>회사소개</BottomLi>
              <BottomLine />
              <BottomLi>고객센터</BottomLi>
              <BottomLine />
              <BottomLi>이용약관</BottomLi>
              <BottomLine />
              <BottomLi>개인정보처리방침</BottomLi>
              <BottomLine />
              <BottomLi>공지사항</BottomLi>
              <BottomLine />
              <BottomLi>문의 070-4109-1331</BottomLi>
              <BottomLine />
              <BottomLi>snowwhite2024@naver.com</BottomLi>
            </BottomUl>
          </Box>
        </Box>
      )}
    </>
  );
};

const InfoUl = styled.ul`
  font-size: 12px;
  text-align: center;
  padding: 0;
  list-style: none;
`;

const InfoLi = styled.li`
  padding: 3px 0;
`;

const BottomUl = styled.ul`
  display: inline-block;
  user-select: none;
  padding: 0 35px;
`;

const BottomLi = styled.li`
  display: inline-block;
`;

const BottomLine = styled.span`
  display: inline-block;
  position: relative;
  width: 1px;
  height: 10px;
  margin: 3px 8px;
  background: #bbb;
  top: 3px;
`;

export default Footer2;

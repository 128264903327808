import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomQuill from "../components/global/CustomQuill";
import { useQuery } from "react-query";
import axios from "axios";
import { formatDateAndTime_KR } from "../hooks/Utill";
import { useNavigate } from "react-router-dom";
import { AddCircleOutline } from "@mui/icons-material";

const SelfEstimatePage = () => {
  const { data } = useQuery("userinfo", { enabled: false });
  const [snackbar, setSnackbar] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [estimateList, setEstimateList] = useState([]);
  const navigate = useNavigate();

  const initDB = async () => {
    if (!data) return;

    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/selfestimate",
      {
        params: {
          ESTIMATE_USER_ID: data.USER_ID,
        },
      }
    );
    setEstimateList(res.data);
  };

  useEffect(() => {
    initDB();
  }, [data]);

  return (
    <>
      <SelfWrapper>
        <SelfHeader>
          <h1>견적 문의</h1>
        </SelfHeader>
        <SelfContent>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              onClick={() => navigate("/selfestimate/add")}
            >
              문의작성
            </Button>
            <Button
              variant="outlined"
              sx={{ marginLeft: "10px" }}
              onClick={() => {
                window.open("http://pf.kakao.com/_kfpYG/chat");
              }}
            >
              카카오톡
            </Button>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "600", width: "55%", textAlign: "center" }}
                >
                  제목
                </TableCell>
                <TableCell sx={{ fontWeight: "600", width: "10%" }}>
                  담당자
                </TableCell>
                <TableCell sx={{ fontWeight: "600", width: "15%" }}>
                  연락처
                </TableCell>
                <TableCell sx={{ fontWeight: "600", width: "20%" }}>
                  작성일
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estimateList.map((item) => (
                <TableRow key={item.ESTIMATE_SID}>
                  <TableCell
                    onClick={() =>
                      navigate(`/selfestimate/detail/${item.ESTIMATE_SID}`)
                    }
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        fontWeight: "600",
                      },
                    }}
                  >
                    {item.ESTIMATE_TITLE}
                  </TableCell>
                  <TableCell>{item.ESTIMATE_MANAGER}</TableCell>
                  <TableCell>{item.ESTIMATE_TEL}</TableCell>
                  <TableCell>
                    {formatDateAndTime_KR(item.ESTIMATE_REGDATE)}
                  </TableCell>
                </TableRow>
              ))}
              {!estimateList.length && (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ textAlign: "center", height: "100px" }}
                  >
                    등록된 견적 문의가 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </SelfContent>
      </SelfWrapper>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setSnackbar(null);
          }}
          autoHideDuration={3000}
        >
          <Alert
            {...snackbar}
            onClose={() => {
              setSnackbar(null);
            }}
          />
        </Snackbar>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>문의 등록</DialogTitle>
        <DialogContent>
          <DialogContentText>
            문의가 등록되었습니다.
            <br />
            빠른 시일 내에 답변 드리겠습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenDialog(false)}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelfEstimatePage;

const SelfWrapper = styled.div`
  width: 1140px;
  margin: 0 auto;
  padding-bottom: 100px;
  height: 100%;
  background-color: #fff;
`;

const SelfHeader = styled.div`
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 24px;
    font-weight: 600;
  }
`;

const SelfContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

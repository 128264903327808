import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Sticker_single = ({ prod, SelectOptions, setSelectOptions }) => {
  const [DefaultPaper, setDefaultPaper] = useState("아트지");
  const [DefaultGram, setDefaultGram] = useState("90g");
  const [DefaultQuantity, setDefaultQuantity] = useState(100);
  const [StickerWidth, setStickerWidth] = useState("50");
  const [StickerHeight, setStickerHeight] = useState("50");
  const [StickerShape, setStickerShape] = useState("반칼");
  const [StickerType, setStickerType] = useState("사각");

  const [papers, setPapers] = useState({});

  const getPriceData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/global/prices",
      {
        params: {
          PRICE_PROD_CATE: prod,
          PRICE_OPTION_CATE: "용지",
        },
      }
    );

    const papers_copy = {};

    res.data.forEach((item) => {
      const name = item.PRICE_OPTION_NM.match(/[가-힣a-zA-Z]+/)[0]; // 용지 이름 추출
      const weight = parseInt(item.PRICE_OPTION_NM.match(/\d+/)[0], 10); // 그람수 추출
      console.log(name);
      console.log(weight);

      if (!papers_copy[name]) {
        papers_copy[name] = [];
      }

      if (!papers_copy[name].includes(weight)) {
        papers_copy[name].push(weight);
      }
    });

    setPapers(papers_copy);
    setDefaultPaper(Object.keys(papers_copy)[0]);
  };

  useEffect(() => {
    getPriceData();
  }, []);

  const menuItems_papers = Object.keys(papers).map((key) => (
    <MenuItem sx={{ fontSize: "14px" }} value={key} key={key}>
      {key}
    </MenuItem>
  ));

  useEffect(() => {
    console.log("확인", papers[DefaultPaper]);
    if (papers[DefaultPaper]) {
      setDefaultGram(papers[DefaultPaper][0] + "g");
    }
  }, [DefaultPaper]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.낱장스티커.용지 = DefaultPaper + DefaultGram;
    setSelectOptions(copyOptions);
  }, [DefaultPaper, DefaultGram]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.낱장스티커.수량 = DefaultQuantity;
    setSelectOptions(copyOptions);
  }, [DefaultQuantity]);

  const [DefaultCount, setDefaultCount] = useState(1);
  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.낱장스티커.건 = DefaultCount;
    setSelectOptions(copyOptions);
  }, [DefaultCount]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.낱장스티커.가로 = parseInt(StickerWidth);
    copyOptions.낱장스티커.세로 = parseInt(StickerHeight);
    setSelectOptions(copyOptions);
  }, [StickerWidth, StickerHeight]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.낱장스티커.모양따기 = StickerShape;
    setSelectOptions(copyOptions);
  }, [StickerShape]);

  useEffect(() => {
    setStickerHeight(StickerWidth);
    const copyOptions = { ...SelectOptions };
    copyOptions.낱장스티커.모양 = StickerType;
    setSelectOptions(copyOptions);
  }, [StickerType]);

  return (
    <Box>
      <Box sx={{}}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          용지
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultPaper}
            onChange={(e) => {
              setDefaultPaper(e.target.value);
            }}
          >
            {menuItems_papers}
          </Select>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultGram}
            onChange={(e) => {
              setDefaultGram(e.target.value);
            }}
          >
            {papers[DefaultPaper]?.map((gram) => (
              <MenuItem sx={{ fontSize: "14px" }} value={gram + "g"}>
                {gram + "g"}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          모양
        </InputLabel>

        <Select
          sx={{
            width: "48%",
            marginRight: "2%",
            height: "40px",
            fontSize: "14px",
          }}
          fullWidth
          value={StickerType}
          onChange={(e) => {
            setStickerType(e.target.value);
          }}
        >
          <MenuItem sx={{ fontSize: "14px" }} value="사각">
            사각
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} value="원형">
            원형
          </MenuItem>
        </Select>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          사이즈
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          {StickerType === "사각" && (
            <>
              <TextField
                size="small"
                sx={{
                  width: "48%",
                  marginRight: "2%",
                  height: "40px",
                  fontSize: "14px",
                }}
                fullWidth
                value={StickerWidth}
                label="가로"
                onChange={(e) => {
                  setStickerWidth(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value < 10) {
                    setStickerWidth(10);
                    return;
                  }
                  if (e.target.value > 250) {
                    setStickerWidth(250);
                    return;
                  }
                }}
              />
              <TextField
                size="small"
                sx={{
                  width: "48%",
                  marginRight: "2%",
                  height: "40px",
                  fontSize: "14px",
                }}
                fullWidth
                value={StickerHeight}
                label="세로"
                onChange={(e) => {
                  setStickerHeight(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value < 10) {
                    setStickerHeight(10);
                    return;
                  }
                  if (e.target.value > 250) {
                    setStickerHeight(250);
                    return;
                  }
                }}
              />
            </>
          )}
          {StickerType === "원형" && (
            <>
              <TextField
                size="small"
                sx={{
                  width: "48%",
                  marginRight: "2%",
                  height: "40px",
                  fontSize: "14px",
                }}
                fullWidth
                value={StickerWidth}
                onChange={(e) => {
                  setStickerWidth(e.target.value);
                  setStickerHeight(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value < 10) {
                    setStickerWidth(10);
                    setStickerHeight(10);
                    return;
                  }
                  if (e.target.value > 250) {
                    setStickerWidth(250);
                    setStickerHeight(250);
                    return;
                  }
                }}
              />
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          수량
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <TextField
            size="small"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultQuantity}
            onChange={(e) => {
              setDefaultQuantity(e.target.value);
            }}
            onBlur={(e) => {
              if (e.target.value < 10) {
                setDefaultQuantity(10);
                return;
              }
            }}
          />
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultCount}
            onChange={(e) => {
              setDefaultCount(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              1건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={2}>
              2건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              3건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={4}>
              4건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={5}>
              5건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={6}>
              6건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={7}>
              7건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={8}>
              8건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={9}>
              9건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={10}>
              10건
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          모양따기
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={StickerShape}
            onChange={(e) => {
              setStickerShape(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value="반칼">
              반칼
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value="완칼">
              완칼
            </MenuItem>
          </Select>
        </Box>
      </Box>

      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "12px",
            marginBottom: "12px",
          }}
        >
          후가공
        </InputLabel>

        <FormControlLabel
          value="코팅"
          control={<Checkbox size="small" />}
          label="코팅"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.낱장스티커[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
      </Box>
    </Box>
  );
};

export default Sticker_single;

import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header3 from "../headers/Header3";
import Footer2 from "../footers/Footer2";
import { Box, SpeedDial, SpeedDialAction } from "@mui/material";
import {
  ArrowUpward,
  Call,
  PermPhoneMsg,
  SupportAgent,
} from "@mui/icons-material";
import { animateScroll as scroll } from "react-scroll";
import { GridSaveAltIcon } from "@mui/x-data-grid";
import * as S from "../../styles/new_styles";
import { useMediaQuery } from "react-responsive";
import NaverConversion from "../../hooks/NaverConversion";

const DefaultLayout = ({ openPopup, openPopup2, queryClient }) => {
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  const navigator = useNavigate();

  const actions = [
    {
      icon: <S.Glob_Icon width="24px" height="24px" icon="/icons/kakao.png" />,
      name: "카카오톡",
      action: () => {
        window.open("http://pf.kakao.com/_kfpYG/chat");
        if (window.wcs) {
          const _conv = {
            type: `lead`,
          };
          window.wcs.trans(_conv);
        }
      },
    },
    {
      icon: <SupportAgent />,
      name: "고객센터",
      action: () => {
        navigator("/cs");
      },
    },
    {
      icon: <Call />,
      name: "전화하기",
      action: () => {
        window.open("tel:070-4109-1331");
        if (window.wcs) {
          const _conv = {
            type: `lead`,
          };
          window.wcs.trans(_conv);
        }
      },
    },
  ];
  return (
    <>
      {isPc ? (
        <>
          <Header3 openPopup={openPopup} queryClient={queryClient} />
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 50, right: 50 }}
            icon={<ArrowUpward />}
            onClick={() => scroll.scrollToTop({ smooth: true })}
          />
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 50, right: 120 }}
            icon={<PermPhoneMsg />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </SpeedDial>
          <Box sx={{ pt: "80px" }} />
          <Outlet />
          <Footer2 openPopup2={openPopup2} />
        </>
      ) : (
        <>
          <Box>
            <Header3 openPopup={openPopup} queryClient={queryClient} />
            <SpeedDial
              className="SpeedDialMob"
              ariaLabel="SpeedDial basic example"
              sx={{
                position: "fixed",
                bottom: 65,
                right: 10,
              }}
              icon={<PermPhoneMsg sx={{}} />}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.action}
                />
              ))}
            </SpeedDial>
            <Outlet />
            <Footer2 openPopup2={openPopup2} />
          </Box>
        </>
      )}
    </>
  );
};

export default DefaultLayout;

import { Box, Button, Skeleton, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatORDER_PAYMENT_TYPE,
  formatTime,
} from "../../hooks/Utill";
import axios from "axios";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { useMediaQuery } from "react-responsive";

const AdminDashBoard = ({ currentPage, setCurrentPage }) => {
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });
  const apiRef = useGridApiRef();
  const [OrderDataRows, setOrderDataRows] = useState([]);
  const [UserDataRows, setUserDataRows] = useState([]);
  //그래프 데이터
  const [graphDataSession, setGraphDataSession] = useState([]);
  const [graphDataClick, setGraphDataClick] = useState([]);

  const [graphDataOrder, setGraphDataOrder] = useState([]);
  const [graphDataOrderKeys, setGraphDataOrderKeys] = useState([]);
  const [graphDataOrderMax, setGraphDataOrderMax] = useState(0);

  //오늘의 할 일
  const [orderingCntData, setOrderingCntData] = useState([]);

  //일자별 통계
  const [dailyData, setDailyData] = useState([]);

  useEffect(() => {
    initdb();
  }, []);

  const findMaxTotal = (groupByWeek) => {
    return Object.values(groupByWeek).reduce((maxTotal, weekData) => {
      // 색상 키를 제외한 실제 상품 데이터만 합산
      const weekTotal = Object.entries(weekData)
        .filter(([key]) => !key.includes("Color") && key !== "week")
        .reduce((sum, [_, value]) => sum + value, 0);

      return Math.max(maxTotal, weekTotal);
    }, 0);
  };

  const initdb = async () => {
    const res = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/orderlist")
    ).data;
    const res_copy = res.map((el, index) => ({ ...el, id: index + 1 }));
    setOrderDataRows(res_copy);

    const UserRes = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/user")
    ).data;
    setUserDataRows(UserRes);

    //그래프
    const graphRes = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/dashboard")
    ).data;

    const graphSession_copy = graphRes.res_session.row.map((el, index) => ({
      x: new Date(el.DATE),
      y: el.CNT,
    }));

    const graphClick_copy = graphRes.res_click.row.map((el, index) => ({
      x: new Date(el.DATE),
      y: el.IP_CNT,
    }));

    const allProducts = [
      ...new Set(graphRes.res_order_cnt.row.map((el) => el.ORDER_CORE_PROD)),
    ];

    const groupByWeek = graphRes.res_order_cnt.row.reduce((acc, row) => {
      if (!acc[row.WEEK_LABEL]) {
        acc[row.WEEK_LABEL] = {
          week: row.WEEK_LABEL,
          // 모든 상품을 0으로 초기화
          ...Object.fromEntries(allProducts.map((prod) => [prod, 0])),
        };
      }
      // 해당 주차의 상품 카운트 업데이트
      acc[row.WEEK_LABEL][row.ORDER_CORE_PROD] = row.ORDER_CNT;

      // 각 상품의 색상 추가
      allProducts.forEach((prod) => {
        acc[row.WEEK_LABEL][`${prod}Color`] = `hsl(${
          Math.random() * 360
        }, 70%, 50%)`;
      });

      return acc;
    }, {});

    setGraphDataOrderMax(graphRes.res_order_cnt.row.length);

    console.log("graphDataOrderMax", graphDataOrderMax);

    const graphOrder_copy = Object.values(groupByWeek);
    const maxTotal = findMaxTotal(groupByWeek);

    setGraphDataOrderKeys(allProducts);
    setGraphDataOrderMax(maxTotal);
    setGraphDataSession(graphSession_copy);
    setGraphDataClick(graphClick_copy);
    setGraphDataOrder(graphOrder_copy);

    const orderingCntRes = graphRes.res_ordering_cnt.row;

    console.log("orderingCntRes", orderingCntRes);

    const orderingCntData_copy = {
      접수: orderingCntRes.find((e) => e.ORDERING_STATUS === "접수")?.CNT || 0,
      진행: orderingCntRes.find((e) => e.ORDERING_STATUS === "진행")?.CNT || 0,
      완료: orderingCntRes.find((e) => e.ORDERING_STATUS === "완료")?.CNT || 0,
    };

    setOrderingCntData(orderingCntData_copy);

    const dailyRes_order = graphRes.res_order_daily.row;
    const dailyRes_user = graphRes.res_user_daily.row;
    const dailyData_copy = dailyRes_order.map((el, index) => ({
      id: index + 1,
      DATE: el.ORDER_DATE,
      ORDER_CNT: el.ORDER_COUNT,
      TOTAL_AMOUNT: el.TOTAL_AMOUNT,
    }));

    dailyData_copy.forEach((daily, idx) => {
      const matchingUserData = dailyRes_user.find(
        (user) => user.REG_DATE === daily.DATE
      );

      daily.USER_COUNT = matchingUserData ? matchingUserData.USER_COUNT : 0;
    });

    const dailyRes_click = graphRes.res_click.row;
    dailyData_copy.forEach((daily, idx) => {
      const matchingClickData = dailyRes_click.find(
        (click) => click.DATE === daily.DATE
      );
      daily.CLICK_COUNT = matchingClickData ? matchingClickData.IP_CNT : 0;
    });

    const dailyRes_session = graphRes.res_session.row;
    dailyData_copy.forEach((daily, idx) => {
      const matchingSessionData = dailyRes_session.find(
        (session) => session.DATE === daily.DATE
      );
      daily.SESSION_COUNT = matchingSessionData ? matchingSessionData.CNT : 0;
    });

    console.log("dailyData_copy", dailyData_copy);

    setDailyData(dailyData_copy);
  };
  const renderOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "결제 대기";
      case 2:
        return "결제 완료";
      case 3:
        return "제작 중";
      case 4:
        return "배송 중";
      case 5:
        return "배송 완료";
      case 9:
        return "취소";
      default:
        return "Code error";
    }
  };

  const OrderColumns = [
    {
      field: "ORDER_DATE",
      headerName: "주문일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_PAYMENT_DATE",
      headerName: "결제일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_SID",
      headerName: "주문번호",
      width: 160,
      editable: false,
    },
    {
      field: "ORDER_NM",
      headerName: "주문자",
      width: 110,
      editable: false,
    },
    {
      field: "ORDER_CORE_PROD",
      headerName: "상품명",
      width: 130,
      editable: false,
      renderCell: (params) => {
        const items = params.row.ITEM_SIDS.split(",");
        if (items.length > 1) {
          return `${params.row.ORDER_CORE_PROD} 외 ${items.length - 1}건`;
        }
      },
    },
    {
      field: "ORDER_AMOUNT",
      headerName: "결제금액",
      width: 100,
      editable: false,
      type: "number",
    },
    {
      field: "ORDER_PAYMENT_TYPE",
      headerName: "결제수단",
      width: 150,
      editable: false,
      renderCell: (params) => {
        let res = formatORDER_PAYMENT_TYPE(params.value);

        if (params.row.CASH_DEPOSITOR_NAME) {
          res += " (" + params.row.CASH_DEPOSITOR_NAME + ")";
        }
        return res;
      },
    },
    {
      field: "ORDER_STATUS",
      headerName: "결제상태",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return renderOrderStatus(params.value);
      },
    },
    {
      field: "ORDER_LOGIS_NM",
      headerName: "택배사",
      width: 110,
      editable: true,
    },
    {
      field: "ORDER_LOGIS_NO",
      headerName: "송장번호",
      width: 120,
      editable: false,
    },
    {
      field: "ORDER_REQ",
      headerName: "요청사항",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_TYPE",
      headerName: "현금영수증",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_NO",
      headerName: "고객번호",
      width: 120,
      editable: false,
    },
    {
      field: "ORDER_USE_POINT",
      headerName: "포인트 사용",
      width: 200,
      editable: false,
      type: "number",
    },
    {
      field: "4",
      headerName: "비고",
      width: 200,
      editable: false,
    },
  ];

  const OrderColumnsMob = [
    {
      field: "ORDER_NM",
      headerName: "주문자",
      width: 110,
      editable: false,
    },

    {
      field: "ORDER_CORE_PROD",
      headerName: "상품명",
      width: 110,
      editable: false,
      renderCell: (params) => {
        const items = params.row.ITEM_SIDS.split(",");
        if (items.length > 1) {
          return `${params.row.ORDER_CORE_PROD} 외 ${items.length - 1}건`;
        }
      },
    },
    {
      field: "ORDER_AMOUNT",
      headerName: "결제금액",
      width: 110,
      editable: false,
      type: "number",
    },
    {
      field: "ORDER_DATE",
      headerName: "주문일",
      width: 120,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_PAYMENT_DATE",
      headerName: "결제일",
      width: 120,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_SID",
      headerName: "주문번호",
      width: 125,
      editable: false,
    },

    {
      field: "ORDER_PAYMENT_TYPE",
      headerName: "결제수단",
      width: 150,
      editable: false,
      renderCell: (params) => {
        let res = formatORDER_PAYMENT_TYPE(params.value);

        if (params.row.CASH_DEPOSITOR_NAME) {
          res += " (" + params.row.CASH_DEPOSITOR_NAME + ")";
        }
        return res;
      },
    },
    {
      field: "ORDER_STATUS",
      headerName: "결제상태",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return renderOrderStatus(params.value);
      },
    },
    {
      field: "ORDER_LOGIS_NM",
      headerName: "택배사",
      width: 110,
      editable: true,
    },
    {
      field: "ORDER_LOGIS_NO",
      headerName: "송장번호",
      width: 120,
      editable: false,
    },
    {
      field: "ORDER_REQ",
      headerName: "요청사항",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_TYPE",
      headerName: "현금영수증",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_NO",
      headerName: "고객번호",
      width: 120,
      editable: false,
    },
    {
      field: "ORDER_USE_POINT",
      headerName: "포인트 사용",
      width: 200,
      editable: false,
      type: "number",
    },
    {
      field: "4",
      headerName: "비고",
      width: 200,
      editable: false,
    },
  ];

  const UserColumns = [
    // { field: "id", headerName: "순번", width: 150 },
    {
      field: "USER_NM",
      headerName: "이름",
      width: 100,
      editable: true,
    },
    {
      field: "USER_ID",
      headerName: "아이디",
      width: 150,
      editable: false,
    },
    {
      field: "USER_TEL0",
      headerName: "전화번호",
      width: 150,
      editable: true,
    },
    {
      field: "USER_POINT",
      headerName: "포인트",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "USER_GRADE",
      headerName: "회원등급",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [1, 9],
      renderCell: (params) => <>{params.value === 9 ? "관리자" : "일반회원"}</>,
    },
    {
      field: "ORDER_CNT",
      headerName: "주문횟수",
      // type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "ORDER_AMT",
      headerName: "주문금액",
      type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "LOGIN_CNT",
      headerName: "방문수",
      // type: "number",
      width: 100,
      editable: false,
    },
    {
      field: "USER_LAST_LOGIN",
      headerName: "최근 접속일",
      // type: "number",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "USER_REGDATE",
      headerName: "가입일",
      // type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return formatDate(params.value);
      },
    },
  ];

  const DailyColumns = [
    {
      field: "DATE",
      headerName: "일자",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return formatDate(params.value);
      },
    },
    {
      field: "ORDER_CNT",
      headerName: "주문수",
      width: 70,
      editable: false,
      type: "number",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "매출액",
      width: 120,
      editable: false,
      type: "number",
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "USER_COUNT",
      headerName: "가입수",
      width: 80,
      editable: false,
      type: "number",
    },
    {
      field: "CLICK_COUNT",
      headerName: "방문수",
      width: 80,
      editable: false,
      type: "number",
    },
    {
      field: "SESSION_COUNT",
      headerName: "세션수",
      width: 80,
      editable: false,
      type: "number",
    },
  ];

  return isPc ? (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "140vh",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          alignContent: "flex-start",
          rowGap: "25px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 180,
            "& button": {
              width: "90px",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
        >
          <Box
            sx={{
              width: "90%",
              borderBottom: "1px solid #ccc",
              padding: "15px",
              margin: "0 auto",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            오늘의 할 일
          </Box>
          <Box
            sx={{
              width: "90%",
              margin: "0 auto",
              padding: "5px",
            }}
          >
            <Button
              onClick={() => {
                setCurrentPage("주문관리");
              }}
            >
              신규주문{" "}
              {OrderDataRows.filter((el) => el.ORDER_STATUS === 2).length}
            </Button>
            <Button
              onClick={() => {
                setCurrentPage("주문관리");
              }}
            >
              제작중{" "}
              {OrderDataRows.filter((el) => el.ORDER_STATUS === 3).length}
            </Button>
            <Button
              onClick={() => {
                setCurrentPage("주문관리");
              }}
            >
              배송중{" "}
              {OrderDataRows.filter((el) => el.ORDER_STATUS === 4).length}
            </Button>
          </Box>
          <Box sx={{ width: "90%", margin: "0 auto", padding: "5px" }}>
            <Button
              onClick={() => {
                setCurrentPage("발주관리");
              }}
            >
              신규발주 {orderingCntData.접수}
            </Button>
            <Button
              onClick={() => {
                setCurrentPage("발주관리");
              }}
            >
              진행중 {orderingCntData.진행}
            </Button>
            <Button
              onClick={() => {
                setCurrentPage("발주관리");
              }}
            >
              완료 {orderingCntData.완료}
            </Button>
          </Box>
        </Box>
        <Box sx={{ width: "48%", height: 400 }}>
          <ResponsiveLine
            enableSlices="x"
            enableTouchCrosshair
            initialHiddenIds={["cognac"]}
            margin={{
              bottom: 60,
              left: 80,
              right: 20,
              top: 20,
            }}
            pointBorderColor={{
              from: "color",
              modifiers: [["darker", 0.3]],
            }}
            pointBorderWidth={1}
            useMesh
            curve="monotoneX"
            data={[
              {
                id: "세션수",
                data: graphDataSession,
              },
              {
                id: "방문자",
                data: graphDataClick,
              },
            ]}
            axisLeft={{
              legendOffset: 12,
              legend: "",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisBottom={{
              format: "%m/%d",
              tickValues: "every 1 days",
              legendOffset: 36,
              legendPosition: "middle",
              tickSize: 5,
              tickPadding: 5,
            }}
            xFormat="time:%Y-%m-%d"
            xScale={{
              format: "%Y-%m-%d",
              precision: "day",
              type: "time",
              useUTC: false,
            }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                itemHeight: 20,
                itemWidth: 80,
                toggleSerie: true,
                translateY: 50,
              },
            ]}
          />
        </Box>
        <Box sx={{ width: "48%", height: 400 }}>
          <ResponsiveBar
            data={graphDataOrder}
            keys={graphDataOrderKeys}
            indexBy="week"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.65}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "fries",
                },
                id: "dots",
              },
              {
                match: {
                  id: "sandwich",
                },
                id: "lines",
              },
            ]}
            borderColor={{
              from: "color",
              modifiers: [["darker", "1.6"]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 32,
              truncateTickAt: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
              truncateTickAt: 0,
              tickValues: graphDataOrderMax,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
          />
        </Box>
        <Box
          sx={{
            height: 400,
            width: "48%",
          }}
        >
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", textAlign: "center" }}
          >
            주문관리
          </Typography>

          <DataGrid
            sx={{
              height: "calc(100% - 50px)",
            }}
            apiRef={apiRef}
            rows={OrderDataRows}
            columns={OrderColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[5]}
            onRowDoubleClick={(param) => {
              console.log(param);
              window.open(
                `/admin/order/${param.row.ORDER_SID}`,
                "주문 상세",
                "width=1250,height=700,top=100,left=200"
              );
            }}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        <Box sx={{ height: 400, width: "48%" }}>
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", textAlign: "center" }}
          >
            회원관리
          </Typography>
          <DataGrid
            sx={{
              height: "calc(100% - 50px)",
            }}
            // apiRef={apiRef}
            rows={UserDataRows}
            columns={UserColumns}
            processRowUpdate={(updatedRow, originalRow) => {
              return updatedRow;
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        <Box sx={{ width: "48%", height: 400 }}>
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", textAlign: "center" }}
          >
            일자별 통계
          </Typography>
          <DataGrid
            sx={{
              height: "calc(100% - 50px)",
            }}
            // apiRef={apiRef}
            rows={dailyData}
            columns={DailyColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        <Box sx={{ width: "48%", height: 250 }}></Box>

        {/* <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      /> */}
      </div>
    </>
  ) : (
    <>
      <Box
        className="layout-mob"
        sx={{
          maxWidth: "300px",
          margin: "0 auto",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid #ccc",
            padding: "15px",
            margin: "0 auto",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          오늘의 할 일
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            padding: "5px",
          }}
        >
          <Button
            onClick={() => {
              setCurrentPage("주문관리");
            }}
          >
            신규주문{" "}
            {OrderDataRows.filter((el) => el.ORDER_STATUS === 2).length}
          </Button>
          <Button
            onClick={() => {
              setCurrentPage("주문관리");
            }}
          >
            제작중 {OrderDataRows.filter((el) => el.ORDER_STATUS === 3).length}
          </Button>
          <Button
            onClick={() => {
              setCurrentPage("주문관리");
            }}
          >
            배송중 {OrderDataRows.filter((el) => el.ORDER_STATUS === 4).length}
          </Button>
        </Box>
        <Box sx={{ width: "100%", margin: "0 auto", padding: "5px" }}>
          <Button
            onClick={() => {
              setCurrentPage("발주관리");
            }}
          >
            신규발주 {orderingCntData.접수}
          </Button>
          <Button
            onClick={() => {
              setCurrentPage("발주관리");
            }}
          >
            진행중 {orderingCntData.진행}
          </Button>
          <Button
            onClick={() => {
              setCurrentPage("발주관리");
            }}
          >
            완료 {orderingCntData.완료}
          </Button>
        </Box>

        <Box
          sx={{
            height: 400,
            width: "100%",
            marginTop: "25px",
          }}
        >
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", textAlign: "center" }}
          >
            주문관리
          </Typography>

          <DataGrid
            sx={{
              height: "calc(100% - 50px)",
            }}
            apiRef={apiRef}
            rows={OrderDataRows}
            columns={OrderColumnsMob}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[5]}
            onRowClick={(param) => {
              const confirm = window.confirm("주문 상세로 이동하시겠습니까?");
              if (confirm) {
                window.location.href = `/admin/order/${param.row.ORDER_SID}`;
              }
            }}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        <Box sx={{ height: 400, width: "100%", marginTop: "25px" }}>
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", textAlign: "center" }}
          >
            회원관리
          </Typography>
          <DataGrid
            sx={{
              height: "calc(100% - 50px)",
            }}
            // apiRef={apiRef}
            rows={UserDataRows}
            columns={UserColumns}
            processRowUpdate={(updatedRow, originalRow) => {
              return updatedRow;
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        <Box sx={{ width: "100%", height: 400, marginTop: "25px" }}>
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", textAlign: "center" }}
          >
            일자별 통계
          </Typography>
          <DataGrid
            sx={{
              height: "calc(100% - 50px)",
            }}
            // apiRef={apiRef}
            rows={dailyData}
            columns={DailyColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
};

export default AdminDashBoard;

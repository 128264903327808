import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import * as S from "../../styles/new_styles";
import { useSpring } from "react-spring";
import {
  Box,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { formatDateAndTime_KR } from "../../hooks/Utill";

const PrintEstimate_order = ({ order_sid }) => {
  const [maxHeight, setMaxHeight] = useState(1000);
  const [sumPrice, setSumPrice] = useState();
  const [sumTax, setSumTax] = useState();

  const [orderData, setOrderData] = useState();
  const [customProdData, setCustomProdData] = useState();

  const [totalPrice, setTotalPrice] = useState(0);
  const [prodPrice, setProdPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [delivery, setDelivery] = useState(0);

  const secret_key = "live_sk_Z61JOxRQVEEmD0YGMXvwVW0X9bAq";
  const [isCard, setIsCard] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState("");

  useEffect(() => {
    if (
      orderData?.ORDER_PAYMENT_TYPE === "pm1" &&
      orderData?.PG_ORDERID !== "undefined"
    ) {
      tossPayment_confirm();
    }
  }, [orderData]);

  const tossPayment_confirm = async () => {
    const encryptedSecretKey = "Basic " + btoa(secret_key + ":");

    const url = `https://api.tosspayments.com/v1/payments/orders/${orderData?.PG_ORDERID}`;
    const options = {
      method: "GET",
      headers: {
        Authorization: encryptedSecretKey,
      },
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setIsCard(true);
      setReceiptUrl(data.receipt.url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const tp = orderData?.ORDER_AMOUNT;
    const pp = Math.round(((tp * 100) / 110) * 1) / 1;
    const tx = Math.round(((tp * 10) / 110) * 1) / 1;
    const dl = 3000;

    setTotalPrice(parseInt(tp).toLocaleString("ko-KR"));
    setProdPrice(parseInt(pp).toLocaleString("ko-KR"));
    setTax(parseInt(tx).toLocaleString("ko-KR"));
    setDelivery(parseInt(dl).toLocaleString("ko-KR"));
  }, [orderData]);

  const Printref = useRef();
  const Btnsref = useRef();

  const [plusHeight, setPlusHeight] = useState(0);

  const initdb = async () => {
    const res_order = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/order_detail",
      {
        params: {
          order_sid: order_sid,
        },
      }
    );
    setOrderData(res_order.data);
    const item_sids = res_order.data.ITEM_SIDS?.split(",");
    const res_custom_prod = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/orderlist/item",
      {
        item_sids: item_sids,
      }
    );
    setCustomProdData(res_custom_prod.data);
    console.log(res_custom_prod.data);
    setPlusHeight(res_custom_prod.data.length * 70);
  };

  useEffect(() => {
    initdb();
    if (Printref.current && Btnsref.current) {
      setMaxHeight(
        Printref.current.offsetHeight + Btnsref.current.offsetHeight
      );
    }
  }, [Printref, Btnsref]);

  const handlePrint = useReactToPrint({
    content: () => Printref.current,
    documentTitle: "거래명세표",
    pageStyle:
      "@page { margin: 0; } @media print { body { -webkit-print-color-adjust: exact; } }",
    // onAfterPrint: () => alert("파일 다운로드 후 알림창 생성 가능"),
  });

  const SlideDown = useSpring({
    height: maxHeight + plusHeight + "px",
    boxShadow: "2px 2px 8px #aaa",
    border: "1px solid #eee",
  });

  const boldCell = (text, colSpan, width) => {
    if (colSpan) {
      colSpan = colSpan;
    } else {
      colSpan = 1;
    }
    if (width) {
      width = width;
    } else {
      width = "auto";
    }
    return (
      <TableCell
        sx={{ fontWeight: "bold", backgroundColor: "#ebf2ff", width: width }}
      >
        {text}
      </TableCell>
    );
  };

  const normalCell = (text, colSpan, width) => {
    if (colSpan) {
      colSpan = colSpan;
    } else {
      colSpan = 1;
    }
    if (width) {
      width = width;
    } else {
      width = "auto";
    }

    return (
      <TableCell
        sx={{ backgroundColor: "#fafbff", width: width }}
        colSpan={colSpan}
      >
        {text}
      </TableCell>
    );
  };

  return (
    <>
      <S.PrintWrapper
        style={SlideDown}
        // height={Printref.current.offsetHeight + Btnsref.current.offsetHeight}
      >
        <S.PrintBox ref={Printref} style={{ paddingBottom: "1rem" }}>
          <h1>거래명세표</h1>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <Table
              sx={{
                border: "2px solid #dbe8ff",
                width: "100%",
                "& td": {
                  // 모든 TableCell에 높이 적용
                  maxHeight: "35px",
                  padding: "8px",
                  overflow: "hidden",
                },
              }}
              size="small"
            >
              <TableBody>
                <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                  {boldCell("고객명")}
                  {normalCell(orderData?.ORDER_NM)}
                </TableRow>
                <TableRow>
                  {boldCell("주문 번호")}
                  {normalCell(orderData?.ORDER_SID)}
                </TableRow>
                <TableRow>
                  {boldCell("접수 시간")}
                  {normalCell(formatDateAndTime_KR(orderData?.ORDER_DATE))}
                </TableRow>
                <TableRow>
                  {boldCell("담당자")}
                  {normalCell("스노우화이트")}
                </TableRow>
              </TableBody>
            </Table>
            <Table
              sx={{
                border: "2px solid #dbe8ff",
                width: "100%",
                "& td": {
                  // 모든 TableCell에 높이 적용
                  maxHeight: "35px",
                  padding: "8px",
                  overflow: "hidden",
                },
              }}
              size="small"
            >
              <TableBody>
                <TableRow>
                  {boldCell("등록번호")}
                  {normalCell("636-81-02878", 3)}
                </TableRow>
                <TableRow>
                  {boldCell("상호")}
                  {normalCell("(주)스노우화이트")}
                  {boldCell("대표")}
                  {normalCell("이규민")}
                  <Box sx={{ position: "absolute", right: "10px" }}>
                    <img
                      src={"/seal.png"}
                      alt="logo"
                      style={{ width: "30px" }}
                    />
                  </Box>
                </TableRow>
                <TableRow>
                  {boldCell("주소")}
                  {normalCell("경기도 고양시 일산동구 장대길 42-13", 3)}
                </TableRow>
                <TableRow>
                  {boldCell("업태")}
                  {normalCell("제조")}
                  {boldCell("종목")}
                  {normalCell("인쇄")}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Table sx={{ border: "2px solid #dbe8ff" }} size="small">
              <TableHead>
                <TableRow>
                  {boldCell("총금액")}
                  {normalCell(
                    `${totalPrice}원 (합계 : ${prodPrice}원 + 부가세 ${tax}원 + 배송비 (부가세 포함) ${delivery}원)`
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Table sx={{ border: "2px solid #dbe8ff" }} size="small">
              <TableHead>
                <TableRow>
                  {boldCell("순번", 1, "10%")}
                  {boldCell("상품", 1, "60%")}
                  {boldCell("수량/건", 1, "20%")}
                  {boldCell("금액", 1, "10%")}
                </TableRow>
              </TableHead>
              <TableBody>
                {customProdData?.map((item, idx) => (
                  <TableRow>
                    {normalCell(idx + 1)}
                    {normalCell(`${item.PROD_NM} : ${item.ITEM_OPTION}`)}
                    {normalCell(
                      `${item.ITEM_QUANTITY.toLocaleString(
                        "ko-KR"
                      )} / ${item.ITEM_COUNT.toLocaleString("ko-KR")}`
                    )}
                    {normalCell(item.ITEM_AMOUNT.toLocaleString("ko-KR"))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
              {orderData?.ORDER_DATE.slice(0, 4)}년{" "}
              {orderData?.ORDER_DATE.slice(5, 7)}월{" "}
              {orderData?.ORDER_DATE.slice(8, 10)}일
            </Typography>
            <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
              상기 내용을 영수합니다.
            </Typography>
          </Box>
        </S.PrintBox>
        <S.PrintBtnBox ref={Btnsref}>
          <S.Btn
            btnBgc="#469cff"
            fontColor="#fff"
            btnBgcHover="#7cb9ff"
            borderCHover="none"
            margin="0 0.5rem 0 0"
            onClick={handlePrint}
          >
            인쇄하기
          </S.Btn>
          {isCard && (
            <S.Btn
              btnBgc="#417dc2"
              fontColor="#fff"
              btnBgcHover="#7cb9ff"
              borderCHover="none"
              margin="0 0.5rem 0 0"
              onClick={() => {
                window.open(receiptUrl, "_blank");
              }}
            >
              매출전표
            </S.Btn>
          )}
          <S.Btn
            onClick={() => {
              window.close();
            }}
          >
            화면닫기
          </S.Btn>
        </S.PrintBtnBox>
      </S.PrintWrapper>
    </>
  );
};

export default PrintEstimate_order;

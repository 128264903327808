import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { formatDate, formatDateAndTime, formatTime } from "../../hooks/Utill";
import {
  DataGrid,
  GridActionsCellItem,
  GridAddIcon,
  GridCloseIcon,
  GridDeleteIcon,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import axios from "axios";
import { Box, Divider, IconButton, Modal } from "@mui/material";
import { Button } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import ReactQuill from "react-quill";
import {
  CheckCircle,
  Create,
  DeleteForever,
  DocumentScanner,
  Download,
  Edit,
  EditAttributes,
  EditCalendar,
  EditNote,
  EditNoteOutlined,
  EditOff,
  EditOutlined,
  GridView,
  HomeWork,
  Print,
  PrintDisabled,
  ViewColumn,
  ViewKanban,
  Visibility,
  Work,
  WorkOff,
  WorkspacePremium,
} from "@mui/icons-material";
import { set } from "react-ga";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";

const AdminErp = () => {
  const columns = [
    {
      field: "ORDERING_NO",
      headerName: "No",
      width: 50,
      editable: false,
    },
    {
      field: "ORDERING_REGDATE",
      headerName: "접수일시",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDERING_CUSTOMER",
      headerName: "거래처명",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_NM",
      headerName: "발주명",
      // type: "number",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_PAPER",
      headerName: "용지",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_SIZE",
      headerName: "사이즈",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_SIDE",
      headerName: "인쇄",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_FINISHING",
      headerName: "후가공",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_QTY",
      headerName: "수량",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_ADDRESS",
      headerName: "배송처",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_REC",
      headerName: "수령자",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_RECTEL",
      headerName: "수령자 연락처",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_SENDER",
      headerName: "발송인",
      width: 95,
      editable: true,
    },
    {
      field: "ORDERING_STATUS",
      headerName: "처리상태",
      width: 80,
      editable: true,
      type: "singleSelect",
      valueOptions: ["접수", "진행", "완료"],
    },
    {
      field: "ORDERING_COMDATE",
      headerName: "완료일시",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditOutlined />}
            label=""
            sx={{
              color: "primary.main",
            }}
            onClick={() => {
              setSelectedValue(apiRef.current.getRow(id));
              setDetailDialogOpen(true);
              setUpdateORDERING_CUSTOMER(
                apiRef.current.getRow(id).ORDERING_CUSTOMER
              );
              setUpdateORDERING_NM(apiRef.current.getRow(id).ORDERING_NM);
              setUpdateORDERING_PAPER(apiRef.current.getRow(id).ORDERING_PAPER);
              setUpdateORDERING_SIZE(apiRef.current.getRow(id).ORDERING_SIZE);
              setUpdateORDERING_SIDE(apiRef.current.getRow(id).ORDERING_SIDE);
              setUpdateORDERING_FINISHING(
                apiRef.current.getRow(id).ORDERING_FINISHING
              );
              setUpdateORDERING_QTY(apiRef.current.getRow(id).ORDERING_QTY);
              setUpdateORDERING_ADDRESS(
                apiRef.current.getRow(id).ORDERING_ADDRESS
              );
              setUpdateORDERING_REC(apiRef.current.getRow(id).ORDERING_REC);
              setUpdateORDERING_RECTEL(
                apiRef.current.getRow(id).ORDERING_RECTEL
              );
              setUpdateORDERING_SENDER(
                apiRef.current.getRow(id).ORDERING_SENDER
              );
              setUpdateORDERING_DETAIL(
                apiRef.current.getRow(id).ORDERING_DETAIL
              );
              // setBOARD_SID(apiRef.current.getRow(id).BOARD_SID);
              // setDialogOpen(true);
            }}
          />,
          <GridActionsCellItem
            icon={<Print />}
            label=""
            sx={{
              color: "primary.main",
            }}
            onClick={() => {
              setSelectedValue(apiRef.current.getRow(id));

              if (
                selectedValue?.ORDERING_NO ===
                  apiRef?.current?.getRow(id)?.ORDERING_NO &&
                printBoxShow
              ) {
                setPrintBoxShow(false);
              } else {
                setPrintBoxShow(true);
              }

              // setDetailDialogOpen(true);
              // setBOARD_SID(apiRef.current.getRow(id).BOARD_SID);
              // setDialogOpen(true);
            }}
          />,
        ];
      },
    },
  ];

  const [dataRows, setDataRows] = useState([]);
  const apiRef = useGridApiRef();
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);

  const [inputORDERING_CUSTOMER, setInputORDERING_CUSTOMER] = useState("");
  const [inputORDERING_NM, setInputORDERING_NM] = useState("");
  const [inputORDERING_PAPER, setInputORDERING_PAPER] = useState("");
  const [inputORDERING_SIZE, setInputORDERING_SIZE] = useState("");
  const [inputORDERING_SIDE, setInputORDERING_SIDE] = useState("");
  const [inputORDERING_FINISHING, setInputORDERING_FINISHING] = useState("");
  const [inputORDERING_QTY, setInputORDERING_QTY] = useState("");
  const [inputORDERING_DETAIL, setInputORDERING_DETAIL] = useState("");
  const [inputORDERING_ADDRESS, setInputORDERING_ADDRESS] = useState("");
  const [inputORDERING_REC, setInputORDERING_REC] = useState("");
  const [inputORDERING_RECTEL, setInputORDERING_RECTEL] = useState("");
  const [inputORDERING_SENDER, setInputORDERING_SENDER] = useState("");

  const [updateORDERING_CUSTOMER, setUpdateORDERING_CUSTOMER] = useState("");
  const [updateORDERING_NM, setUpdateORDERING_NM] = useState("");
  const [updateORDERING_PAPER, setUpdateORDERING_PAPER] = useState("");
  const [updateORDERING_SIZE, setUpdateORDERING_SIZE] = useState("");
  const [updateORDERING_SIDE, setUpdateORDERING_SIDE] = useState("");
  const [updateORDERING_FINISHING, setUpdateORDERING_FINISHING] = useState("");
  const [updateORDERING_QTY, setUpdateORDERING_QTY] = useState("");
  const [updateORDERING_DETAIL, setUpdateORDERING_DETAIL] = useState("");
  const [updateORDERING_ADDRESS, setUpdateORDERING_ADDRESS] = useState("");
  const [updateORDERING_REC, setUpdateORDERING_REC] = useState("");
  const [updateORDERING_RECTEL, setUpdateORDERING_RECTEL] = useState("");
  const [updateORDERING_SENDER, setUpdateORDERING_SENDER] = useState("");

  const [selectedValue, setSelectedValue] = useState();
  const [open, setOpen] = useState(false);

  const [ImageOpen, setImageOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const detailRef = useRef(null);

  const DownLoadCjExcel = async () => {
    console.log(apiRef.current.getSelectedRows());
    if (apiRef.current.getSelectedRows().size === 0) {
      setSnackbar({
        severity: "error",
        children: "주문을 선택해주세요.",
      });
      return false;
    }

    //
    const fileName = `${formatDate(new Date())} 스노우플래닛 CJ송장`; // 다운로드할 파일 이름
    const data = [];

    apiRef.current.getSelectedRows().forEach((el) => {
      console.log(el);
      const addData = {
        받는분성명: el.ORDERING_REC,
        주소: el.ORDERING_ADDRESS,
        받는분전화번호: el.ORDERING_RECTEL,
        내품명: "",
        품목명: el.ORDERING_NM,
        배송메세지1: "",
        배송메세지3: "",
      };
      data.push(addData);
    });

    const datas = data?.length ? data : []; // data가 없으면 빈 배열

    // 스타일 적용
    const worksheet = XLSX.utils.json_to_sheet(datas);
    const range = XLSX.utils.decode_range(worksheet["!ref"]);

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1"; // 첫 번째 행 주소
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: "F0F0F0" } }, // 배경색 설정
        alignment: { horizontal: "center" }, // 가운데 정렬
      };
    }

    // 열 너비 설정
    const wscols = [
      { wch: 20 }, // 받는분성명 열 너비
      { wch: 50 }, // 주소 열 너비
      { wch: 20 }, // 받는분전화번호 열 너비
      { wch: 20 }, // 내품명 열 너비
      { wch: 20 }, // 품목명 열 너비
      { wch: 20 }, // 배송메세지1 열 너비
      { wch: 20 }, // 배송메세지3 열 너비
    ];
    worksheet["!cols"] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName ? `${fileName}.xlsx` : "data.xlsx");
  };

  const handleImageOpen = (src) => {
    setModalImageSrc(src);
    setImageOpen(true);
  };

  const handleImageClose = () => setImageOpen(false);

  const handleUpdate = async () => {
    const updated_data = {
      ORDERING_CUSTOMER: updateORDERING_CUSTOMER,
      ORDERING_NM: updateORDERING_NM,
      ORDERING_PAPER: updateORDERING_PAPER,
      ORDERING_SIZE: updateORDERING_SIZE,
      ORDERING_SIDE: updateORDERING_SIDE,
      ORDERING_FINISHING: updateORDERING_FINISHING,
      ORDERING_QTY: updateORDERING_QTY,
      ORDERING_RECTEL: updateORDERING_RECTEL,
      ORDERING_SENDER: updateORDERING_SENDER,
      ORDERING_REC: updateORDERING_REC,
      ORDERING_NO: selectedValue.ORDERING_NO,
      ORDERING_STATUS: selectedValue.ORDERING_STATUS,
      ORDERING_DETAIL: updateORDERING_DETAIL,
    };

    const res = await axios.put(
      process.env.REACT_APP_DB_HOST + "/api/admin/ordering",
      { row: updated_data }
    );

    if (res.status === 200) {
      setSnackbar({
        children: "변경을 완료하였습니다.",
        severity: "success",
      });
      initdb();
    } else {
      setSnackbar({
        children: "변경을 실패하였습니다.",
        severity: "error",
      });
    }
  };

  const handleDelete = async () => {
    const confirm = window.confirm("정말로 삭제하시겠습니까?");

    if (!confirm) {
      return;
    }

    const ordering_ids = [];
    apiRef.current.getSelectedRows().forEach((row) => {
      ordering_ids.push(row.ORDERING_NO);
    });
    const res = await axios.delete(
      process.env.REACT_APP_DB_HOST + "/api/admin/ordering",
      { data: { ORDERING_NO: ordering_ids } }
    );

    if (res.status === 200) {
      setSnackbar({
        children: "삭제를 완료하였습니다.",
        severity: "success",
      });
      initdb();
    } else {
      setSnackbar({
        children: "삭제를 실패하였습니다.",
        severity: "error",
      });
    }
  };

  // useEffect(() => {
  //   // 이미지 클릭 이벤트 추가

  //   const images = document.querySelectorAll(".ordering-detail img");
  //   console.log(images);
  //   images.forEach((img) => {
  //     img.addEventListener("click", () => handleImageOpen(img.src));
  //   });

  //   // Cleanup event listeners on component unmount
  //   // return () => {
  //   //   images.forEach((img) => {
  //   //     img.removeEventListener("click", () => handleImageOpen(img.src));
  //   //   });
  //   // };
  // }, [detailDialogOpen]);

  function base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeType });
  }

  const uploadImage = async (file) => {
    const result = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/upload_global",
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result.data;
  };

  useEffect(() => {
    changeImg("input");
  }, [inputORDERING_DETAIL]);

  useEffect(() => {
    changeImg("update");
  }, [updateORDERING_DETAIL]);

  const changeImg = async (mode) => {
    const copy_input =
      mode === "input" ? inputORDERING_DETAIL : updateORDERING_DETAIL;
    if (copy_input.includes(`<img src="data:image/png;base64`)) {
      const img_base64 = copy_input
        .split(`data:image/png;base64`)[1]
        .split(`">`)[0];

      const img_base64_start =
        copy_input.indexOf(`<img src="data:image/png;base64`) + 10;
      const img_base64_last = img_base64.length + 21;

      const img_base64_final = copy_input.slice(
        img_base64_start,
        img_base64_start + img_base64_last
      );

      console.log(base64ToBlob(img_base64_final, "image/png"));

      const formData = new FormData();
      formData.append("file", base64ToBlob(img_base64_final, "image/png"));
      formData.append("name", "admin");
      formData.append("dir", "ordering");

      const result = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/upload_global",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const update_input = copy_input.replace(img_base64_final, result.data);
      mode === "input"
        ? setInputORDERING_DETAIL(update_input)
        : setUpdateORDERING_DETAIL(update_input);
    }
  };

  const handleClickOpen = (row) => {
    setOpen(true);
    setSelectedValue(row);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const initdb = async () => {
    const res = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/ordering")
    ).data;
    setDataRows(res);
  };

  const handleInsertOrdering = async () => {
    const res = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/ordering", {
        ORDERING_CUSTOMER: inputORDERING_CUSTOMER,
        ORDERING_NM: inputORDERING_NM,
        ORDERING_PAPER: inputORDERING_PAPER,
        ORDERING_SIZE: inputORDERING_SIZE,
        ORDERING_SIDE: inputORDERING_SIDE,
        ORDERING_FINISHING: inputORDERING_FINISHING,
        ORDERING_QTY: inputORDERING_QTY,
        ORDERING_DETAIL: inputORDERING_DETAIL,
        ORDERING_ADDRESS: inputORDERING_ADDRESS,
        ORDERING_REC: inputORDERING_REC,
        ORDERING_RECTEL: inputORDERING_RECTEL,
        ORDERING_SENDER: inputORDERING_SENDER,
      })
    ).data;

    if (res) {
      setSnackbar({
        severity: "success",
        children: "발주가 추가되었습니다.",
      });
      initdb();
      setDialogOpen2(false);
    }
  };

  const handleUpdateStatus = async (status) => {
    console.log(apiRef.current.getSelectedRows());

    const ordering_ids = [];

    apiRef.current.getSelectedRows().forEach((row) => {
      ordering_ids.push(row.ORDERING_NO);
    });

    const res = await axios.patch(
      process.env.REACT_APP_DB_HOST + "/api/admin/ordering",
      {
        ORDERING_NO: ordering_ids,
        ORDERING_STATUS: status,
      }
    );

    if (res.status === 200) {
      setSnackbar({
        severity: "success",
        children: "발주 상태가 변경되었습니다.",
      });
      initdb();
    }
  };

  useEffect(() => {
    initdb();
  }, []);

  const [printBoxShow, setPrintBoxShow] = useState(false);

  //그리드 툴바

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Button
            startIcon={<GridAddIcon />}
            onClick={() => {
              setDialogOpen2(true);
            }}
          >
            추가
          </Button>
          <Button
            startIcon={<EditNote />}
            onClick={() => {
              if (apiRef.current.getSelectedRows().size === 0) {
                return;
              }

              handleUpdateStatus("접수");
            }}
          >
            접수
          </Button>
          <Button
            startIcon={<Print />}
            onClick={() => {
              if (apiRef.current.getSelectedRows().size === 0) {
                return;
              }
              handleUpdateStatus("진행");
            }}
          >
            진행
          </Button>
          <Button
            startIcon={<CheckCircle />}
            onClick={() => {
              if (apiRef.current.getSelectedRows().size === 0) {
                return;
              }
              handleUpdateStatus("완료");
            }}
          >
            완료
          </Button>
          <Button
            startIcon={<DeleteForever />}
            onClick={() => {
              if (apiRef.current.getSelectedRows().size === 0) {
                return;
              }
              handleDelete();
            }}
          >
            삭제
          </Button>
          <Button
            startIcon={<Download />}
            onClick={() => {
              DownLoadCjExcel();
            }}
          >
            CJ 송장
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box>
        <DataGrid
          apiRef={apiRef}
          rows={dataRows}
          columns={columns}
          rowHeight={38}
          sx={{
            fontSize: "12px",
          }}
          // onCellEditStop={(params) => {
          //   console.log(params);
          //   handleEditUser(params);
          // }}

          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          processRowUpdate={(updatedRow, originalRow) => {
            handleClickOpen(updatedRow);
            return updatedRow;
          }}
        />
      </Box>
      {/* 다이얼로그2 */}
      <Dialog
        open={dialogOpen2}
        onClose={() => {
          setDialogOpen2(false);
          setSnackbar({
            severity: "info",
            children: "발주생성이 취소되었습니다.",
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          발주생성
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TableContainer component={Paper}>
              <Table>
                <TableRow>
                  <TableCell>거래처명</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_CUSTOMER}
                      onChange={(e) => {
                        setInputORDERING_CUSTOMER(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>발주명</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_NM}
                      onChange={(e) => {
                        setInputORDERING_NM(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>용지</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_PAPER}
                      onChange={(e) => {
                        setInputORDERING_PAPER(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>사이즈</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_SIZE}
                      onChange={(e) => {
                        setInputORDERING_SIZE(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>인쇄</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_SIDE}
                      onChange={(e) => {
                        setInputORDERING_SIDE(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>후가공</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_FINISHING}
                      onChange={(e) => {
                        setInputORDERING_FINISHING(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>수량</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_QTY}
                      onChange={(e) => {
                        setInputORDERING_QTY(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>배송처</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_ADDRESS}
                      onChange={(e) => {
                        setInputORDERING_ADDRESS(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>수령자</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_REC}
                      onChange={(e) => {
                        setInputORDERING_REC(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>수령자 연락처</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_RECTEL}
                      onChange={(e) => {
                        setInputORDERING_RECTEL(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>발송인</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      value={inputORDERING_SENDER}
                      onChange={(e) => {
                        setInputORDERING_SENDER(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>비고</TableCell>
                  <TableCell>
                    <ReactQuill
                      style={{
                        width: "300px",
                      }}
                      value={inputORDERING_DETAIL}
                      onChange={setInputORDERING_DETAIL}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>첨부파일</TableCell>
                  <TableCell>
                    <Button variant="contained">첨부파일 추가</Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen2(false);
              setSnackbar({
                severity: "info",
                children: "단가생성이 취소되었습니다.",
              });
            }}
          >
            취소
          </Button>
          <Button autoFocus onClick={handleInsertOrdering}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      {/* 다이얼로그 상세보기 */}
      <Dialog
        open={detailDialogOpen}
        onClose={() => {
          setDetailDialogOpen(false);
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            width: "100%",
            padding: "16px",
            fontSize: "24px",
          }}
        >
          발주 상세
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableRow>
                <TableCell
                  sx={{
                    width: "150px",
                  }}
                  l
                >
                  발주번호
                </TableCell>
                <TableCell
                  sx={{
                    width: "500px",
                  }}
                >
                  {selectedValue && selectedValue.ORDERING_NO}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>접수일시</TableCell>
                <TableCell>
                  {selectedValue &&
                    formatDateAndTime(selectedValue.ORDERING_REGDATE)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>거래처명</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_CUSTOMER}
                    onChange={(e) => {
                      setUpdateORDERING_CUSTOMER(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>발주명</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_NM}
                    onChange={(e) => {
                      setUpdateORDERING_NM(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>용지</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_PAPER}
                    onChange={(e) => {
                      setUpdateORDERING_PAPER(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>사이즈</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_SIZE}
                    onChange={(e) => {
                      setUpdateORDERING_SIZE(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>인쇄</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_SIDE}
                    onChange={(e) => {
                      setUpdateORDERING_SIDE(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>후가공</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_FINISHING}
                    onChange={(e) => {
                      setUpdateORDERING_FINISHING(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>수량</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_QTY}
                    onChange={(e) => {
                      setUpdateORDERING_QTY(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>완료일시</TableCell>
                <TableCell>
                  {selectedValue &&
                    formatDateAndTime(selectedValue.ORDERING_COMDATE)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>처리상태</TableCell>
                <TableCell>
                  {selectedValue && selectedValue.ORDERING_STATUS}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>배송처</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_ADDRESS}
                    onChange={(e) => {
                      setUpdateORDERING_ADDRESS(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>수령자</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_REC}
                    onChange={(e) => {
                      setUpdateORDERING_REC(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>수령자 연락처</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_RECTEL}
                    onChange={(e) => {
                      setUpdateORDERING_RECTEL(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>발송인</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={updateORDERING_SENDER}
                    onChange={(e) => {
                      setUpdateORDERING_SENDER(e.target.value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>비고</TableCell>
                <TableCell>
                  <ReactQuill
                    style={{
                      width: "100%",
                    }}
                    value={updateORDERING_DETAIL}
                    onChange={setUpdateORDERING_DETAIL}
                  />
                  <Divider sx={{ margin: "16px 0" }} />
                  <div
                    ref={detailRef}
                    onMouseEnter={() => {
                      const images = detailRef.current.querySelectorAll("img");
                      images.forEach((img) => {
                        console.log("Image src:", img.src);
                        img.addEventListener("click", () =>
                          handleImageOpen(img.src)
                        );
                        img.style.cursor = "zoom-in";
                      });
                    }}
                    // onClick={() => {
                    //   const images = detailRef.current.querySelectorAll("img");
                    //   images.forEach((img) => {
                    //     console.log("Image src:", img.src);
                    //     img.addEventListener("click", () =>
                    //       handleImageOpen(img.src)
                    //     );
                    //   });
                    // }}
                    className="ordering-detail" // 특정 클래스를 부여
                    dangerouslySetInnerHTML={{
                      __html: selectedValue?.ORDERING_DETAIL,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>첨부파일</TableCell>
                <TableCell>
                  <Button variant="contained">첨부파일 추가</Button>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
          <DialogActions>
            <Button
              onClick={() => {
                setDetailDialogOpen(false);
              }}
            >
              취소
            </Button>
            <Button autoFocus onClick={handleUpdate}>
              수정
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {printBoxShow && <OrderingErpPrintBox DETAIL={selectedValue} />}

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar(false)}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(false)} />
        </Snackbar>
      )}
      <ChoiceDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setSnackbar={setSnackbar}
        initdb={initdb}
      />
      {/* 이미지 확대를 위한 모달 */}
      <Modal
        open={ImageOpen}
        onClose={handleImageClose}
        sx={{
          zIndex: 9999,
        }}
      >
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            height="100vh"
            bgcolor="rgba(0, 0, 0, 0.8)"
          >
            <img
              onClick={handleImageClose}
              src={modalImageSrc}
              alt="Full Size"
              style={{ maxWidth: "90%", maxHeight: "90%", cursor: "zoom-out" }}
            />
            <Box mt={2}>
              <Button
                variant="contained"
                onClick={handleImageClose}
                size="large"
              >
                닫기
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default AdminErp;

const ChoiceDialog = (props) => {
  const { onClose, selectedValue, open, setSnackbar, initdb } = props;

  const handleClose = () => {
    onClose();
    setSnackbar({
      children: "변경을 취소하였습니다.",
      severity: "info",
    });
  };

  const handleYes = async () => {
    const res = await axios.put(
      process.env.REACT_APP_DB_HOST + "/api/admin/ordering",
      {
        row: selectedValue,
      }
    );
    if (res.status === 200) {
      setSnackbar({
        children: "변경을 완료하였습니다.",
        severity: "success",
      });
      initdb();
    } else {
      setSnackbar({
        children: "변경을 실패하였습니다.",
        severity: "error",
      });
    }
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleYes();
        }
      }}
    >
      {/* <DialogTitle id="alert-dialog-title">알림</DialogTitle> */}
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        알림
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          '{selectedValue?.ORDERING_NM}' 발주 정보를 변경하시겠습니까?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleYes} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OrderingErpPrintBox = (props) => {
  const TitleCell = styled(TableCell)`
    width: 20%;
    font-size: 14pt;
    text-align: center !important;
    border: 1px solid #333 !important;
    padding: 6px;
    word-wrap: break-word;
  `;
  const ContentCell = styled(TableCell)`
    width: 80%;
    font-size: 14pt;
    text-align: left !important;
    border: 1px solid #333 !important;
    padding: 6px;
    word-wrap: break-word;
  `;

  const handlePrint = useReactToPrint({
    content: () => Printref.current,
    documentTitle: "제작의뢰서",
    // onAfterPrint: () => alert("파일 다운로드 후 알림창 생성 가능"),
  });

  const Printref = useRef();

  const tableRef = useRef(null);
  const detailRowsRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [detailHeight, setDetailHeight] = useState(0);

  const PrintAutoHeightinterval = setInterval(() => {
    setDetailHeight(950 - tableRef?.current?.offsetHeight);

    if (
      950 - tableRef?.current?.offsetHeight - 80 <
      detailRowsRef?.current?.offsetHeight
    ) {
      setScale(
        (950 - tableRef?.current?.offsetHeight - 80) /
          detailRowsRef?.current?.offsetHeight
      );
    } else {
      setScale(1);
    }
  }, 1000);

  useEffect(() => {}, [props]);

  return (
    <>
      <Box
        className="btnWrap"
        sx={{
          textAlign: "center",
          padding: "1rem",
        }}
      >
        <Button variant="contained" onClick={handlePrint}>
          인쇄하기
        </Button>
      </Box>
      <Box
        sx={{
          width: "209mm",
          height: "296mm",
          padding: "2rem",
          border: "1px dotted #000",
          margin: "0 auto",
          maxWidth: "209mm",
          maxHeight: "296mm",
          overflow: "hideen",
        }}
        ref={Printref}
      >
        <Box
          sx={{
            fontSize: "26pt",
            textAlign: "center",
            padding: "2rem",
            fontWeight: "bold",
            paddingTop: "0",
          }}
        >
          스노우화이트 제작의뢰서
        </Box>
        <Table
          sx={{
            width: "100%",
            tableLayout: "fixed",
            borderCollapse: "collapse",
          }}
          ref={tableRef}
        >
          <TableRow>
            <TitleCell>발주일</TitleCell>
            <ContentCell>
              {formatDate(props?.DETAIL?.ORDERING_REGDATE)} /{" "}
              {formatTime(props?.DETAIL?.ORDERING_REGDATE)}
            </ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>거래처명</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_CUSTOMER}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>품목명</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_NM}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>용지</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_PAPER}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>사이즈</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_SIZE}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>인쇄</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_SIDE}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>후가공</TitleCell>
            <ContentCell
              sx={{
                fontWeight: "bold",
              }}
            >
              {props?.DETAIL?.ORDERING_FINISHING}
            </ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>수량</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_QTY}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>배송처</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_ADDRESS}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>수령자</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_REC}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>수령자연락처</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_RECTEL}</ContentCell>
          </TableRow>
          <TableRow>
            <TitleCell>발송인</TitleCell>
            <ContentCell>{props?.DETAIL?.ORDERING_SENDER}</ContentCell>
          </TableRow>
        </Table>
        <Box
          height={detailHeight + 60}
          paddingBottom={"16px"}
          overflow={"hidden"}
          className="detailRows"
        >
          <Box
            sx={{
              padding: "16px",
              fontSize: "24px",
              textAlign: "center",
              border: "1px solid #333",

              mb: "8px",
              mt: "8px",
            }}
          >
            비고
          </Box>
          <Box ref={detailRowsRef} sx={{}}>
            <div
              style={{
                transform: `scale(${scale})`,
                transformOrigin: "top center", // 왼쪽 위를 기준으로 축소
                width: "100%",
              }}
              dangerouslySetInnerHTML={{
                __html: props?.DETAIL?.ORDERING_DETAIL,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

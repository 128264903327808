import React, { useState } from "react";
import { NavBar } from "./New_EditorSideStyle";
import New_EditorSideTabs from "./New_EditorSideTabs";
import New_EditorSideAddition from "./New_EditorSideAddition";
const New_EditorSidePanel = ({
  editorRef,
  selectedNode,
  setSelectedNode,
  pages,
  currentPage,
}) => {
  const [activeItem, setActiveItem] = useState("텍스트");

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <NavBar>
      <New_EditorSideTabs
        activeItem={activeItem}
        onItemClick={handleItemClick}
      />
      <New_EditorSideAddition
        editorRef={editorRef}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        activeItem={activeItem}
        pages={pages}
        currentPage={currentPage}
      />
    </NavBar>
  );
};

export default New_EditorSidePanel;

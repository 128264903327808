import React from "react";
import PrintEstimate_order from "../components/products/PrintEstimate_order";
import { useParams } from "react-router-dom";

const OrderReceiptPage = () => {
  const { order_sid } = useParams();
  return (
    <>
      <PrintEstimate_order order_sid={order_sid} />
    </>
  );
};

export default OrderReceiptPage;

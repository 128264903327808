import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  SideAdditionContainer,
  SideAdditionInner,
  SideAdditionTitle,
  SideAdditionContent,
  TextTable,
  TextTableCell,
  BgColorBox,
  BgColorPanel,
  BgColorPanelItem,
  BgColorPanelItem2,
} from "./New_EditorSideStyle";
import {
  ArrowDropDown,
  ArrowDropUp,
  CloudUpload,
  ColorLens,
  ComputerTwoTone,
  QrCode,
} from "@mui/icons-material";
import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import { toPng } from "html-to-image";
import { useProgress } from "../Editor_ProgressContext";
import JsBarcode from "jsbarcode";
import { FaBarcode } from "react-icons/fa";
import { GridCloseIcon } from "@mui/x-data-grid";
import * as S from "../../../styles/new_styles";
import { SketchPicker } from "react-color";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { useQuery } from "react-query";
const New_EditorSideAddition = ({
  editorRef,
  selectedNode,
  setSelectedNode,
  activeItem,
  pages,
  currentPage,
}) => {
  const { progress, setProgress } = useProgress();
  const [snackbar, setSnackbar] = useState(null);

  // 배경 설정
  const [bgColorPickerOpen, setBgColorPickerOpen] = useState(false);
  const [bgColor, setBgColor] = useState("#ffffff");
  const colorPickerAnimation = useSpring({
    opacity: bgColorPickerOpen ? 1 : 0,
    transform: bgColorPickerOpen ? "scale(1)" : "scale(0)",
    display: bgColorPickerOpen ? "block" : "none",
    config: { duration: 150 },
    zIndex: bgColorPickerOpen ? 1002 : 1000,
  });
  const [bgColorPanelFirstOpen, setBgColorPanelFirstOpen] = useState(true);
  const [bgColorPanelSecondOpen, setBgColorPanelSecondOpen] = useState(false);
  // AI 이미지 생성
  const [dialogOpen, setDialogOpen] = useState(false);
  const [InputAiPrompt, setInputAiPrompt] = useState("");
  const [showKeywords, setShowKeywords] = useState(false);
  const [recentKeywords, setRecentKeywords] = useState([]);

  const { data } = useQuery("userinfo", { enabled: false });
  const userId = data?.USER_ID;

  const [aiImages, setAiImages] = useState([]);

  const fetchAiImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_HOST}/api/openai/image?userId=${userId}`
      );
      setAiImages(response.data.row);
    } catch (error) {
      console.error("AI 이미지 목록을 불러오는 데 실패했습니다:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchAiImages();
    }
  }, [userId]);

  const handleMakeAiImage = async () => {
    // handleCreateUploadImage(
    //   "https://oaidalleapiprodscus.blob.core.windows.net/private/org-AT1CDyy8w9YZFWZ3Bdgar8Xv/user-rmMahpS9rDtepfkSkmEaHVtR/img-e8R8LyZ4HEsT4CTvKAGTjMcR.png?st=2024-06-28T06%3A19%3A43Z&se=2024-06-28T08%3A19%3A43Z&sp=r&sv=2023-11-03&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-06-28T01%3A41%3A35Z&ske=2024-06-29T01%3A41%3A35Z&sks=b&skv=2023-11-03&sig=neoKQp6gHLJsY%2BDsFofk8UZnbvYl%2BEPYzMH5DXKzURE%3D"
    // );

    setProgress(true);
    const res = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/openai/image",
      {
        prompt: InputAiPrompt,
        userId: userId,
      }
    );

    if (res.status === 200) {
      setSnackbar({
        children: "이미지가 생성되었습니다.",
        severity: "success",
      });
      console.log("AI생성한거 res임", res);
      console.log("경로임", res.data.FIleUrl);
      const updatedKeywords = [InputAiPrompt, ...recentKeywords.slice(0, 4)];
      setRecentKeywords(updatedKeywords);
      localStorage.setItem("recentAIKeywords", JSON.stringify(updatedKeywords));
      editorRef.current[currentPage].current.functions.addImage(
        res.data.FIleUrl,
        300,
        300
      );
    } else {
      setSnackbar({
        children: "이미지 생성에 실패했습니다. 키워드를 다시 확인해주세요.",
        severity: "error",
      });
    }
    setInputAiPrompt("");
    setDialogOpen(false);
    setProgress(false);
    fetchAiImages();
  };

  //바코드
  const [barcodeValue, setBarcodeValue] = useState("");
  const [showBarcodeDialog, setShowBarcodeDialog] = useState(false);
  const handleBarcodeGenerate = async () => {
    if (barcodeValue) {
      try {
        setProgress(true);
        const barcodeElement = document.getElementById("barcode");
        JsBarcode("#barcode", barcodeValue, {
          format: "CODE128",
          width: 2,
          height: 100,
          displayValue: true,
        });

        const pngUrl = await toPng(barcodeElement);
        editorRef.current[currentPage].current.functions.addImage(
          pngUrl,
          150,
          100
        );
        setSnackbar({
          children: "바코드가 생성되었습니다.",
          severity: "success",
        });
      } catch (error) {
        console.error("바코드 생성 실패:", error);
        setSnackbar({
          children: "바코드 생성에 실패했습니다.",
          severity: "error",
        });
      }

      setShowBarcodeDialog(false);
      setBarcodeValue("");
      setProgress(false);
    }
  };

  //QR코드
  const [showQrDialog, setShowQrDialog] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const handleQrCodeGenerate = async () => {
    if (qrValue) {
      try {
        setProgress(true);
        const qrCodeElement = document.getElementById("qrCode");
        const pngUrl = await toPng(qrCodeElement);
        console.log(pngUrl);
        editorRef.current[currentPage].current.functions.addImage(
          pngUrl,
          100,
          100
        );
        setSnackbar({
          children: "QR코드가 생성되었습니다.",
          severity: "success",
        });
      } catch (error) {
        console.error("QR코드 생성 실패:", error);
        setSnackbar({
          children: "QR코드 생성에 실패했습니다.",
          severity: "error",
        });
      }

      setShowQrDialog(false);
      setQrValue("");
      setProgress(false);
    }
  };

  const renderBgPanelItem = (color) => {
    return (
      <BgColorPanelItem
        color={color}
        onClick={() => {
          editorRef.current[currentPage].current.functions.changeBgColor(color);
        }}
      />
    );
  };

  // activeItem에 따른 컨텐츠 렌더링 로직
  const renderContent = () => {
    switch (activeItem) {
      case "템플릿":
        return <div></div>;
      case "텍스트":
        return (
          <>
            <div>
              <Button
                fullWidth
                sx={{
                  fontSize: "24px",
                }}
                onClick={() => {
                  editorRef.current[currentPage].current.functions.addText(
                    "텍스트",
                    60,
                    "Arial",
                    "normal",
                    "center",
                    0,
                    1,
                    "black",
                    1,
                    0,
                    0
                  );
                }}
              >
                제목 추가
              </Button>
              <Button
                fullWidth
                sx={{
                  fontSize: "18px",
                }}
                onClick={() => {
                  editorRef.current[currentPage].current.functions.addText(
                    "텍스트",
                    40,
                    "Arial",
                    "normal",
                    "center",
                    0,
                    1,
                    "black",
                    1,
                    0,
                    0
                  );
                }}
              >
                부제목 추가
              </Button>
              <Button
                fullWidth
                sx={{
                  fontSize: "14px",
                }}
                onClick={() => {
                  editorRef.current[currentPage].current.functions.addText(
                    "텍스트",
                    20,
                    "Arial",
                    "normal",
                    "center",
                    0,
                    1,
                    "black",
                    1,
                    0,
                    0
                  );
                }}
              >
                본문 추가
              </Button>
            </div>
            <TextTable>
              <TextTableCell
                onClick={() => {
                  setTimeout(() => {
                    editorRef.current[currentPage].current.functions.addText(
                      "SALE",
                      60,
                      "Anton",
                      "bold",
                      "center",
                      0,
                      1,
                      "rgba(0, 0, 0, 0.3)",
                      1,
                      5,
                      5
                    );
                  }, 0);
                  setTimeout(() => {
                    editorRef.current[currentPage].current.functions.addText(
                      "SALE",
                      60,
                      "Anton",
                      "bold",
                      "center",
                      0,
                      1,
                      "black",
                      1,
                      0,
                      0
                    );
                  }, 50);
                }}
              >
                <img src={"/editor/TextImage/SALE.png"} alt={"SALE"} />
              </TextTableCell>
            </TextTable>
          </>
        );
      case "클립아트":
        return (
          <TextTable>
            {Array.from({ length: 14 }).map((item, index) => (
              <TextTableCell
                key={index}
                onClick={() => {
                  setTimeout(() => {
                    editorRef.current[currentPage].current.functions.addImage(
                      `/editor/ClipArtImage/${index + 1}.png`
                    );
                  }, 0);
                }}
              >
                <img
                  src={`/editor/ClipArtImage/${index + 1}.png`}
                  alt={index + 1}
                />
              </TextTableCell>
            ))}
          </TextTable>
        );
      case "업로드":
        return (
          <>
            <div>
              <Button
                startIcon={<CloudUpload />}
                fullWidth
                variant="outlined"
                onClick={() => {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", ".png,.jpg");
                  input.click();
                  input.addEventListener("change", async () => {
                    const file = input.files[0];

                    //체크
                    const allowedExtensions = [".png", ".jpg"];
                    const fileExtension = file.name
                      .split(".")
                      .pop()
                      .toLowerCase();
                    if (!allowedExtensions.includes("." + fileExtension)) {
                      setSnackbar({
                        children:
                          "올바른 파일 형식을 선택해주세요. (png 또는 jpg)",
                        severity: "error",
                      });
                      return;
                    }

                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("type", "design");
                    formData.append("dir", "editor");
                    formData.append("userid", "tk");
                    try {
                      setProgress(true);
                      const result = await axios.post(
                        process.env.REACT_APP_DB_HOST + "/api/upload_global",
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const fileUrl = result.data;
                      setSnackbar({
                        children: "파일이 업로드 되었습니다.",
                        severity: "success",
                      });
                      editorRef.current[currentPage].current.functions.addImage(
                        fileUrl
                      );
                      setProgress(false);
                    } catch (error) {
                      console.log(error);
                      console.log("실패");
                      setProgress(false);
                    }
                  });
                }}
              >
                이미지 업로드
              </Button>
              <Button
                startIcon={<QrCode />}
                fullWidth
                variant="outlined"
                onClick={() => {
                  setShowQrDialog(true);
                }}
                sx={{ marginTop: "10px" }}
              >
                QR코드 생성
              </Button>
              <Button
                startIcon={<FaBarcode />}
                fullWidth
                variant="outlined"
                onClick={() => {
                  setShowBarcodeDialog(true);
                }}
                sx={{ marginTop: "10px" }}
              >
                바코드 생성
              </Button>
              <Button
                startIcon={<ComputerTwoTone />}
                fullWidth
                variant="outlined"
                onClick={() => {
                  setDialogOpen(true);
                }}
                sx={{ marginTop: "10px" }}
              >
                AI 이미지 생성
              </Button>
            </div>
            <Divider sx={{ margin: "20px 0" }} />
            <Box>
              <Box
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                생성된 이미지
              </Box>
              <TextTable height={"65vh"}>
                {aiImages.map((image, index) => (
                  <TextTableCell
                    key={index}
                    onClick={() => {
                      editorRef.current[currentPage].current.functions.addImage(
                        `/upload/AI/${userId}/${image.AI_IMAGE_FILE}`
                      );
                    }}
                  >
                    <img
                      src={`/upload/AI/${userId}/${image.AI_IMAGE_FILE}`}
                      alt={`AI 생성 이미지 ${index + 1}`}
                    />
                  </TextTableCell>
                ))}
              </TextTable>
            </Box>
          </>
        );
      case "도형":
        return (
          <TextTable>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Circle"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Circle.png"} alt={"Circle"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Rect"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Rect.png"} alt={"Rect"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Star"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Star.png"} alt={"Star"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Triangle"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Triangle.png"} alt={"Triangle"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Ellipse"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Ellipse.png"} alt={"Ellipse"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Pentagon"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Pentagon.png"} alt={"Pentagon"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Hexagon"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Hexagon.png"} alt={"Hexagon"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Arrow"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Arrow.png"} alt={"Arrow"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Path"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Path.png"} alt={"Path"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Wedge"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Wedge.png"} alt={"Wedge"} />
            </TextTableCell>
            <TextTableCell
              onClick={() => {
                editorRef.current[currentPage].current.functions.addShape(
                  "Ring"
                );
              }}
            >
              <img src={"/editor/ShapeImage/Ring.png"} alt={"Ring"} />
            </TextTableCell>
          </TextTable>
        );
      case "배경":
        return (
          <>
            <BgColorBox>
              <Button
                fullWidth
                startIcon={<ColorLens />}
                onClick={() => {
                  setBgColorPickerOpen(!bgColorPickerOpen);
                }}
              >
                직접 선택
              </Button>
              <BgColorPanelItem2
                color={bgColor}
                onClick={() => {
                  setBgColorPickerOpen(!bgColorPickerOpen);
                }}
              />
              <ColorPickerBox style={colorPickerAnimation}>
                <SketchPicker
                  color={bgColor}
                  onChange={(color) => {
                    setBgColor(color.hex);
                    editorRef.current[
                      currentPage
                    ].current.functions.changeBgColor(color.hex);
                  }}
                  onClose={() => {
                    setBgColorPickerOpen(false);
                  }}
                />
              </ColorPickerBox>
              <Divider sx={{ margin: "20px 0" }} />
              <Button
                fullWidth
                endIcon={
                  bgColorPanelFirstOpen ? <ArrowDropUp /> : <ArrowDropDown />
                }
                onClick={() => {
                  setBgColorPanelFirstOpen(!bgColorPanelFirstOpen);
                }}
              >
                기본 색상
              </Button>
              {bgColorPanelFirstOpen && (
                <BgColorPanel>
                  {renderBgPanelItem("#000")}
                  {renderBgPanelItem("#333")}
                  {renderBgPanelItem("#777")}
                  {renderBgPanelItem("#ccc")}
                  {renderBgPanelItem("#fff")}
                  {renderBgPanelItem("red")}
                  {renderBgPanelItem("orange")}
                  {renderBgPanelItem("yellow")}
                  {renderBgPanelItem("green")}
                  {renderBgPanelItem("blue")}
                  {renderBgPanelItem("navy")}
                  {renderBgPanelItem("purple")}
                  {renderBgPanelItem("#499700")}
                  {renderBgPanelItem("#2b59bb")}
                  {renderBgPanelItem("#f3128e")}
                </BgColorPanel>
              )}
              <Button
                fullWidth
                endIcon={
                  bgColorPanelSecondOpen ? <ArrowDropUp /> : <ArrowDropDown />
                }
                onClick={() => {
                  setBgColorPanelSecondOpen(!bgColorPanelSecondOpen);
                }}
              >
                파스텔 색상
              </Button>
              {bgColorPanelSecondOpen && (
                <BgColorPanel>
                  {renderBgPanelItem("#ff0000")}
                  {renderBgPanelItem("#ff3c3c")}
                  {renderBgPanelItem("#ff6868")}
                  {renderBgPanelItem("#ffa1a1")}
                  {renderBgPanelItem("#ffe2e2")}
                  {renderBgPanelItem("#1900ff")}
                  {renderBgPanelItem("#4f3bff")}
                  {renderBgPanelItem("#8679ff")}
                  {renderBgPanelItem("#b5adff")}
                  {renderBgPanelItem("#e9e6ff")}
                  {renderBgPanelItem("#fff000")}
                  {renderBgPanelItem("rgb(255, 242, 65)")}
                  {renderBgPanelItem("#fff678")}
                  {renderBgPanelItem("#fffaad")}
                  {renderBgPanelItem("#fffcd0")}
                  {renderBgPanelItem("#15ff00")}
                  {renderBgPanelItem("#56ff47")}
                  {renderBgPanelItem("#88ff7d")}
                  {renderBgPanelItem("#c0ffba")}
                  {renderBgPanelItem("#edffeb")}
                </BgColorPanel>
              )}
            </BgColorBox>
          </>
        );
      default:
        return <div>기본 컨텐츠</div>;
    }
  };

  return (
    <>
      <SideAdditionContainer>
        <SideAdditionInner>
          <SideAdditionTitle>{activeItem}</SideAdditionTitle>
          <SideAdditionContent>{renderContent()}</SideAdditionContent>
        </SideAdditionInner>
      </SideAdditionContainer>
      {/* 스낵바 */}
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setSnackbar(null);
          }}
          autoHideDuration={3000}
        >
          <Alert
            {...snackbar}
            onClose={() => {
              setSnackbar(null);
            }}
          />
        </Snackbar>
      )}
      {/* QR코드 다이얼로그 */}
      <Dialog open={showQrDialog} onClose={() => setShowQrDialog(false)}>
        <DialogTitle>QR 코드 생성</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="QR 코드 내용"
            value={qrValue}
            onChange={(e) => setQrValue(e.target.value)}
            margin="normal"
          />
          {qrValue && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <div id="qrCode">
                <QRCodeSVG value={qrValue} />
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowQrDialog(false)}>취소</Button>
          <Button onClick={handleQrCodeGenerate} disabled={!qrValue}>
            생성
          </Button>
        </DialogActions>
      </Dialog>
      {/* 바코드 다이얼로그 */}
      <Dialog
        open={showBarcodeDialog}
        onClose={() => setShowBarcodeDialog(false)}
      >
        <DialogTitle>바코드 생성</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="바코드 내용"
            value={barcodeValue}
            onChange={(e) => {
              setBarcodeValue(e.target.value);
              if (barcodeValue !== "" && e.target.value !== "") {
                const barcodeElement = document.getElementById("barcode");
                JsBarcode("#barcode", e.target.value, {
                  format: "CODE128",
                  width: 2,
                  height: 100,
                  displayValue: true,
                });
              }
            }}
            margin="normal"
          />
          {barcodeValue && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <svg id="barcode"></svg>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowBarcodeDialog(false)}>취소</Button>
          <Button onClick={handleBarcodeGenerate} disabled={!barcodeValue}>
            생성
          </Button>
        </DialogActions>
      </Dialog>
      {/* AI 이미지 생성 다이얼로그 */}
      <Dialog
        onClose={() => {
          setDialogOpen(false);
        }}
        open={dialogOpen}
      >
        {/* <DialogTitle id="alert-dialog-title">알림</DialogTitle> */}
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          AI 이미지 생성
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ mb: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                color="info"
                onClick={() => {
                  // 최근 키워드 검색 기록을 보여주는 함수 호출
                  setShowKeywords(!showKeywords);
                }}
              >
                최근 키워드 검색 보기
              </Button>
              <Box
                sx={{ position: "relative" }}
                display={showKeywords ? "block" : "none"}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    color: "#0288d1",
                    zIndex: 1000,
                  }}
                >
                  <List>
                    {recentKeywords.length > 0 ? (
                      recentKeywords.map((keyword, index) => (
                        <ListItemButton
                          key={index}
                          onClick={() => {
                            setInputAiPrompt(keyword);
                            setShowKeywords(false);
                          }}
                        >
                          <ListItemText primary={keyword} />
                        </ListItemButton>
                      ))
                    ) : (
                      <ListItemButton>
                        <ListItemText primary="검색 기록이 없습니다" />
                      </ListItemButton>
                    )}
                  </List>
                </Box>
              </Box>
            </Box>
            <TextField
              fullWidth
              label="키워드"
              name="Aikeyword"
              value={InputAiPrompt}
              onChange={(e) => {
                setInputAiPrompt(e.target.value);
              }}
            />
          </DialogContentText>
          <Typography
            variant="body2"
            sx={{ mt: 2, mb: 0, fontWeight: "bold", color: "#333" }}
          >
            프롬프트 사용법
          </Typography>
          <ul
            style={{
              paddingLeft: "20px",
              fontSize: "14px",
              listStyleType: "none",
              backgroundColor: "#eee",
              borderRadius: "8px",
              padding: "15px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              구체적인 설명을 사용하세요
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              "귀여운 팬더"보다는 "대나무 숲에서 앉아있는 검은 눈 주위와 귀를
              가진 흑백의 푹신한 팬더, 입에는 대나무 잎을 물고 있음"
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              원하는 스타일을 명시하세요 (예: "수채화 스타일의", "팝아트
              스타일의")
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              색상, 구도, 조명 등 세부 사항을 포함하세요
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              원하지 않는 요소는 "~없이"로 표현하세요
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              여러 개념을 결합할 때는 쉼표를 사용하세요
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMakeAiImage}>생성</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default New_EditorSideAddition;

const ColorPickerBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 0px;
    left: 100px;
    width: 200px;
    height: 300px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1002 !important;
  }
`;

import {
  Article,
  Category,
  CloudUpload,
  FormatColorFill,
  PermMedia,
  PictureAsPdf,
  TextFields,
  Widgets,
} from "@mui/icons-material";
import React from "react";
import { SideTabs, SideInner, PanelItem } from "./New_EditorSideStyle";
import { IconButton, Typography } from "@mui/material";

const New_EditorSideTabs = ({ activeItem, onItemClick }) => {
  const tabs = [
    // { name: "템플릿", icon: <Widgets /> },
    { name: "텍스트", icon: <TextFields /> },
    { name: "클립아트", icon: <PermMedia /> },
    { name: "도형", icon: <Category /> },
    { name: "업로드", icon: <CloudUpload /> },
    { name: "배경", icon: <FormatColorFill /> },
  ];

  return (
    <SideTabs>
      <SideInner>
        {tabs.map((tab) => (
          <PanelItem
            onClick={() => onItemClick(tab.name)}
            key={tab.name}
            className={activeItem === tab.name ? "active" : ""}
          >
            <IconButton>
              {tab.icon}
              <Typography>{tab.name}</Typography>
            </IconButton>
          </PanelItem>
        ))}
      </SideInner>
    </SideTabs>
  );
};

export default New_EditorSideTabs;

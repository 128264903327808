import React from "react";
import { Box } from "@mui/material";

import DieCutSticker from "./DieCutSticker";
import ThomsonSticker from "./ThomsonSticker";
import PackageBox from "./PackageBox";
import PackageCorBox from "./PackageCorBox";
import PackageShopBag from "./PackageShopBag";

import NameCard from "./Prods/NameCard/NameCard";
import NameCard_Premium from "./Prods/NameCard/NameCard_Premium";
import Flyer from "./Prods/Flyer/Flyer";
import PostCard from "./Prods/PostCard/PostCard";
import Leaflet from "./Prods/Leaflet/Leaflet";
import Booklet from "./Prods/Booklet/Booklet";
import Brochure from "./Prods/Booklet/Brochure";
import SpringNoteBook from "./Prods/Booklet/SpringNoteBook";
import Proposal from "./Prods/Booklet/Proposal";
import NoteBook from "./Prods/Booklet/Notebook";
import Xbanner from "./Prods/XBanner/Xbanner";
import Banner from "./Prods/Banner/Banner";
import NameCard_Fleece from "./Prods/NameCard/NameCard_Fleece";
import Poster from "./Prods/Poster/Poster";
import BigEnvelope from "./Prods/Envelope/BigEnvelope";
import SmallEnvelope from "./Prods/Envelope/SmallEnvelope";
import Sticker_single from "./Prods/Sticker/Sticker_single";
import Sticker_DIY from "./Prods/Sticker/Sticker_DIY";
import Sticker_circle from "./Prods/Sticker/Sticker_circle";

const ProdOptions = ({
  prod,
  SelectOptions,
  setSelectOptions,
  optionList,
  setOptionList,
}) => {
  return (
    <Box
      sx={{
        padding: "12px 0",
        margin: "12px 0",
        borderTop: "1px solid #e0e0e0",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      {prod === "일반지명함" && (
        <NameCard
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "고급지명함" && (
        <NameCard_Premium
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "펄지명함" && (
        <NameCard_Fleece
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "전단지" && (
        <Flyer
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "엽서" && (
        <PostCard
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "포스터" && (
        <Poster
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "리플릿" && (
        <Leaflet
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "낱장스티커" && (
        <Sticker_single
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "원형스티커" && (
        <Sticker_circle
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "DIY스티커" && (
        <Sticker_DIY
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "단행본" && (
        <Booklet
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "브로슈어" && (
        <Brochure
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "스프링노트" && (
        <SpringNoteBook
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "제안서" && (
        <Proposal
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "노트" && (
        <NoteBook
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "박스" && (
        <PackageBox
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "합지박스" && (
        <PackageCorBox
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "쇼핑백" && (
        <PackageShopBag
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "현수막" && (
        <Banner
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "X배너" && (
        <Xbanner
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "대봉투" && (
        <BigEnvelope
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "소봉투" && (
        <SmallEnvelope
          prod={prod}
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
    </Box>
  );
};

export default ProdOptions;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import New_EditorKonva from "./New_EditorKonva";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";

const New_EditorWorkSpace = ({
  editorRef,
  selectedNode,
  setSelectedNode,
  pages,
  addPage,
  removePage,
  currentPage,
  setCurrentPage,
}) => {
  useEffect(() => {
    initdb();
  }, []);

  const [loading, setLoading] = useState(true);
  const [loadedData, setLoadedData] = useState([]);

  const { item_sid } = useParams();

  const initdb = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/custom_prod",
      {
        params: {
          item_sid: item_sid,
        },
      }
    );

    if (res.status === 200) {
      if (res.data.ITEM_DESIGN.length === 0) {
        addPage(1);
      }

      await res.data.ITEM_DESIGN.forEach((data, index) => {
        addPage(index + 1);
      });
      setLoadedData(res.data.ITEM_DESIGN);
      setLoading(false);
    } else {
      alert("불러오기 오류");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    } else if (loading === false) {
      loadedData.forEach((data, index) => {
        console.log("데이타임", data);
        console.log("로딩이 다 됐다", editorRef);
        editorRef.current[index + 1].current.functions.saveDataLoad(data);
      });
    }
  }, [loading]);

  useEffect(() => {
    const preventSpaceScroll = (e) => {
      if (
        (e.key === " " || e.key === "Spacebar" || e.keyCode === 32) &&
        e.target.tagName !== "INPUT" &&
        e.target.tagName !== "TEXTAREA"
      ) {
        e.preventDefault();
      }
    };

    const preventDefaultScroll = (e) => {
      if (
        (e.key === " " || e.key === "Spacebar" || e.keyCode === 32) &&
        e.target.tagName !== "INPUT" &&
        e.target.tagName !== "TEXTAREA"
      ) {
        e.preventDefault();
      }
    };

    // 스크롤 방지를 위한 이벤트 리스너 추가
    window.addEventListener("keydown", preventSpaceScroll);
    window.addEventListener("keypress", preventDefaultScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("keydown", preventSpaceScroll);
      window.removeEventListener("keypress", preventDefaultScroll);
    };
  }, []);
  return (
    <WorkSpaceContainer>
      <WorkSpaceInner>
        <CanvasPage>
          <Box
            sx={{
              position: "fixed",
              width: "1500px",
              zIndex: "1000",
              bottom: "20px",
              margin: "10px auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                addPage(null);
              }}
              variant="contained"
              sx={{ mr: 0.5 }}
            >
              레이어 추가
            </Button>
            <Button
              variant="contained"
              sx={{ zIndex: "1000" }}
              onClick={() => {
                const confirm = window.confirm("정말로 삭제하시겠습니까?");
                if (confirm) {
                  removePage(currentPage);
                }
              }}
            >
              레이어 삭제
            </Button>
          </Box>
          <React.Fragment>
            {pages.map((page) => (
              <div
                onClick={() => {
                  setCurrentPage(page.id);
                }}
              >
                <New_EditorKonva
                  key={page.id}
                  editorRef={editorRef.current[page.id]}
                  selectedNode={selectedNode}
                  setSelectedNode={setSelectedNode}
                  data-page-id={page.id}
                  isSelected={page.id === currentPage}
                />
              </div>
            ))}
          </React.Fragment>
        </CanvasPage>
      </WorkSpaceInner>
    </WorkSpaceContainer>
  );
};

export default New_EditorWorkSpace;

const WorkSpaceContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  outline: none;
  flex: 1 1 0%;
  background-color: rgba(232, 232, 232, 0.9);
  overflow: hidden;
`;

const WorkSpaceInner = styled.div`
  width: calc(100% - 25px);
  height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px !important  ;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  scrollbar-width: auto;
  scrollbar-color: #888 #f1f1f1;
`;

const CanvasPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Checkbox, IconButton, Typography } from "@mui/material";
import { FaHamburger, FaUser } from "react-icons/fa";
import { GridMenuIcon } from "@mui/x-data-grid";
import {
  ArrowLeft,
  ForkLeft,
  JoinLeft,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Login,
  LoginTwoTone,
  Person,
  RampLeft,
  Sell,
  SwipeLeft,
} from "@mui/icons-material";
import { BsChatLeft } from "react-icons/bs";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

const MobileHeader = ({ openPopup, Cate, userNm, cookies, queryClient }) => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [isAdmin, setIsAdmin] = React.useState(false);
  const { data } = useQuery("userinfo", { enabled: false });

  React.useEffect(() => {
    const grade = data?.USER_GRADE;

    if (grade === 9) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [data]);

  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toggleDrawer2 = (newOpen) => () => {
    setOpen2(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem
          sx={{
            mb: 2,
          }}
        >
          <KeyboardArrowLeft />
        </ListItem>

        {Cate &&
          Cate.map((el, index) => (
            <Link to={`/products/${el.CATE_SID}`} key={index}>
              <ListItem
                disablePadding
                secondaryAction={<KeyboardArrowRight edge="end" />}
              >
                <ListItemButton>
                  <ListItemText primary={<MenuText>{el.CATE_NM}</MenuText>} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
      </List>
    </Box>
  );
  const DrawerList2 = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer2(false)}>
      <List>
        <ListItem
          sx={{
            mb: 2,
          }}
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          {"　"}
        </ListItem>

        {/* <ListItem
          disablePadding
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          <ListItemButton
            onClick={() => {
              navigate("/mypage");
            }}
          >
            <ListItemText primary={<MenuText>마이페이지</MenuText>} />
          </ListItemButton>
        </ListItem> */}
        <ListItem
          disablePadding
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          <ListItemButton
            onClick={() => {
              navigate("/cart");
            }}
          >
            <ListItemText primary={<MenuText>장바구니</MenuText>} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          <ListItemButton
            onClick={() => {
              navigate("/orderlist");
            }}
          >
            <ListItemText primary={<MenuText>주문내역</MenuText>} />
          </ListItemButton>
        </ListItem>

        {isAdmin && (
          <ListItem
            disablePadding
            secondaryAction={<KeyboardArrowRight edge="end" />}
          >
            <ListItemButton
              onClick={() => {
                navigate("/admin");
              }}
            >
              <ListItemText primary={<MenuText>어드민</MenuText>} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem
          disablePadding
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          <ListItemButton
            onClick={() => {
              cookies.remove("token");
              cookies.remove("refreshToken");
              queryClient.setQueryData("userinfo");
            }}
          >
            <ListItemText primary={<MenuText>로그아웃</MenuText>} />
          </ListItemButton>
        </ListItem>
        {/* <ListItem
          disablePadding
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          <ListItemButton
            onClick={() => {
              navigate("/cs");
            }}
          >
            <ListItemText primary={<MenuText>문의사항</MenuText>} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          secondaryAction={<KeyboardArrowRight edge="end" />}
        >
          <ListItemButton
            onClick={() => {
              navigate("/notice");
            }}
          >
            <ListItemText primary={<MenuText>공지사항</MenuText>} />
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        <IconButton onClick={toggleDrawer(true)}>
          <GridMenuIcon
            sx={{
              height: "32px",
              width: "42px",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate("/");
          }}
        >
          <Box
            sx={{
              width: "140px",
              height: "50px",
              backgroundImage: "url(/asserts/logo/logo_mob.png)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </IconButton>
        {userNm ? (
          <IconButton
            onClick={() => {
              // alert("d");
              // cookies.remove("token");
              // cookies.remove("refreshToken");
              // queryClient.setQueryData("userinfo");
              setOpen2(true);
            }}
          >
            <Person
              sx={{
                height: "32px",
                width: "42px",
              }}
            />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              openPopup(0);
            }}
          >
            <Login
              sx={{
                height: "32px",
                width: "42px",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Drawer open={open2} onClose={toggleDrawer2(false)} anchor="right">
        {DrawerList2}
      </Drawer>
    </>
  );
};

const MenuText = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  height: 24px;
`;

export default MobileHeader;

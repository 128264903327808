export const SenecaData = {
  스노우: {
    100: 0.09,
    120: 0.11,
    150: 0.14,
    180: 0.17,
    200: 0.2,
    250: 0.25,
    300: 0.31,
  },
  아트지: {
    100: 0.08,
    120: 0.09,
    150: 0.13,
    180: 0.15,
    200: 0.17,
    250: 0.22,
    300: 0.28,
  },
  랑데뷰: {
    105: 0.14,
    130: 0.17,
    160: 0.22,
    190: 0.26,
    210: 0.28,
    240: 0.33,
  },
  모조지: {
    80: 0.09,
    100: 0.11,
    120: 0.14,
    150: 0.16,
    180: 0.19,
    220: 0.24,
    260: 0.29,
  },
  뉴플러스: {
    80: 0.08,
    100: 0.1,
    120: 0.12,
  },
  반누보: {
    122: 0.15,
    227: 0.29,
  },
  아르떼: {
    105: 0.16,
    130: 0.19,
    160: 0.23,
    190: 0.27,
    210: 0.29,
    230: 0.31,
  },
  매쉬멜로우: {
    105: 0.12,
    128: 0.15,
    157: 0.18,
    186: 0.21,
    209: 0.24,
    233: 0.26,
    262: 0.29,
  },
  몽블랑: {
    90: 0.12,
    100: 0.14,
    105: 0.14,
    130: 0.18,
    160: 0.23,
    190: 0.27,
    210: 0.29,
    240: 0.32,
  },
  미스틱: {
    157: 0.19,
  },
  그린라이트: {
    100: 0.15,
  },
};

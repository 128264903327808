import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import New_EditorToolbar from "./New_EditorToolbar";
import New_EditorWorkSpace from "./New_EditorWorkSpace";
import { Box, CircularProgress } from "@mui/material";
import New_EditorSidePanel from "./SideBar/New_EditorSidePanel";
import { ProgressProvider, useProgress } from "./Editor_ProgressContext";

const New_EditorPageContent = () => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const editorRef = useRef({});

  const addPage = useCallback(
    (id) => {
      let newId = null;
      if (id) {
        newId = id;
        console.log("아이디있음", id);
      } else {
        console.log("아이디없음");
        newId = pages.length + 1 + Date.now();
      }
      setPages((prevPages) => [...prevPages, { id: newId }]);
      editorRef.current[newId] = React.createRef();
    },

    [pages]
  );

  useEffect(() => {
    console.log("페이지스", pages);
  }, [pages]);

  const removePage = useCallback((id) => {
    setPages((prevPages) => prevPages.filter((page) => page.id !== id));
    delete editorRef.current[id];
  }, []);

  const [selectedNode, setSelectedNode] = useState(null);
  const { progress } = useProgress();

  return (
    <EditorContainer>
      <SidePanelWrap>
        <New_EditorSidePanel
          editorRef={editorRef}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          pages={pages}
          currentPage={currentPage}
        />
      </SidePanelWrap>
      <WorkSpaceWrap>
        <New_EditorToolbar
          editorRef={editorRef}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          pages={pages}
          currentPage={currentPage}
        />
        <New_EditorWorkSpace
          editorRef={editorRef}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          pages={pages}
          addPage={addPage}
          removePage={removePage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </WorkSpaceWrap>

      {progress && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            textAlign: "center",
            lineHeight: "800px",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </EditorContainer>
  );
};

const New_EditorPage = () => (
  <ProgressProvider>
    <New_EditorPageContent />
  </ProgressProvider>
);

export default New_EditorPage;

const EditorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: rgba(232, 232, 232, 0.9);
`;

const SidePanelWrap = styled.div`
  height: 100%;
  width: auto;
  max-height: 100vh;
`;

const WorkSpaceWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

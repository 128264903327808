import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomQuill from "../components/global/CustomQuill";
import { useQuery } from "react-query";
import axios from "axios";
import { formatDateAndTime_KR } from "../hooks/Utill";
import { useNavigate, useParams } from "react-router-dom";

const SelfEstimateDetailPage = () => {
  const { estimate_sid } = useParams();
  const { data } = useQuery("userinfo", { enabled: false });
  const [snackbar, setSnackbar] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [estimateList, setEstimateList] = useState([]);
  const [estimateDetail, setEstimateDetail] = useState(null);
  const navigate = useNavigate();

  const initDB = async () => {
    if (!data) return;

    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/selfestimate",
      {
        params: {
          ESTIMATE_USER_ID: data.USER_ID,
        },
      }
    );
    setEstimateList(res.data);

    const detail = res.data.find((item) => item.ESTIMATE_SID === estimate_sid);
    setEstimateDetail(detail);
  };

  useEffect(() => {
    initDB();
  }, [data]);

  return (
    <>
      <SelfWrapper>
        <SelfHeader>
          <h1>견적 문의</h1>
        </SelfHeader>
        <SelfContent>
          <Box
            sx={{ width: "700px", border: "1px solid #ccc", padding: "16px" }}
          >
            <Typography variant="h6">
              {estimateDetail?.ESTIMATE_TITLE}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", marginTop: "16px" }}>
            <Box
              sx={{ width: "340px", border: "1px solid #ccc", padding: "16px" }}
            >
              <Typography variant="h6">
                {estimateDetail?.ESTIMATE_MANAGER}
              </Typography>
            </Box>
            <Box
              sx={{ width: "340px", border: "1px solid #ccc", padding: "16px" }}
            >
              <Typography variant="h6">
                {estimateDetail?.ESTIMATE_TEL}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              border: "1px solid #ccc",
              padding: "16px",
              width: "700px",
            }}
          >
            <ContentBox>
              <div
                dangerouslySetInnerHTML={{
                  __html: estimateDetail?.ESTIMATE_CONTENT,
                }}
              />
            </ContentBox>
          </Box>

          <Box
            sx={{
              marginTop: "16px",
              border: "1px solid #ccc",
              padding: "16px",
              width: "700px",
              textAlign: "center",
            }}
          >
            {estimateDetail?.ESTIMATE_FILEURL !== "" ? (
              <Typography
                variant="h6"
                sx={{
                  cursor: "pointer",
                  color: "blue",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  window.open(estimateDetail?.ESTIMATE_FILEURL, "_blank");
                }}
              >
                {estimateDetail?.ESTIMATE_FILENAME}
              </Typography>
            ) : (
              <Typography variant="h6">첨부파일이 없습니다.</Typography>
            )}
          </Box>

          <Box sx={{ marginTop: "16px" }}>
            <Button
              variant="outlined"
              onClick={() => navigate("/selfestimate")}
            >
              목록
            </Button>
          </Box>
        </SelfContent>
      </SelfWrapper>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setSnackbar(null);
          }}
          autoHideDuration={3000}
        >
          <Alert
            {...snackbar}
            onClose={() => {
              setSnackbar(null);
            }}
          />
        </Snackbar>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>문의 등록</DialogTitle>
        <DialogContent>
          <DialogContentText>
            문의가 등록되었습니다.
            <br />
            빠른 시일 내에 답변 드리겠습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenDialog(false)}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelfEstimateDetailPage;

const SelfWrapper = styled.div`
  width: 1140px;
  margin: 0 auto;
  padding-bottom: 100px;
  height: 100%;
  background-color: #fff;
`;

const SelfHeader = styled.div`
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 24px;
    font-weight: 600;
  }
`;

const SelfContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentBox = styled.div`
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.25em;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 1.125em;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1em;
    margin-bottom: 8px;
  }

  h6 {
    font-size: 0.875em;
    margin-bottom: 6px;
  }

  p {
    font-size: 1em;
    line-height: normal;
  }
`;

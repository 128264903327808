import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Poster = ({ prod, SelectOptions, setSelectOptions }) => {
  const [PaperSize, setPaperSize] = useState("297x420");
  const [PaperWidth, setPaperWidth] = useState(420);
  const [PaperHeight, setPaperHeight] = useState(420);
  const [DefaultPaper, setDefaultPaper] = useState("스노우");
  const [DefaultGram, setDefaultGram] = useState("250g");
  const [PrintMethod, setPrintMethod] = useState("단면");
  const [Quantity, setQuantity] = useState(10);
  const [DefaultCount, setDefaultCount] = useState(1);

  const [progress, setProgress] = useState(false);
  //기본세팅
  useEffect(() => {
    setProgress(true);
    setTimeout(() => {
      setDefaultPaper("스노우");
      setDefaultGram("250g");
      setProgress(false);
    }, 666);
  }, []);

  /////////////////////////////////
  const [papers, setPapers] = useState({});

  const getPriceData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/global/prices",
      {
        params: {
          PRICE_PROD_CATE: prod,
          PRICE_OPTION_CATE: "용지",
        },
      }
    );

    const papers_copy = {};

    res.data.forEach((item) => {
      const name = item.PRICE_OPTION_NM.match(/[가-힣a-zA-Z]+/)[0]; // 용지 이름 추출
      const weight = parseInt(item.PRICE_OPTION_NM.match(/\d+/)[0], 10); // 그람수 추출
      console.log(name);
      console.log(weight);

      if (!papers_copy[name]) {
        papers_copy[name] = [];
      }

      if (!papers_copy[name].includes(weight)) {
        papers_copy[name].push(weight);
      }
    });

    setPapers(papers_copy);
    setDefaultPaper(Object.keys(papers_copy)[0]);
  };

  const menuItems_papers = Object.keys(papers).map((key) => (
    <MenuItem
      sx={{ fontSize: "14px" }}
      value={key}
      key={key}
      className="paperItem"
    >
      {key}
    </MenuItem>
  ));

  useEffect(() => {
    getPriceData();
  }, []);

  /////////////////////////////////

  useEffect(() => {
    if (PaperSize === "직접입력") {
      return;
    }
    setPaperWidth(PaperSize.split("x")[0]);
    setPaperHeight(PaperSize.split("x")[1]);
  }, [PaperSize]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.포스터.규격 = PaperSize;
    copyOptions.포스터.가로 = parseInt(PaperWidth);
    copyOptions.포스터.세로 = parseInt(PaperHeight);
    copyOptions.포스터.용지 = DefaultPaper + DefaultGram;
    copyOptions.포스터.인쇄 = PrintMethod;
    copyOptions.포스터.수량 = parseInt(Quantity);
    copyOptions.포스터.건 = parseInt(DefaultCount);
    setSelectOptions(copyOptions);
  }, [
    PaperSize,
    PaperWidth,
    PaperHeight,
    DefaultPaper,
    DefaultGram,
    Quantity,
    PrintMethod,
    DefaultCount,
  ]);

  return (
    <Box>
      {progress && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "500vh",
            textAlign: "center",
            lineHeight: "800px",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{}}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          규격
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{ width: "98%", height: "40px", fontSize: "14px" }}
            fullWidth
            value={PaperSize}
            onChange={(e) => {
              setPaperSize(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={"297x420"}>
              A3 (297mm x 420mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"297x210"}>
              A4 (297mm x 210mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"364x505"}>
              B3 (364mm x 505mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"257x364"}>
              B4 (257mm x 364mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"465x636"}>
              국반절 (465mm x 636mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"직접입력"}>
              직접입력
            </MenuItem>
          </Select>
        </Box>
        {PaperSize === "직접입력" && (
          <Box
            sx={{
              marginTop: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              sx={{ width: "48%" }}
              label="가로"
              value={PaperWidth}
              onChange={(e) => {
                setPaperWidth(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value < 50) {
                  setPaperWidth(50);
                  return;
                }
                if (e.target.value > 740) {
                  setPaperWidth(740);
                  return;
                }
              }}
            />{" "}
            x{" "}
            <TextField
              size="small"
              sx={{ width: "48%" }}
              label="세로"
              value={PaperHeight}
              onChange={(e) => {
                setPaperHeight(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value < 50) {
                  setPaperHeight(50);
                  return;
                }
                if (e.target.value > 740) {
                  setPaperHeight(740);
                  return;
                }
              }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          용지
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultPaper}
            onChange={(e) => {
              setDefaultPaper(e.target.value);
              setDefaultGram(papers[e.target.value][0] + "g");
            }}
          >
            {menuItems_papers}
          </Select>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultGram}
            onChange={(e) => {
              setDefaultGram(e.target.value);
            }}
          >
            {papers[DefaultPaper]?.map((gram) => (
              <MenuItem sx={{ fontSize: "14px" }} value={gram + "g"}>
                {gram + "g"}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          인쇄방법
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={PrintMethod}
            onChange={(e) => {
              setPrintMethod(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={"양면"}>
              양면
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"단면"}>
              단면
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          수량
        </InputLabel>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            sx={{ width: "48%" }}
            size="small"
            value={Quantity}
            onChange={(e) => {
              let num = e.target.value.replace(/[^0-9]/g, ""); // 숫자만 남기기
              if (parseInt(num) < 0) {
                num = "1"; // 빈 값이거나 0 이하일 경우 1로 설정
              }
              setQuantity(num);
            }}
            type="number"
          />
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultCount}
            onChange={(e) => {
              setDefaultCount(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              1건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={2}>
              2건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              3건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={4}>
              4건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={5}>
              5건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={6}>
              6건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={7}>
              7건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={8}>
              8건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={9}>
              9건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={10}>
              10건
            </MenuItem>
          </Select>
        </Box>
      </Box>

      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "12px",
            marginBottom: "12px",
          }}
        >
          후가공
        </InputLabel>

        <FormControlLabel
          value="코팅"
          control={<Checkbox size="small" />}
          label="코팅"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.포스터[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
        <FormControlLabel
          value="귀도리"
          control={<Checkbox size="small" />}
          label="귀도리"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.포스터[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
        <FormControlLabel
          value="오시"
          control={<Checkbox size="small" />}
          label="오시"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.포스터[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
        <FormControlLabel
          value="미싱"
          control={<Checkbox size="small" />}
          label="미싱"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.포스터[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
        <FormControlLabel
          value="타공"
          control={<Checkbox size="small" />}
          label="타공"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.포스터[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
      </Box>
    </Box>
  );
};

export default Poster;

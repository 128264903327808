import React, { createContext, useState, useContext } from "react";

const Editor_ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(false);

  return (
    <Editor_ProgressContext.Provider value={{ progress, setProgress }}>
      {children}
    </Editor_ProgressContext.Provider>
  );
};

export const useProgress = () => useContext(Editor_ProgressContext);

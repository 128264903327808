import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

export const NaverAuthJoin = () => {
  const [dialogError, setDialogError] = useState(false);
  const [dialogOpenJoin, setDialogOpenJoin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  //회원가입
  const code = window.location.search.split("=")[1].split("&")[0];
  const state = window.location.search.split("=")[2];

  const init = async () => {
    const response = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/naver/auth",
      {
        params: {
          code: code,
          state: state,
          type: "join",
        },
      }
    );

    if (response.status === 201) {
      setErrorMsg("이미 가입된 회원입니다.");
      setDialogError(true);
    } else if (response.status === 202) {
      setErrorMsg("회원 가입 오류");
      setDialogError(true);
    } else if (response.status === 203) {
      setErrorMsg("네이버 인증 오류");
      setDialogError(true);
    } else if (response.status === 200) {
      setDialogOpenJoin(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          textAlign: "center",
          lineHeight: "800px",
        }}
      >
        <CircularProgress />
      </Box>
      <Dialog
        open={dialogOpenJoin}
        onClose={() => {
          window.location.href = "/";
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            축하합니다! 스노우플래닛 회원가입이 완료 되었습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = "/";
            }}
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogError}
        onClose={() => {
          window.location.href = "/";
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = "/";
            }}
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const NaverAuthLogin = ({ queryClient }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [dialogError, setDialogError] = useState(false);
  const [dialogOpenLogin, setDialogOpenLogin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  //회원가입
  const code = window.location.search.split("=")[1].split("&")[0];
  const state = window.location.search.split("=")[2];

  const init = async () => {
    const response = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/naver/auth",
      {
        params: {
          code: code,
          state: state,
          type: "login",
        },
      }
    );

    if (response.status === 200) {
      console.log("response", response);
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);

      cookies.remove("token");
      cookies.remove("refreshToken");
      cookies.set("token", response.data.accessToken, { path: "/" });
      cookies.set("refreshToken", response.data.refreshToken, { path: "/" });

      queryClient.invalidateQueries("userinfo");
      queryClient.setQueryData("userinfo");
    }

    if (response.status === 201) {
      setErrorMsg(
        "회원가입이 되지 않은 아이디입니다. 회원가입을 진행해주세요."
      );
      setDialogError(true);
    } else if (response.status === 202) {
      setErrorMsg("로그인 오류");
      setDialogError(true);
    } else if (response.status === 203) {
      setErrorMsg("네이버 인증 오류");
      setDialogError(true);
    } else if (response.status === 200) {
      setDialogOpenLogin(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          textAlign: "center",
          lineHeight: "800px",
        }}
      >
        <CircularProgress />
      </Box>
      <Dialog
        open={dialogOpenLogin}
        onClose={() => {
          window.location.href = "/";
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            로그인에 성공하였습니다!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/");
            }}
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogError}
        onClose={() => {
          window.location.href = "/";
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = "/";
            }}
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import * as S from "../styles/new_styles";
import { useEffect, useRef, useState } from "react";
import ReviewBoard from "../components/products/ReviewBoard";
import { useParams } from "react-router-dom";
import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  MissingPrice1,
  MissingPrice3,
  OsiNameCardPrice,
  PunchingPrice,
  RoundingPrice,
} from "./price";
import ProdOptions from "../components/products/Options/ProdOptions";
import OptionDetail from "../components/products/Options/OptionDetail";
import { FileCopy, UploadFile } from "@mui/icons-material";
import ProdPrice from "../components/products/ProdPrice";
import { PriceCalc } from "../components/products/Options/PriceCalc";
import { useMediaQuery } from "react-responsive";
import { SiKakao, SiKakaotalk } from "react-icons/si";

const ProductDetailPage = ({ openPopup, queryClient }) => {
  const [qty, setQty] = useState();
  const [scrollPositon, setScrollPosition] = useState(0);
  const [SliderIndex, SetSliderIndex] = useState(0);
  const DtailBox = useRef(null);
  const DropDown = useRef(null);
  const { prod_sid } = useParams();
  const [prodDetail, setProdDetail] = useState();
  const [prodImages, setProdImages] = useState([]);
  const [prodOptions, setProdOptions] = useState([]);
  const [prodPrice, setProdPrice] = useState(0);
  const [seletedOptions, setSeletedOptions] = useState([]);
  const { data } = useQuery("userinfo", { enabled: false });
  const USER_ID = data?.USER_ID;
  const [reviewData, setReviewData] = useState([]);
  const [designCheck, setDesignCheck] = useState(false);
  const [designFile, setDesignFile] = useState();
  const [designFileName, setDesignFileName] = useState();
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  // 메뉴 아이템 호버 이벤트 상태 관리
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredX, setHoveredX] = useState(0);
  const [hoveredY, setHoveredY] = useState(0);

  // 호버 이벤트 핸들러
  const handleMenuItemHover = (item) => {
    setHoveredItem(item);
    const itemRect = item.getBoundingClientRect();
    const mouseX = itemRect.left + window.scrollX;
    const mouseY = itemRect.top + window.scrollY;
    setHoveredX(mouseX);
    setHoveredY(mouseY);
  };

  // 호버 종료 핸들러
  const handleMenuItemLeave = () => {
    setHoveredItem(null);
  };

  // 전역 이벤트 리스너 등록
  useEffect(() => {
    const handleGlobalHover = (e) => {
      const menuItems = document.querySelectorAll(".paperItem");

      menuItems.forEach((item) => {
        if (item.contains(e.target)) {
          handleMenuItemHover(item);
        }
      });
    };

    const handleGlobalLeave = (e) => {
      const menuItems = document.querySelectorAll(".paperItem");
      menuItems.forEach((item) => {
        if (!item.contains(e.target)) {
          handleMenuItemLeave();
        }
      });
    };

    document.addEventListener("mouseover", handleGlobalHover);
    document.addEventListener("mouseout", handleGlobalLeave);
    document.addEventListener("mousedown", handleGlobalLeave);
    return () => {
      document.removeEventListener("mouseover", handleGlobalHover);
      document.removeEventListener("mouseout", handleGlobalLeave);
      document.removeEventListener("mousedown", handleGlobalLeave);
    };
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/product/detail", {
        prod_sid: prod_sid,
      })
      .then((res) => {
        console.log(res);
        setProdDetail(res.data);
        setProdPrice(Math.round(res.data.PROD_PRICE));
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/product/images", {
        prod_sid: prod_sid,
      })
      .then((res) => {
        console.log(res);
        setProdImages(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/product/options", {
        prod_sid: prod_sid,
      })
      .then((res) => {
        const groupedData = {};
        res.data.forEach((option) => {
          const category = option.OPTION_CATE;
          if (!groupedData[category]) {
            groupedData[category] = {
              OPTION_CATE: category,
              OPTION: [],
            };
          }
          groupedData[category].OPTION.push(option);
        });

        const result = Object.values(groupedData);

        console.log(result);
        setProdOptions(result);
      })
      .catch((error) => {
        console.log(error);
      });

    initdb();
  }, [prod_sid]);

  const initdb = async () => {
    setReviewData(
      (
        await axios.post(process.env.REACT_APP_DB_HOST + "/api/review", {
          prod_sid: prod_sid,
        })
      ).data
    );

    const paperData = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/paper", {
        params: {
          PROD_SID: prod_sid,
        },
      })
    ).data;

    const optionPriceData = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/option_price", {
        params: {
          PROD_SID: prod_sid,
        },
      })
    ).data;

    setPaper(paperData);
    console.log(paperData);

    setSelectedPaper(paperData[0]?.PAPER_NM + paperData[0]?.PAPER_WEIGHT);

    setPaperQty(paperData[0]?.PAPER_QTY);

    setPaperAmt(paperData[0]?.PAPER_AMT);

    setQty(paperData[0]?.PAPER_QTY.split(",")[0]);

    setDefaultAmt(paperData[0]?.PAPER_AMT.split(",")[0]);

    setOptionPriceTable(optionPriceData);
  };

  useEffect(() => {
    // 초기 옵션 값 설정 (첫번째로)
    const seletedset = [];
    prodOptions.forEach((e, index) => {
      seletedset[index] = {
        OPTION_CATE: e.OPTION_CATE,
        OPTION_SID: e.OPTION[0].OPTION_SID,
        OPTION_PRICE: e.OPTION[0].OPTION_PRICE,
        OPTION_NM: e.OPTION[0].OPTION_NM,
        OPTION_DETAIL: e.OPTION[0].OPTION_DETAIL,
      };
    });
    setSeletedOptions(seletedset);
  }, [prodOptions]);

  useEffect(() => {
    calcPrice();
  }, [seletedOptions, qty]);

  const calcPrice = () => {
    // 옵션별로 가격 세팅
    console.log(seletedOptions);
    const copy_seletedOptions = seletedOptions;
    let total_price = 0;
    copy_seletedOptions.map((el) => {
      total_price += parseFloat(el.OPTION_PRICE);
    });
    console.log(total_price);
    console.log(prodDetail?.PROD_PRICE);
    setProdPrice(
      (parseFloat(prodDetail?.PROD_PRICE) + total_price) *
        (qty / prodDetail?.PROD_UNIT)
    );
  };

  const handleDropdown = (value) => {
    console.log(prodDetail?.PROD_QUANTITY?.split(","));
    setQty(value);
    DropDown.current.checked = false;
  };

  const updateScroll = () => {
    const MaxHeight = DtailBox?.current?.offsetHeight;
    if (window.scrollY < MaxHeight - 730) {
      setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      updateScroll();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSlideClick = (index) => {
    SetSliderIndex(index);
  };

  const handlePrevClick = () => {
    if (SliderIndex != 0) {
      SetSliderIndex(SliderIndex - 1);
    } else {
      SetSliderIndex(prodImages.length - 1);
    }
  };

  const handleNextClick = () => {
    if (SliderIndex < prodImages.length - 1) {
      SetSliderIndex(SliderIndex + 1);
    } else {
      SetSliderIndex(0);
    }
  };

  // 직접 디자인하기
  const handleSendDesign = async () => {
    if (USER_ID === undefined) {
      openPopup(0);
      return false;
    }

    if (designCheck) {
      setSnackbar({
        children: "업로드한 디자인 파일로 주문을 진행해주세요.",
        severity: "info",
      });
      return false;
    }

    const item_qty = [
      "단행본",
      "브로슈어",
      "스프링노트",
      "제안서",
      "노트",
    ].includes(prodDetail?.PROD_NM)
      ? SelectOptions["책자"]?.수량
      : SelectOptions[prodDetail.PROD_NM]?.수량;

    const PROD_NM = [
      "단행본",
      "브로슈어",
      "스프링노트",
      "제안서",
      "노트",
    ].includes(prodDetail?.PROD_NM)
      ? "책자"
      : prodDetail.PROD_NM;

    const PROD_SID = prodDetail.PROD_SID;
    const ITEM_OPTION = createOptionNm().join(" | ");
    const ITEM_QUANTITY = item_qty;
    const ITEM_AMOUNT = printPrice + optionPrice + taxPrice;
    const ITEM_DESIGN = JSON.stringify([]);
    const ITEM_WIDTH = SelectOptions[PROD_NM]?.가로;
    const ITEM_HEIGHT = SelectOptions[PROD_NM]?.세로;
    const ITEM_COUNT = SelectOptions[PROD_NM]?.건;

    const res = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/cart/add",
      {
        PROD_SID: PROD_SID,
        ITEM_OPTION: ITEM_OPTION,
        ITEM_QUANTITY: ITEM_QUANTITY,
        ITEM_COUNT: ITEM_COUNT,
        ITEM_AMOUNT: ITEM_AMOUNT,
        ITEM_DESIGN: ITEM_DESIGN,
        USER_ID: USER_ID,
        ITEM_FILE_LOCATION: designFile,
        ITEM_FILE_NAME: "",
        ITEM_WIDTH: ITEM_WIDTH,
        ITEM_HEIGHT: ITEM_HEIGHT,
      }
    );

    if (res.status === 200) {
      navigate(`/editor2/${res.data}`);
    }
  };

  // 장바구니에 담기
  const handleSendCart = async () => {
    if (window.wcs) {
      const _conv = {
        type: `lead`,
      };
      window.wcs.trans(_conv);
    }
    setProgress(true);
    if (USER_ID === undefined) {
      openPopup(0);
      return false;
    }
    if (!designCheck) {
      setSnackbar({
        children: "디자인을 확인해주세요.",
        severity: "error",
      });
      setProgress(false);
      return false;
    }

    const item_qty = [
      "단행본",
      "브로슈어",
      "스프링노트",
      "제안서",
      "노트",
    ].includes(prodDetail?.PROD_NM)
      ? SelectOptions["책자"]?.수량
      : SelectOptions[prodDetail.PROD_NM]?.수량;

    const PROD_NM = [
      "단행본",
      "브로슈어",
      "스프링노트",
      "제안서",
      "노트",
    ].includes(prodDetail?.PROD_NM)
      ? "책자"
      : prodDetail.PROD_NM;

    const PROD_SID = prodDetail.PROD_SID;
    const ITEM_OPTION = createOptionNm().join(" | ");
    const ITEM_QUANTITY = item_qty;
    const ITEM_AMOUNT = printPrice + optionPrice + taxPrice;
    const ITEM_DESIGN = JSON.stringify([]);
    const ITEM_WIDTH = SelectOptions[PROD_NM]?.가로;
    const ITEM_HEIGHT = SelectOptions[PROD_NM]?.세로;
    const ITEM_COUNT = SelectOptions[PROD_NM]?.건;

    const res = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/cart/add",
      {
        PROD_SID: PROD_SID,
        ITEM_OPTION: ITEM_OPTION,
        ITEM_QUANTITY: ITEM_QUANTITY,
        ITEM_AMOUNT: ITEM_AMOUNT,
        ITEM_COUNT: ITEM_COUNT,
        ITEM_DESIGN: ITEM_DESIGN,
        USER_ID: USER_ID,
        ITEM_FILE_LOCATION: designFile,
        ITEM_FILE_NAME: designFileName,
        ITEM_WIDTH: ITEM_WIDTH,
        ITEM_HEIGHT: ITEM_HEIGHT,
      }
    );

    if (res.status === 200) {
      setTimeout(() => {
        queryClient.invalidateQueries("userinfo");
        setSnackbar({
          children: "장바구니에 추가되었습니다.",
          severity: "success",
        });
        setProgress(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setProgress(false);
      }, 1000);
    }
  };

  // 주문하기
  const handleSendOrder = async () => {
    if (window.wcs) {
      const _conv = {
        type: `lead`,
      };
      window.wcs.trans(_conv);
    }
    if (USER_ID === undefined) {
      openPopup(0);
      return false;
    }
    if (!designCheck) {
      setSnackbar({
        children: "디자인을 확인해주세요.",
        severity: "error",
      });
      return false;
    }

    const item_qty = [
      "단행본",
      "브로슈어",
      "스프링노트",
      "제안서",
      "노트",
    ].includes(prodDetail?.PROD_NM)
      ? SelectOptions["책자"]?.수량
      : SelectOptions[prodDetail.PROD_NM]?.수량;

    const PROD_NM = [
      "단행본",
      "브로슈어",
      "스프링노트",
      "제안서",
      "노트",
    ].includes(prodDetail?.PROD_NM)
      ? "책자"
      : prodDetail.PROD_NM;

    const PROD_SID = prodDetail.PROD_SID;
    const ITEM_OPTION = createOptionNm().join(" | ");
    const ITEM_QUANTITY = item_qty;
    const ITEM_AMOUNT = printPrice + optionPrice + taxPrice;
    const ITEM_DESIGN = JSON.stringify([]);
    const ITEM_WIDTH = SelectOptions[PROD_NM]?.가로;
    const ITEM_HEIGHT = SelectOptions[PROD_NM]?.세로;
    const ITEM_COUNT = SelectOptions[PROD_NM]?.건;

    const res = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/cart/add",
      {
        PROD_SID: PROD_SID,
        ITEM_OPTION: ITEM_OPTION,
        ITEM_QUANTITY: ITEM_QUANTITY,
        ITEM_COUNT: ITEM_COUNT,
        ITEM_AMOUNT: ITEM_AMOUNT,
        ITEM_DESIGN: ITEM_DESIGN,
        USER_ID: USER_ID,
        ITEM_FILE_LOCATION: designFile,
        ITEM_FILE_NAME: designFileName,
        ITEM_WIDTH: ITEM_WIDTH,
        ITEM_HEIGHT: ITEM_HEIGHT,
      }
    );

    if (res.status === 200) {
      queryClient.invalidateQueries("userinfo");
      navigate(`/order/${res.data}`);
    }
  };

  const paperRef = useRef([]);
  const [paper, setPaper] = useState([]);
  const [paperQty, setPaperQty] = useState();
  const [paperAmt, setPaperAmt] = useState();
  const [defaultAmt, setDefaultAmt] = useState(0);
  const [optionAmt, setOptionAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [selectedPaper, setSelectedPaper] = useState();

  const handleChange = (event, newSelectedPaper) => {
    setSelectedPaper(newSelectedPaper);
  };

  //옵션 종합

  const [selOption, setSelOption] = useState({});
  const [optionPriceTable, setOptionPriceTable] = useState([]);

  // 옵션 가격 계산 함수
  const calculateOptionPrice = (qty, option) => {
    if (!option) {
      console.log(option);
      alert(`가격 테이블이 설정되지 않았습니다.`);
      return false;
    }

    if (qty < option.OPTION_DEFAULT_QTY) {
      // OPTION_DEFAULT_QTY보다 작은 경우 기본 가격 반환
      return option.OPTION_DEFAULT_AMT;
    } else {
      // OPTION_DEFAULT_QTY보다 큰 경우 추가 가격 계산
      let additionalQty = qty - option.OPTION_DEFAULT_QTY; // 추가된 수량
      let additionalPrice =
        Math.ceil(additionalQty / option.OPTION_ADD_QTY) *
        option.OPTION_ADD_AMT; // 추가된 수량에 해당하는 추가 가격
      return option.OPTION_DEFAULT_AMT + additionalPrice; // 기본 가격에 추가 가격을 더하여 반환
    }
  };

  const calculateDifference = (item) => {
    return Math.abs(item.maesu - qty);
  };

  function findClosest(Price) {
    return Price.reduce((prev, curr) => {
      return calculateDifference(curr) < calculateDifference(prev)
        ? curr
        : prev;
    });
  }

  const updateOptionAmt = () => {
    console.log(optionPriceTable);
    let amt = 0;

    if (selOption.earDori === true) {
      const findedItem = findClosest(RoundingPrice); //단가표
      console.log(findedItem);
      const premium = 1;

      amt += Math.round((findedItem["price"] * premium) / 100) * 100;
    }

    //타공
    if (selOption.punching === true) {
      const findedItem = findClosest(PunchingPrice); //단가표
      const premium = 1;
      //타공 개수 단가 * 수량 * premium 100의 자리 반올림
      amt +=
        Math.round((findedItem[selOption.punchingQty] * qty * premium) / 100) *
        100;
    }

    //오시_명함
    if (selOption.osi === true) {
      const findedItem = findClosest(OsiNameCardPrice); //단가표
      const premium = 1;
      //타공 개수 단가 * premium 100의 자리 반올림
      const price =
        Math.round((findedItem[selOption.osiQty] * premium) / 100) * 100;
      const min_price = 5000;
      const finalPrice = Math.max(price, min_price);
      amt += finalPrice;
    }

    //미싱
    if (selOption.missing === true) {
      let findedItem;
      if (selOption.missingQty === "line3") {
        findedItem = findClosest(MissingPrice3); //단가표
      } else {
        findedItem = findClosest(MissingPrice1); //단가표
      }

      const premium = 1;
      //타공 개수 단가 * premium 100의 자리 반올림
      const price = Math.round((findedItem["a6"] * qty * premium) / 100) * 100;
      const min_price = 5000;
      const finalPrice = Math.max(price, min_price);

      amt += finalPrice;
    }

    //코팅 명함
    if (selOption.coating === true) {
      const dan = 3400 - 700;
      const dan_add = 700;
      const yang = 4900 - 1300;
      const yang_add = 1300;

      let price = 0;
      if (selOption.coatingOption.indexOf("dan") != -1) {
        price = dan + (dan_add / 100) * qty;
      } else {
        price = yang + (yang_add / 100) * qty;
      }

      const premium = 1;
      const finalPrifce = Math.round((price * premium) / 100) * 100;

      amt += finalPrifce;
    }

    // if(selOption.cover)

    setOptionAmt(amt);
  };

  useEffect(() => {
    updateOptionAmt();
  }, [qty]);

  /**
   * 후가공
   *
   */

  /**
   * 옵션 이름 생성
   *
   */

  const createOptionNm = () => {
    let OptionNm = [];

    const prodNm = prodDetail?.PROD_NM;
    console.log(SelectOptions[prodNm]);

    //명함
    if (["일반지명함", "고급지명함"].includes(prodNm)) {
      OptionNm.push(SelectOptions[prodNm]?.용지);

      if (SelectOptions[prodNm]?.코팅) {
        OptionNm.push(optionList[prodNm]?.코팅);
      }

      if (SelectOptions[prodNm]?.귀도리) {
        OptionNm.push(`귀도리 : ${optionList[prodNm]?.귀도리}`);
      }

      if (SelectOptions[prodNm]?.오시) {
        OptionNm.push(`오시 : ${optionList[prodNm]?.오시}`);
      }

      if (SelectOptions[prodNm]?.미싱) {
        OptionNm.push(`미싱 : ${optionList[prodNm]?.미싱}`);
      }

      if (SelectOptions[prodNm]?.타공) {
        OptionNm.push(`타공 : ${optionList[prodNm]?.타공}`);
      }
    }

    //홍보물
    if (["전단지", "엽서", "포스터", "리플릿"].includes(prodNm)) {
      OptionNm.push(SelectOptions[prodNm]?.용지);

      OptionNm.push(
        `규격 : ${SelectOptions[prodNm]?.가로}x${SelectOptions[prodNm]?.세로}`
      );

      OptionNm.push(`${SelectOptions[prodNm]?.인쇄}`);

      if (prodNm === "엽서") {
        if (SelectOptions[prodNm]?.뒷면 !== "선택안함") {
          OptionNm.push(`뒷면 : ${SelectOptions[prodNm]?.뒷면}`);
        }
      }

      if (SelectOptions[prodNm]?.코팅) {
        OptionNm.push(optionList[prodNm]?.코팅);
      }

      if (SelectOptions[prodNm]?.귀도리) {
        OptionNm.push(`귀도리 : ${optionList[prodNm]?.귀도리}`);
      }

      if (SelectOptions[prodNm]?.오시) {
        OptionNm.push(`오시 : ${optionList[prodNm]?.오시}`);
      }

      if (SelectOptions[prodNm]?.미싱) {
        OptionNm.push(`미싱 : ${optionList[prodNm]?.미싱}`);
      }

      if (SelectOptions[prodNm]?.타공) {
        OptionNm.push(`타공 : ${optionList[prodNm]?.타공}`);
      }
    }

    //책자
    if (
      ["단행본", "브로슈어", "스프링노트", "제안서", "노트"].includes(prodNm)
    ) {
      OptionNm.push(
        `규격 : ${SelectOptions["책자"]?.가로}x${SelectOptions["책자"]?.세로}`
      );

      OptionNm.push(
        `표지 : ${SelectOptions["책자"]?.표지} ${SelectOptions["책자"]?.표지인쇄}`
      );

      if (SelectOptions["책자"]?.내지템플릿) {
        OptionNm.push(`내지템플릿 : ${SelectOptions["책자"]?.내지템플릿}`);
      }

      if (SelectOptions["책자"]?.표지코팅) {
        OptionNm.push(`표지코팅 : ${optionList["책자"]?.표지코팅}`);
      }

      OptionNm.push(`(세네카 ${SelectOptions["책자"]?.세네카})`);

      OptionNm.push(
        `내지 : ${SelectOptions["책자"]?.내지} ${SelectOptions["책자"]?.내지인쇄} ${SelectOptions["책자"]?.페이지}p`
      );

      OptionNm.push(`제본 : ${SelectOptions["책자"]?.제본}`);

      if (SelectOptions["책자"]?.박) {
        OptionNm.push(`박 : ${optionList["책자"]?.박}`);
      }

      if (SelectOptions["책자"]?.형압) {
        OptionNm.push(`형압 : ${optionList["책자"]?.형압}`);
      }

      if (SelectOptions["책자"]?.부분코팅) {
        OptionNm.push(`부분코팅`);
      }
    }

    //실사출력
    if (["현수막", "X배너"].includes(prodNm)) {
      OptionNm.push(
        `규격 : ${SelectOptions[prodNm]?.가로}x${SelectOptions[prodNm]?.세로}`
      );

      OptionNm.push(`소재 : ${SelectOptions[prodNm]?.소재}`);

      OptionNm.push(`후가공 : ${SelectOptions[prodNm]?.후가공}`);

      OptionNm.push(`열재단 : ${SelectOptions[prodNm]?.열재단}`);

      if (["X배너"].includes(prodNm) && SelectOptions[prodNm]?.거치대수량 > 0) {
        OptionNm.push(
          `거치대 : ${SelectOptions[prodNm]?.거치대} ${SelectOptions[prodNm]?.거치대수량}개`
        );
      }
    }

    //봉투

    if (["대봉투", "소봉투"].includes(prodNm)) {
      OptionNm.push(`용지 : ${SelectOptions[prodNm]?.용지}`);

      if (SelectOptions[prodNm]?.양면테이프 === "양면테이프") {
        OptionNm.push(`양면테이프`);
      }
    }

    if (["낱장스티커", "DIY스티커"].includes(prodNm)) {
      OptionNm.push(`용지 : ${SelectOptions[prodNm]?.용지}`);

      if (prodNm === "낱장스티커") {
        OptionNm.push(`모양 : ${SelectOptions[prodNm]?.모양}`);
      }
      OptionNm.push(
        `규격 : ${SelectOptions[prodNm]?.가로}x${SelectOptions[prodNm]?.세로}`
      );

      if (SelectOptions[prodNm]?.모양따기) {
        OptionNm.push(`모양따기 : ${SelectOptions[prodNm]?.모양따기}`);
      }

      if (SelectOptions[prodNm]?.코팅) {
        OptionNm.push(optionList[prodNm]?.코팅);
      }
    }

    return OptionNm;
  };

  /**
   * 스낵바
   */

  const [snackbar, setSnackbar] = useState(null);

  //공통

  const [globalQty, setGlobalQty] = useState(1);
  const [globalAmt, setGlobalAmt] = useState(0);
  const [globalOptionAmt, setGlobalOptionAmt] = useState(0);
  const [globalTax, setGlobalTax] = useState(0);

  //

  //책자
  const [bookQty, setBookQty] = useState(1);
  const [bookCoverAmt, setBookCoverAmt] = useState(0);
  const [bookBindingAmt, setBookBindingAmt] = useState(0);
  const [bookCoatingAmt, setBookCoatingAmt] = useState(0);
  const [bookInnerAmt, setBookInnerAmt] = useState(0);
  const [bookOptionAmt, setBookOptionAmt] = useState(0);

  //책자 API
  const getPrice_paper = async (params) => {
    if (
      Object.values(params).some(
        (value) => value === null || value === "" || value === undefined
      )
    ) {
      console.error("Invalid parameter detected:", params);
      return;
    }

    const result = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/calc_price",
      {
        params,
      }
    );

    return result;
  };

  //책자 최종가격 계산
  const calcTotalAmt = () => {
    let defaultAmt_copy =
      isNaN(defaultAmt) || defaultAmt === undefined ? 0 : defaultAmt;
    let optionAmt_copy =
      isNaN(optionAmt) || optionAmt === undefined ? 0 : optionAmt;
    let bookCoverAmt_copy =
      isNaN(bookCoverAmt) || bookCoverAmt === undefined ? 0 : bookCoverAmt;
    let bookInnerAmt_copy =
      isNaN(bookInnerAmt) || bookInnerAmt === undefined ? 0 : bookInnerAmt;
    let bookOptionAmt_copy =
      isNaN(bookOptionAmt) || bookOptionAmt === undefined ? 0 : bookOptionAmt;
    let bookCoatingAmt_copy =
      isNaN(bookCoatingAmt) || bookCoatingAmt === undefined
        ? 0
        : bookCoatingAmt;
    let bookBindingAmt_copy =
      isNaN(bookBindingAmt) || bookBindingAmt === undefined
        ? 0
        : bookBindingAmt;

    setTotalAmt(
      parseInt(optionAmt_copy) +
        parseInt(defaultAmt_copy) +
        parseInt(bookCoverAmt_copy) +
        parseInt(bookInnerAmt_copy) +
        parseInt(bookOptionAmt_copy) +
        parseInt(bookCoatingAmt_copy) +
        parseInt(bookBindingAmt_copy)
    );

    // 책자일 경우
    if (prodDetail?.PROD_OPTIONS?.indexOf("책자") !== -1) {
      const bookletTax =
        (parseInt(optionAmt_copy) +
          parseInt(defaultAmt_copy) +
          parseInt(bookCoverAmt_copy) +
          parseInt(bookInnerAmt_copy) +
          parseInt(bookOptionAmt_copy) +
          parseInt(bookCoatingAmt_copy) +
          parseInt(bookBindingAmt_copy)) *
        0.1;

      setTaxAmt(Math.round(bookletTax / 10) * 10);
    } else {
      const elseTax =
        (parseInt(optionAmt_copy) + parseInt(defaultAmt_copy)) * 0.1;
      setTaxAmt(Math.round(elseTax / 10) * 10);
    }
  };

  useEffect(() => {
    calcTotalAmt();
  }, [
    optionAmt,
    defaultAmt,
    bookCoverAmt,
    bookInnerAmt,
    bookOptionAmt,
    bookCoatingAmt,
    bookBindingAmt,
  ]);

  // 옵션 체크
  const [SelectOptions, setSelectOptions] = useState({
    일반지명함: { 가로: 90, 세로: 50 },
    고급지명함: { 가로: 90, 세로: 50 },
    펄지명함: { 가로: 90, 세로: 50 },
    전단지: {},
    엽서: {},
    포스터: {},
    리플릿: {},
    낱장스티커: {},
    원형스티커: {},
    DIY스티커: {},
    책자: {},
    단행본: {},
    브로슈어: {},
    스프링노트: {},
    제안서: {},
    노트: {},
    박스: {},
    합지박스: {},
    쇼핑백: {},
    현수막: {},
    배너: {},
    X배너: {},
    대봉투: {},
    소봉투: {},
  });

  //옵션 나열
  const [optionList, setOptionList] = useState({
    일반지명함: {},
    고급지명함: {},
    펄지명함: {},
    전단지: {},
    엽서: {},
    포스터: {},
    리플릿: {},
    낱장스티커: {},
    원형스티커: {},
    DIY스티커: {},
    책자: {},
    단행본: {},
    브로슈어: {},
    스프링노트: {},
    제안서: {},
    노트: {},
    박스: {},
    합지박스: {},
    쇼핑백: {},
    현수막: {},
    배너: {},
    X배너: {},
    대봉투: {},
    소봉투: {},
  });

  useEffect(() => {
    console.log(SelectOptions[prodDetail?.PROD_NM]);
    console.log(SelectOptions);
  }, [SelectOptions]);

  useEffect(() => {
    console.log(optionList);
    console.log(optionList[prodDetail?.PROD_NM]);
  }, [optionList]);

  //단가표

  const [priceTable, setPriceTable] = useState([]);
  const [priceTable_global, setPriceTable_global] = useState([]);

  //단가표 불러오기
  useEffect(() => {
    getPrices();
    getPrices_global();
    setPrintPrice(0);
    setOptionPrice(0);
    setTaxPrice(0);
    setDesignFile();
    setDesignFileName();
    setDesignCheck(false);
  }, [prodDetail?.PROD_NM]);

  const [printPrice, setPrintPrice] = useState(0);
  const [optionPrice, setOptionPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);

  //변경시 단가 계산
  useEffect(() => {
    const finalPrice = PriceCalc(
      priceTable,
      priceTable_global,
      prodDetail?.PROD_NM,
      SelectOptions,
      optionList
    );

    if (finalPrice === undefined) return;

    if (isNaN(finalPrice?.print) || isNaN(finalPrice?.option)) return;

    const rounded_printPrice = Math.round(finalPrice?.print / 10) * 10;
    const rounded_optionPrice = Math.round(finalPrice?.option / 10) * 10;
    const rounded_taxPrice =
      Math.round(((rounded_printPrice + rounded_optionPrice) * 0.1) / 10) * 10;

    setPrintPrice(rounded_printPrice);
    setOptionPrice(rounded_optionPrice);
    setTaxPrice(rounded_taxPrice);
  }, [SelectOptions, optionList, []]);

  // 상품별 단가표
  const getPrices = async () => {
    let prodNm_copy = prodDetail?.PROD_NM;
    if (
      ["단행본", "브로슈어", "스프링노트", "제안서", "노트"].includes(
        prodDetail?.PROD_NM
      )
    ) {
      prodNm_copy = "책자";
    }

    const price_data = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/global/prices", {
        params: { PRICE_PROD_CATE: prodNm_copy },
      })
    ).data;

    console.log("단가표 >>", price_data);
    setPriceTable(price_data);

    // console.log(price_data);

    // console.log(
    //   price_data.filter(
    //     (el) => el.PRICE_OPTION_NM === SelectOptions[prodDetail?.PROD_NM].용지
    //   )
    // );

    // const testdata = price_data.filter(
    //   (el) => el.PRICE_OPTION_NM === SelectOptions[prodDetail?.PROD_NM].용지
    // );

    // console.log(
    //   filterByQty(testdata, SelectOptions[prodDetail?.PROD_NM]?.수량)
    // );

    // const paperPrice =
    //   filterByQty(testdata, SelectOptions[prodDetail?.PROD_NM]?.수량)
    //     .PRICE_PRICE * SelectOptions[prodDetail?.PROD_NM]?.수량;

    // console.log("용지가격 : ", paperPrice);
  };

  // 공통 단가표
  const getPrices_global = async () => {
    const price_data = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/global/prices", {
        params: { PRICE_PROD_CATE: "공통" },
      })
    ).data;

    console.log("공통단가표 >>", price_data);
    setPriceTable_global(price_data);
  };

  const renderImg = (name) => {
    let url = "";

    if (name === "아트지") {
      url = "/paper/art.png";
    } else if (name === "아르떼") {
      url = "/paper/arte.png";
    } else if (name === "몽블랑") {
      url = "/paper/mont.png";
    } else if (name === "모조지") {
      url = "/paper/mojo_white.png";
    } else if (name === "스노우") {
      url = "/paper/snow.png";
    } else if (name === "랑데뷰") {
      url = "/paper/rendezvous.png";
    } else if (name === "마쉬멜로우") {
      url = "/paper/mash.png";
    } else {
      url = "/paper/noimg.png";
    }

    return (
      <img src={url} alt={name} style={{ width: "100%", height: "100%" }} />
    );
  };

  return (
    <>
      {/* 용지 설명 */}
      {hoveredItem && (
        <>
          <Box
            sx={{
              zIndex: 2000,
              position: "absolute",
              top: hoveredY,
              left: hoveredX - 275,
              width: "250px",
              height: "250px",
            }}
          >
            {renderImg(hoveredItem.getAttribute("data-value"))}
          </Box>
        </>
      )}
      {isPc ? (
        <>
          {progress && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "500vh",
                top: 0,
                left: 0,
                textAlign: "center",
                lineHeight: "800px",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 2000,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <S.MainLayout>
            {/* <Button onClick={createOptionNm}>ㅇㅇ</Button> */}
            <S.MainSection>
              <S.ProdDetailWrapper>
                <S.ProdDetailBox ref={DtailBox}>
                  <S.ProdDetailLeft>
                    <S.ProdDetailSliderBox topValue={scrollPositon}>
                      <S.ProdDetailMainSlider>
                        <S.ProdDetailMainSliderView
                          img={prodImages[SliderIndex]?.IMAGE_LOCATION}
                        >
                          <S.ProdDetailSliderPrev onClick={handlePrevClick} />
                          <S.ProdDetailSliderNext onClick={handleNextClick} />
                        </S.ProdDetailMainSliderView>
                      </S.ProdDetailMainSlider>
                      <S.ProdDetailSubSlider>
                        {prodImages?.map((item, index) => (
                          <S.ProdDetailSubSliderView
                            className={
                              index === SliderIndex ? "selected" : null
                            }
                            onClick={() => {
                              handleSlideClick(index);
                            }}
                            img={item.IMAGE_LOCATION}
                          />
                        ))}
                      </S.ProdDetailSubSlider>
                    </S.ProdDetailSliderBox>
                  </S.ProdDetailLeft>
                  <S.ProdDetailRight>
                    <S.ProdDetailTitle>{prodDetail?.PROD_NM}</S.ProdDetailTitle>
                    {prodDetail?.PROD_DETAIL?.split("|").map((el, index) => (
                      <S.ProdDetailDesc>{el}</S.ProdDetailDesc>
                    ))}
                    <S.Product_Detail_Option_ItemWrapper>
                      {/* 옵션 */}
                      <ProdOptions
                        prod={prodDetail?.PROD_NM}
                        SelectOptions={SelectOptions}
                        setSelectOptions={setSelectOptions}
                        optionList={optionList}
                        setOptionList={setOptionList}
                      />
                    </S.Product_Detail_Option_ItemWrapper>
                    {prodDetail?.PROD_NOTI?.split("|").map((el, index) => (
                      <S.ProdDetailDesc>{el}</S.ProdDetailDesc>
                    ))}
                    {/* 템플릿 다운로드 */}
                    {prodDetail?.PROD_NM.indexOf("명함") !== -1 && (
                      <Box
                        sx={{
                          backgroundColor: "#f0f0f0",
                          padding: "10px",
                          borderRadius: "5px",
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "bold",
                          marginTop: "10px",
                          color: "#fff",
                          backgroundColor: "#469cff",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#2e8efd",
                          },
                        }}
                        onClick={() => {
                          window.open("/template/namecard.ai");
                        }}
                      >
                        명함 양식 다운로드
                      </Box>
                    )}
                    {prodDetail?.PROD_NM.indexOf("DIY스티커") !== -1 && (
                      <Box
                        sx={{
                          backgroundColor: "#f0f0f0",
                          padding: "10px",
                          borderRadius: "5px",
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "bold",
                          marginTop: "10px",
                          color: "#fff",
                          backgroundColor: "#469cff",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#2e8efd",
                          },
                        }}
                        onClick={() => {
                          window.open("/template/sticker.ai");
                        }}
                      >
                        스티커 양식 다운로드
                      </Box>
                    )}
                    {/* <Link to="/order"> */}
                    {/* <S.ProdDetailPayButton onClick={handleSendCart}>
                장바구니에 담기
              </S.ProdDetailPayButton> */}
                    {/* </Link> */}
                  </S.ProdDetailRight>
                  <S.ProdDetailRight2 />
                </S.ProdDetailBox>
              </S.ProdDetailWrapper>
              {/* 견적서 */}
              <ProdPrice
                prod={prodDetail?.PROD_NM}
                USER_ID={USER_ID}
                openPopup={openPopup}
                setSnackbar={setSnackbar}
                designFile={designFile}
                setDesignFile={setDesignFile}
                designFileName={designFileName}
                setDesignFileName={setDesignFileName}
                designCheck={designCheck}
                setDesignCheck={setDesignCheck}
                handleSendCart={handleSendCart}
                handleSendOrder={handleSendOrder}
                handleSendDesign={handleSendDesign}
                imgUrl={prodImages[SliderIndex]?.IMAGE_LOCATION}
                printPrice={printPrice}
                optionPrice={optionPrice}
                taxPrice={taxPrice}
                progress={progress}
                setProgress={setProgress}
              />
              {/* 견적서 끝 */}
            </S.MainSection>
            {/* 주문하기 및 옵션 선택 */}
            <S.MainSection>
              <S.ProductOrderBox>
                <OptionDetail
                  prod={prodDetail?.PROD_NM}
                  SelectOptions={SelectOptions}
                  setSelectOptions={setSelectOptions}
                  optionList={optionList}
                  setOptionList={setOptionList}
                />
              </S.ProductOrderBox>
            </S.MainSection>
            {/* 주문하기 및 옵션 선택 끝 */}
            <S.MainSection bgc="">
              <S.ProdDetailContentWrapper>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    mb: 2,
                  }}
                >
                  <Button
                    onClick={() => {
                      window.open("http://pf.kakao.com/_kfpYG/chat");
                      if (window.wcs) {
                        const _conv = {
                          type: `lead`,
                        };
                        window.wcs.trans(_conv);
                      }
                    }}
                    startIcon={
                      <img
                        src={"/icons/kakao2.png"}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    }
                    variant="contained"
                    sx={{
                      backgroundColor: "rgb(250,225,0)",
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "18px",
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "rgb(250,225,0)",
                      },
                    }}
                  >
                    카카오톡 상담하기
                  </Button>
                  <br />
                  주문이 처음이신가요?
                  <br />
                  주문이 어려울 땐 언제든 문의주세요!
                </Box>
                <div className="ql-snow">
                  <div className="ql-editor">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: prodDetail?.PROD_CONTENT,
                      }}
                    />
                  </div>
                </div>
              </S.ProdDetailContentWrapper>
            </S.MainSection>
            <S.MainSection>
              <S.ProductReviewWrapper>
                <h1>고객 리뷰</h1>
                <ReviewBoard reviewData={reviewData} />
              </S.ProductReviewWrapper>
            </S.MainSection>
          </S.MainLayout>
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={() => {
                setSnackbar(null);
              }}
              autoHideDuration={3000}
            >
              <Alert
                {...snackbar}
                onClose={() => {
                  setSnackbar(null);
                }}
              />
            </Snackbar>
          )}
        </>
      ) : (
        <>
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={() => {
                setSnackbar(null);
              }}
              autoHideDuration={3000}
            >
              <Alert
                {...snackbar}
                onClose={() => {
                  setSnackbar(null);
                }}
              />
            </Snackbar>
          )}
          {progress && (
            <Box
              sx={{
                position: "fixed",
                width: "100%",
                height: "500vh",
                top: 0,
                left: 0,
                textAlign: "center",
                lineHeight: "500px",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 2000,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box className="wrapper">
            <Box>
              <S.ProdDetailTitle>{prodDetail?.PROD_NM}</S.ProdDetailTitle>
            </Box>
            <S.ProdDetailMainSlider>
              <S.ProdDetailMainSliderView_Mob
                img={prodImages[SliderIndex]?.IMAGE_LOCATION}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <S.ProdDetailSliderPrev_Mob onClick={handlePrevClick} />
                  <S.ProdDetailSliderNext_Mob onClick={handleNextClick} />
                </Box>
              </S.ProdDetailMainSliderView_Mob>
            </S.ProdDetailMainSlider>
            <S.ProdDetailSubSlider_Mob>
              {prodImages?.map((item, index) => (
                <S.ProdDetailSubSliderView
                  className={index === SliderIndex ? "selected" : null}
                  onClick={() => {
                    handleSlideClick(index);
                  }}
                  img={item.IMAGE_LOCATION}
                />
              ))}
            </S.ProdDetailSubSlider_Mob>
            <Divider sx={{ mt: 1.5, mb: 1.5 }} />
            {prodDetail?.PROD_DETAIL?.split("|").map((el, index) => (
              <S.ProdDetailDesc_Mob>{el}</S.ProdDetailDesc_Mob>
            ))}
            <Divider sx={{ mt: 1.5, mb: 1.5 }} />
            <Box p={1.5}>
              <ProdOptions
                prod={prodDetail?.PROD_NM}
                SelectOptions={SelectOptions}
                setSelectOptions={setSelectOptions}
                optionList={optionList}
                setOptionList={setOptionList}
              />
            </Box>
            <Box p={1.5}>
              <OptionDetail
                prod={prodDetail?.PROD_NM}
                SelectOptions={SelectOptions}
                setSelectOptions={setSelectOptions}
                optionList={optionList}
                setOptionList={setOptionList}
              />
            </Box>
            <ProdPrice
              prod={prodDetail?.PROD_NM}
              USER_ID={USER_ID}
              openPopup={openPopup}
              setSnackbar={setSnackbar}
              designFile={designFile}
              setDesignFile={setDesignFile}
              designFileName={designFileName}
              setDesignFileName={setDesignFileName}
              designCheck={designCheck}
              setDesignCheck={setDesignCheck}
              handleSendCart={handleSendCart}
              handleSendOrder={handleSendOrder}
              handleSendDesign={handleSendDesign}
              imgUrl={prodImages[SliderIndex]?.IMAGE_LOCATION}
              printPrice={printPrice}
              optionPrice={optionPrice}
              taxPrice={taxPrice}
              progress={progress}
              setProgress={setProgress}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bold",
              lineHeight: "1.5",
              mb: 2,
              mt: 6,
            }}
          >
            <Button
              onClick={() => {
                window.open("http://pf.kakao.com/_kfpYG/chat");
                if (window.wcs) {
                  const _conv = {
                    type: `lead`,
                  };
                  window.wcs.trans(_conv);
                }
              }}
              startIcon={
                <img
                  src={"/icons/kakao2.png"}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              }
              variant="contained"
              sx={{
                backgroundColor: "rgb(250,225,0)",
                color: "#000",
                fontWeight: "bold",
                fontSize: "18px",
                mb: 2,
                "&:hover": {
                  backgroundColor: "rgb(250,225,0)",
                },
              }}
            >
              카카오톡 상담하기
            </Button>
            <br />
            주문이 처음이신가요?
            <br />
            주문이 어려울 땐 언제든 문의주세요!
          </Box>
          <Box>
            <div className="ql-snow">
              <div className="ql-editor">
                <div
                  dangerouslySetInnerHTML={{
                    __html: prodDetail?.PROD_CONTENT,
                  }}
                />
              </div>
            </div>
          </Box>
          <Box mt={3} mb={3}>
            <Typography
              sx={{
                fontFamily: "Noto Sans KR",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              고객 리뷰
            </Typography>
            <ReviewBoard reviewData={reviewData} />
          </Box>
        </>
      )}
    </>
  );
};

export default ProductDetailPage;

import React, { useEffect, useRef, useState } from "react";
import * as S from "../../styles/new_styles";
// import * as XLSX from "xlsx/xlsx.mjs";
import * as XLSX from "xlsx-js-style";
import {
  formatDate,
  formatDateAndTime,
  formatORDER_PAYMENT_TYPE,
  formatTime,
} from "../../hooks/Utill";
import axios from "axios";
import AdminOrderDetail from "./AdminOrderDetail";
import Pagination from "react-js-pagination";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { Download, Upload } from "@mui/icons-material";

const AdminOrder = ({ openPopup }) => {
  const [initOrderlist_frist, setInitOrderlist_frist] = useState([]);
  const [initOrderlist, setInitOrderlist] = useState([]);
  const [orderlist, setOrderlist] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderDetailVisible, setOrderDetailVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [logisNm, setLogisNm] = useState("CJ택배");
  const [logisNo, setLogisNo] = useState("");

  const [snackbar, setSnackbar] = useState(false);

  const handlePageChange = (e) => {
    setCurrentPage(e);
    const startIndex = (e - 1) * countPerPage;
    const endIndex = startIndex + countPerPage;
    const pageItems = initOrderlist.slice(startIndex, endIndex);
    setOrderlist(pageItems);
  };

  const getToken = async () => {
    const url = "https://auth.tracker.delivery/oauth2/token";
    const params =
      "?grant_type=client_credentials&client_id=52ct0v88mspvl4h4ovspsrrp9h&client_secret=1f2er0ahub2q8i2rg8v74hsgj4i8157go646k10i3phu9kp38n2h";

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const res = await fetch(url + params, config);
      return await res.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const DownLoadCjExcel = async () => {
    console.log(apiRef.current.getSelectedRows());
    if (apiRef.current.getSelectedRows().size === 0) {
      setSnackbar({
        severity: "error",
        children: "주문을 선택해주세요.",
      });
      return false;
    }

    //
    const fileName = `${formatDate(new Date())} 스노우플래닛 CJ송장`; // 다운로드할 파일 이름
    const data = [];

    apiRef.current.getSelectedRows().forEach((el) => {
      console.log(el);
      const addData = {
        받는분성명: el.ORDER_REC,
        주소: `${el.ORDER_ADDRESS} ${el.ORDER_ADD_ADDRESS} (${el.ORDER_POSTCODE})`,
        받는분전화번호: el.REC_TEL,
        내품명: "",
        품목명: el.ORDER_CORE_PROD,
        배송메세지1: el.ORDER_REQ,
        배송메세지3: "",
      };
      data.push(addData);
    });

    const datas = data?.length ? data : []; // data가 없으면 빈 배열

    // 스타일 적용
    const worksheet = XLSX.utils.json_to_sheet(datas);
    const range = XLSX.utils.decode_range(worksheet["!ref"]);

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1"; // 첫 번째 행 주소
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: "F0F0F0" } }, // 배경색 설정
        alignment: { horizontal: "center" }, // 가운데 정렬
      };
    }

    // 열 너비 설정
    const wscols = [
      { wch: 20 }, // 받는분성명 열 너비
      { wch: 50 }, // 주소 열 너비
      { wch: 20 }, // 받는분전화번호 열 너비
      { wch: 20 }, // 내품명 열 너비
      { wch: 20 }, // 품목명 열 너비
      { wch: 20 }, // 배송메세지1 열 너비
      { wch: 20 }, // 배송메세지3 열 너비
    ];
    worksheet["!cols"] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName ? `${fileName}.xlsx` : "data.xlsx");
  };

  const UpLoadCjExcel = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx, .xls";

    input.onchange = async (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // 여기서 jsonData를 활용하여 원하는 처리 수행

        try {
          jsonData.forEach(async (el, index) => {
            const res = await axios.post(
              process.env.REACT_APP_DB_HOST + "/api/admin/order/invoice",
              {
                ORDER_LOGIS_NM: "CJ택배",
                ORDER_LOGIS_NO: el.운송장번호.replace(/-/g, ""),
                ORDER_REC: el.받는분,
                ORDER_POSTCODE: el.받는분우편번호,
                REC_TEL: el.받는분전화번호,
              }
            );

            if (res.status === 200) {
              logisRegister(el.운송장번호.replace(/-/g, ""));
              initdb();
            } else {
              setSnackbar({
                severity: "error",
                children: `송장번호 등록에 실패하였습니다. (${index + 1}번째)`,
              });
            }
          });

          setSnackbar({
            severity: "success",
            children: `송장번호 업로드가 완료되었습니다. (${jsonData.length}건)`,
          });
        } catch (error) {
          console.error("Error:", error);
          setSnackbar({
            severity: "error",
            children: "송장번호 업로드 중 오류가 발생했습니다.",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    };

    input.click();
  };

  const allCheckbox = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    initdb();
  }, []);

  const renderOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "결제 대기";
      case 2:
        return "결제 완료";
      case 3:
        return "제작 중";
      case 4:
        return "배송 중";
      case 5:
        return "배송 완료";
      case 9:
        return "취소";
      default:
        return "Code error";
    }
  };

  const statusFillter = (status) => {
    const fillerdata = initOrderlist_frist.filter(
      (el) => el.ORDER_STATUS === status
    );
    const initSelectedItem = Array.from(
      { length: fillerdata?.length },
      () => false
    );
    console.log(initSelectedItem);
    setSelectedItem(initSelectedItem);
    setInitOrderlist(fillerdata);
    setOrderlist(fillerdata.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const statusFillter_cancle = () => {
    const initSelectedItem = Array.from(
      { length: initOrderlist?.length },
      () => false
    );
    console.log(initSelectedItem);
    setSelectedItem(initSelectedItem);
    setInitOrderlist(initOrderlist_frist);
    setOrderlist(initOrderlist_frist.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const updateStatus = async (value) => {
    console.log(apiRef.current.getSelectedRows());

    const row_cnt = apiRef.current.getSelectedRows().size;

    const order_sids = [];

    if (row_cnt === 0) {
      setSnackbar({
        severity: "info",
        children: "주문을 선택해주세요.",
      });
      return false;
    }

    if (value === 4) {
      if (row_cnt !== 1) {
        setSnackbar({
          severity: "info",
          children: "배송중 처리는 1개만 선택해주세요",
        });
        return false;
      }
      setDialogOpen(true);
      setLogisNo("");
      return false;
    }

    const confirm = window.confirm(
      `선택한 주문 ${row_cnt}건을 처리하시겠습니까?`
    );
    if (!confirm) return false;

    apiRef.current.getSelectedRows().forEach((el) => {
      order_sids.push(el.ORDER_SID);
    });

    const res = await axios.put(
      process.env.REACT_APP_DB_HOST + "/api/admin/order",
      {
        order_sid: order_sids,
        field: "ORDER_STATUS",
        order_status: value,
      }
    );

    initdb();
    console.log(apiRef);
    console.log(gridRef);

    apiRef.current.selectRow(null);
    return;
  };

  //MUI DATA TABLE
  const columns = [
    {
      field: "ORDER_DATE",
      headerName: "주문일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_PAYMENT_DATE",
      headerName: "결제일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_SID",
      headerName: "주문번호",
      width: 160,
      editable: false,
    },
    {
      field: "ORDER_NM",
      headerName: "주문자",
      width: 110,
      editable: false,
    },
    {
      field: "ORDER_CORE_PROD",
      headerName: "상품명",
      width: 130,
      editable: false,
      renderCell: (params) => {
        const items = params.row.ITEM_SIDS.split(",");
        if (items.length > 1) {
          return `${params.row.ORDER_CORE_PROD} 외 ${items.length - 1}건`;
        }
      },
    },
    {
      field: "ORDER_AMOUNT",
      headerName: "결제금액",
      width: 100,
      editable: false,
      type: "number",
    },
    {
      field: "ORDER_PAYMENT_TYPE",
      headerName: "결제수단",
      width: 150,
      editable: false,
      renderCell: (params) => {
        let res = formatORDER_PAYMENT_TYPE(params.value);

        if (params.row.CASH_DEPOSITOR_NAME) {
          res += " (" + params.row.CASH_DEPOSITOR_NAME + ")";
        }
        return res;
      },
    },
    {
      field: "ORDER_STATUS",
      headerName: "결제상태",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return renderOrderStatus(params.value);
      },
    },
    {
      field: "ORDER_LOGIS_NM",
      headerName: "택배사",
      width: 110,
      editable: true,
    },
    {
      field: "ORDER_LOGIS_NO",
      headerName: "송장번호",
      width: 120,
      editable: false,
    },
    {
      field: "ORDER_REQ",
      headerName: "요청사항",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_TYPE",
      headerName: "현금영수증",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_NO",
      headerName: "고객번호",
      width: 120,
      editable: false,
    },
    {
      field: "ORDER_USE_POINT",
      headerName: "포인트 사용",
      width: 200,
      editable: false,
      type: "number",
    },
    {
      field: "4",
      headerName: "비고",
      width: 200,
      editable: false,
    },
  ];

  const logisRegister = async (logisNo) => {
    const requestBody = {
      query: `mutation RegisterTrackWebhook(
    $input: RegisterTrackWebhookInput!
  ) {
    registerTrackWebhook(input: $input)
  }`.trim(),
      variables: {
        input: {
          carrierId: "kr.cjlogistics",
          trackingNumber: logisNo,
          callbackUrl: `https://snowplanet.co.kr:3030/api/delivery/callback?logisNo=${logisNo}`,
          expirationTime: new Date(
            Date.now() + 5 * 24 * 60 * 60 * 1000
          ).toISOString(),
        },
      },
    };
    const token = (await getToken()).access_token;
    const url = "https://apis.tracker.delivery/graphql";
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    };

    //
    try {
      const response = await fetch(url, config);
      const responseData = await response.json();
      console.log(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
    //
  };

  const initdb = async () => {
    const res = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/orderlist")
    ).data;

    const initSelectedItem = Array.from({ length: res?.length }, () => false);
    setSelectedItem(initSelectedItem);

    setInitOrderlist_frist(res);
    setInitOrderlist(res);
    setOrderlist(res.slice(0, countPerPage));

    const res_copy = res.map((el, index) => ({ ...el, id: index + 1 }));

    setDataRows(res_copy);
  };

  // const initdb = async () => {
  //   const res = (
  //     await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/orderlist")
  //   ).data;

  //   const initSelectedItem = Array.from({ length: res?.length }, () => false);
  //   setSelectedItem(initSelectedItem);

  //   setInitOrderlist_frist(res);
  //   setInitOrderlist(res);
  //   setOrderlist(res.slice(0, countPerPage));
  // };

  const [dataRows, setDataRows] = useState([]);
  const apiRef = useGridApiRef();

  const [filt, setFilt] = useState({
    items: [{}],
  });

  const filtOrderCanscle = () => {
    setFilt({
      items: [{ field: "ORDER_STATUS", operator: "equals", value: `` }],
    });
  };

  const filtOrderState = (value) => {
    setFilt({
      items: [{ field: "ORDER_STATUS", operator: "equals", value: `${value}` }],
    });
  };

  return (
    <>
      <S.MainLayout>
        <S.AdminWrapper>
          <Box mb={1}>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(1);
              }}
            >
              결제대기 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(2);
              }}
            >
              결제완료 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(3);
              }}
            >
              제작중 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(4);
              }}
            >
              배송중 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(5);
              }}
            >
              배송완료 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(9);
              }}
            >
              취소 처리
            </Button>
          </Box>
          <Box mb={1}>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderCanscle();
              }}
            >
              전체주문 {dataRows.length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(1);
              }}
            >
              결제대기 {dataRows.filter((el) => el.ORDER_STATUS === 1).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(2);
              }}
            >
              결제완료 {dataRows.filter((el) => el.ORDER_STATUS === 2).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(3);
              }}
            >
              제작중 {dataRows.filter((el) => el.ORDER_STATUS === 3).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(4);
              }}
            >
              배송중 {dataRows.filter((el) => el.ORDER_STATUS === 4).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(5);
              }}
            >
              배송완료 {dataRows.filter((el) => el.ORDER_STATUS === 5).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(9);
              }}
            >
              취소 {dataRows.filter((el) => el.ORDER_STATUS === 9).length}
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ float: "right", marginLeft: "8px" }}
              startIcon={<Download />}
              onClick={DownLoadCjExcel}
            >
              CJ 송장 양식
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ float: "right", marginLeft: "8px" }}
              startIcon={<Upload />}
              onClick={UpLoadCjExcel}
            >
              CJ 송장 업로드
            </Button>
          </Box>
          <Box sx={{ height: 680, width: "100%" }}>
            <DataGrid
              // filterModel={{
              //   items: [{ field: "ORDER_AMOUNT", operator: ">", value: "1" }],
              // }}
              filterModel={filt}
              // onFilterModelChange={(model) => {
              //   if (model != filt) setFilt(model);
              // }}
              apiRef={apiRef}
              ref={gridRef}
              rows={dataRows}
              columns={columns}
              // onCellEditStop={(params) => {
              //   console.log(params);
              //   handleEditUser(params);
              // }}
              processRowUpdate={(updatedRow, originalRow) => {
                // handleClickOpen(updatedRow);
                return updatedRow;
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowDoubleClick={(param) => {
                console.log(param);
                window.open(
                  `/admin/order/${param.row.ORDER_SID}`,
                  "주문 상세",
                  "width=1250,height=700,top=100,left=200"
                );
              }}
            />
          </Box>
        </S.AdminWrapper>
      </S.MainLayout>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          alert("변경이 취소되었습니다.");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", width: "350px" }}
        >
          운송장 번호 입력
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "left", padding: "10px 0" }}
          >
            <Box sx={{ width: "80%", margin: "0 auto", marginBottom: "16px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={logisNm}
                onChange={(e) => {
                  setLogisNm(e.target.value);
                }}
                fullWidth
              >
                <MenuItem value={"CJ택배"}>CJ택배</MenuItem>
                <MenuItem value={"롯데택배"}>롯데택배</MenuItem>
                <MenuItem value={"로젠택배"}>로젠택배</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: "80%", margin: "0 auto" }}>
              <TextField
                label="운송장 번호"
                fullWidth
                value={logisNo}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setLogisNo(value);
                }}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              if (logisNo === "") {
                alert("운송장 번호를 입력해주세요.");
                return false;
              }

              const order_sids = [];

              apiRef.current.getSelectedRows().forEach((el) => {
                order_sids.push(el.ORDER_SID);
              });

              const res = await axios.put(
                process.env.REACT_APP_DB_HOST + "/api/admin/order",
                {
                  order_sid: order_sids,
                  field: "ORDER_STATUS",
                  order_status: 4,
                  logis_nm: logisNm,
                  logis_no: logisNo,
                }
              );

              if (res.status === 200) {
                initdb();
                logisRegister(logisNo);
              }

              setDialogOpen(false);
            }}
            autoFocus
          >
            확인
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
              alert("변경이 취소되었습니다.");
            }}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar(false)}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(false)} />
        </Snackbar>
      )}
    </>
  );
};

export default AdminOrder;

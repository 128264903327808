import {
  Stage,
  Layer,
  Rect,
  Circle,
  Group,
  Star,
  Transformer,
  Text,
  Image,
  Wedge,
  Arc,
  Arrow,
  RegularPolygon,
  Line,
  Ellipse,
  Path,
  Ring,
} from "react-konva";
import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useContext,
} from "react";
import Konva from "konva";
import styled from "styled-components";
import { TextField } from "@mui/material";
import image1 from "../../assets/snow0.png";
import WebFont from "webfontloader";
import { useProgress } from "./Editor_ProgressContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";

const stageWidth = window.innerWidth - 350 - 56; // 72는 사이드바 350은 에디션
const stageHeight = window.innerHeight - 50; //50은 툴바 26은 임시 버튼
// const stageWidth = 2000 - 72 - 350; // 72는 사이드바 350은 에디션
// const stageHeight = 950 - 50; //50은 툴바 26은 임시 버튼

const calculateScale = (rectWidth, rectHeight, paperWidth, paperHeight) => {
  let percent = 1;
  if (paperWidth > 210) {
    percent = 0.9;
  } else if (paperWidth > 148) {
    percent = 0.8;
  } else {
    percent = 0.7;
  }

  //명함
  if (paperWidth === 90 && paperHeight === 50) {
    percent = 0.5;
  }

  const scaleXtest = (stageWidth * percent) / rectWidth; // 10% 여백을 둠
  const scaleYtest = (stageHeight * percent) / rectHeight; // 10% 여백을 둠
  const scaletest = Math.min(scaleXtest, scaleYtest);

  console.log("scaleXtest", scaleXtest);
  console.log("scaleYtest", scaleYtest);
  console.log("scaletest", scaletest);

  return scaletest;
};

const New_EditorKonva = ({
  editorRef,
  selectedNode,
  setSelectedNode,
  isSelected,
}) => {
  useImperativeHandle(editorRef, () => ({
    functions,
  }));
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [isSpacePressed, setIsSpacePressed] = useState(false);

  useEffect(() => {
    const stage = StageRef.current;
    if (stage) {
      const handleKeyDown = (e) => {
        if (e.code === "Space" && !isSpacePressed) {
          setIsSpacePressed(true);
          stage.draggable(true);
          document.body.style.cursor = "grab";
        }
      };

      const handleKeyUp = (e) => {
        if (e.code === "Space") {
          setIsSpacePressed(false);
          stage.draggable(false);
          document.body.style.cursor = "default";
        }
      };

      const handleDragMove = () => {
        setStagePos({
          x: stage.x(),
          y: stage.y(),
        });
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      stage.on("dragmove", handleDragMove);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
        stage.off("dragmove", handleDragMove);
      };
    }
  }, [isSpacePressed]);

  const { item_sid } = useParams();
  const [itemData, setItemData] = useState([]);

  const initdb = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/custom_prod",
      {
        params: {
          item_sid: item_sid,
        },
      }
    );

    setItemData(res.data);

    console.log(res);
  };

  useEffect(() => {
    initdb();
  }, []);

  useEffect(() => {
    setPaperWidth(itemData?.ITEM_WIDTH);
    setPaperHeight(itemData?.ITEM_HEIGHT);
  }, [itemData]);

  const { progress, setProgress } = useProgress();
  const backgroundRef = useRef(null);

  const [canvasScale, setCanvasScale] = useState(1.8);
  const [paperWidth, setPaperWidth] = useState(0);
  const [paperHeight, setPaperHeight] = useState(0);

  const canvasWidth = paperWidth + 4;
  const canvasHeight = paperHeight + 4;

  const rectWidth = (canvasWidth * 300) / 25.4 / 1.5;
  const rectHeight = (canvasHeight * 300) / 25.4 / 1.5;
  const rectX = stageWidth / 2 - rectWidth / 2;
  const rectY = stageHeight / 2 - rectHeight / 2;

  useEffect(() => {
    const scale = calculateScale(
      rectWidth,
      rectHeight,
      paperWidth,
      paperHeight
    );
    setCanvasScale(scale);
  }, [paperWidth, paperHeight]);

  // setcan

  const containerRef = useRef(null);

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    if (layerRef.current) {
      setProgress(true);
      console.log("레이어가 로드되었습니다:", layerRef.current);
      console.log("layerRef.current", layerRef.current.getSize());
      const scale = 0.9;
      const layerWidth = layerRef.current.getSize().width;
      const layerHeight = layerRef.current.getSize().height;
      const stageWidth = layerRef.current.getStage().width();
      const stageHeight = layerRef.current.getStage().height();

      layerRef.current.setScaleX(canvasScale);
      layerRef.current.setScaleY(canvasScale);

      const centerX = (stageWidth - layerWidth * canvasScale) / 2;
      const centerY = (stageHeight - layerHeight * canvasScale) / 2;

      layerRef.current.position({
        x: centerX,
        y: centerY,
      });

      console.log("레이어 위치 조정 후:", layerRef.current.position());
      layerRef.current.batchDraw();
      setProgress(false);
    }
  }, [canvasScale]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Kavivanar",
          "Noto Sans KR:400,700",
          "Nanum Gothic",
          "Nanum Myeongjo",
          "Malgun Gothic",
          "Gmarket Sans",
          "Pretendard",
          "Spoqa Han Sans",
          "Roboto",
          "Open Sans",
          "Lato",
          "Anton",
        ],
      },
      active: () => {
        // 폰트가 로드되었음을 확인
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = "12px Your Custom Font";
        const initialWidth = context.measureText("test").width;

        const checkFontLoaded = () => {
          context.font = "12px Your Custom Font";
          if (context.measureText("test").width !== initialWidth) {
            setFontLoaded(true);
            if (layerRef.current) {
              layerRef.current.batchDraw();
            }
          } else {
            setTimeout(checkFontLoaded, 50);
          }
        };

        checkFontLoaded();
      },
    });
  }, []);

  const [objects, setObjects] = useState([]);

  const [textEditMode, setTextEditMode] = useState(false);
  const [textEditObj, setTextEditObj] = useState(null);
  const [textEditInput, setTextEditInput] = useState("");

  const [selectedIds, setSelectedIds] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [selectionRect, setSelectionRect] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const StageRef = useRef(null);
  const groupRef = useRef(null);
  const trRef = useRef(null);
  const layerRef = useRef(null);
  const layerRef2 = useRef(null);
  const textEditRef = useRef(null);

  const [renderKey, setRenderKey] = useState(0); // 렌더링 키 추가

  const [groups, setGroups] = useState([]);

  const [images, setImages] = useState({});

  const [savePoint, setSavePoint] = useState(null);

  const [history, setHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);
  const isHistoryAction = useRef(false);

  useEffect(() => {
    objects.forEach((obj) => {
      if (obj.type === "Image" && !images[obj.id]) {
        console.log("이미지", obj);
        const img = new window.Image();
        img.src = obj.image;
        img.onload = () => {
          setImages((prev) => ({ ...prev, [obj.id]: img }));
        };
      }
    });
  }, [objects]);

  useEffect(() => {
    layerRef.current.children
      ?.find(
        (child) =>
          child.attrs.className === "Background" ||
          child.attrs.className === "Bleed"
      )
      ?.setZIndex(0);

    layerRef.current.children
      ?.find(
        (child) =>
          child.attrs.className === "OutOfBackground" ||
          child.attrs.className === "OutOfBackground1" ||
          child.attrs.className === "OutOfBackground2" ||
          child.attrs.className === "OutOfBackground3" ||
          child.attrs.className === "OutOfBackground4"
      )
      ?.setZIndex(1000);
  }, [objects]);

  useEffect(() => {
    if (selectedIds) {
      // 선택된 요소 찾기
      console.log("selectedIds.length", selectedIds?.length);
      if (selectedIds.length > 0 && selectedIds.length === 1) {
        //하나만 선택 됐을 때
        const selectedNode = StageRef.current.findOne(`#${selectedIds[0]}`);
        setSelectedNode(selectedNode);
      } else if (selectedIds.length > 1) {
        console.log("ㅇㅇ");
        setSelectedNode("그룹");
      } else {
        setSelectedNode(null);
      }

      // 셀렉션 ..
      console.log("selectedIds", selectedIds);
      console.log("selectedNode", selectedNode);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (selectedIds.length > 0) {
      console.log("StageRef.current", StageRef.current);
      const selectedNodes = selectedIds
        .map((id) => StageRef.current.findOne("#" + id))
        .filter((node) => node !== undefined);
      trRef.current.nodes(selectedNodes);
      trRef.current.getLayer().batchDraw();
    } else {
      trRef.current.nodes([]);
    }
  }, [selectedIds]);

  const handleDragMove = (e, id) => {
    console.log(e);
    const { x, y } = e.target.position();
    setObjects(
      objects.map((shape) => {
        if (shape.id === id) {
          const newX = Math.max(
            rectX,
            Math.min(x, rectX + rectWidth - shape.radius * 2)
          );
          const newY = Math.max(
            rectY,
            Math.min(y, rectY + rectHeight - shape.radius * 2)
          );
          return { ...shape, x: newX, y: newY };
        }
        return shape;
      })
    );
  };

  const functions = {
    saveData: () => {
      console.log(functions.historySave());
      return functions.historySave();
    },

    savePDF: () => {
      const stage = StageRef.current;
      const background = backgroundRef.current;
      if (!background) {
        console.error("Background not found");
        return;
      }

      const scale = stage.scaleX();
      const stagePos = stage.position();
      const bgPos = background.absolutePosition();
      const bgWidthUnit = background.width() / paperWidth;
      const bgHeightUnit = background.height() / paperHeight;
      console.log("bgWidthUnit", bgWidthUnit);
      console.log("bgHeightUnit", bgHeightUnit);
      const bgWidth = bgWidthUnit * (paperWidth + 50);
      const bgHeight = bgHeightUnit * (paperHeight + 50);

      // 픽셀을 밀리미터로 변환 (대략적인 변환, 72dpi 기준)
      const pxToMm = 0.264583333;
      const bgWidthMm = bgWidth * pxToMm;
      const bgHeightMm = bgHeight * pxToMm;

      // PDF 크기를 background 크기에 맞춥니다 (밀리미터 단위).
      const pdf = new jsPDF("l", "mm", [bgWidthMm, bgHeightMm]);

      // 전체 스테이지 이미지 추가 (background 영역만)
      const dataURL = stage.toDataURL({
        x: bgPos.x,
        y: bgPos.y,
        width: bgWidth,
        height: bgHeight,
        pixelRatio: 2, // 고품질 이미지를 위해 pixelRatio를 2로 설정
      });

      // 이미지를 PDF에 추가 (크기를 mm 단위로 지정)
      pdf.addImage(dataURL, "PNG", 0, 0, bgWidthMm, bgHeightMm);

      // PDF 저장
      pdf.save("canvas.pdf");
    },
    zoomReset: () => {
      const scale = calculateScale(
        rectWidth,
        rectHeight,
        paperWidth,
        paperHeight
      );
      setCanvasScale(scale);
      StageRef.current.position({
        x: 0,
        y: 0,
      });
    },
    zoomIn: () => {
      setCanvasScale(canvasScale + 0.05);
    },
    zoomOut: () => {
      setCanvasScale(canvasScale - 0.05);
    },
    zIndexSetup: () => {
      layerRef.current.children
        ?.find(
          (child) =>
            child.attrs.className === "Background" ||
            child.attrs.className === "Bleed"
        )
        ?.setZIndex(0);

      layerRef.current.children
        ?.find(
          (child) =>
            child.attrs.className === "OutOfBackground" ||
            child.attrs.className === "OutOfBackground1" ||
            child.attrs.className === "OutOfBackground2" ||
            child.attrs.className === "OutOfBackground3" ||
            child.attrs.className === "OutOfBackground4"
        )
        ?.setZIndex(1000);
    },
    bringToFront: () => {
      if (selectedNode) {
        selectedNode.setZIndex(selectedNode.getZIndex() + 1);
        functions.zIndexSetup();
      }
    },
    sendToBack: () => {
      if (selectedNode) {
        if (selectedNode.getZIndex() > 2) {
          console.log("Dddddddd");
          selectedNode.setZIndex(selectedNode.getZIndex() - 1);
          functions.zIndexSetup();
        }
      }
    },
    changeBgColor: (color) => {
      layerRef.current.children.forEach((child) => {
        if (child.attrs.className === "Background") {
          child.setAttrs({ fill: color });
          functions.historySave();
        }
      });
    },
    addShape: (shape) => {
      let newShape = {};
      switch (shape) {
        case "Circle":
          newShape = {
            id: objects.length + Date.now(),
            type: "Circle",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            radius: 50,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Rect":
          newShape = {
            id: objects.length + Date.now(),
            type: "Rect",
            x: rectX + rectWidth / 2 - 50,
            y: rectY + rectHeight / 2 - 50,
            width: 100,
            height: 100,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Star":
          newShape = {
            id: objects.length + Date.now(),
            type: "Star",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            numPoints: 5,
            innerRadius: 20,
            outerRadius: 40,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Triangle":
          newShape = {
            id: objects.length + Date.now(),
            type: "Triangle",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2 + 11,
            sides: 3,
            radius: 50,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Ellipse":
          newShape = {
            id: objects.length + Date.now(),
            type: "Ellipse",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            width: 100,
            height: 50,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Pentagon":
          newShape = {
            id: objects.length + Date.now(),
            type: "RegularPolygon",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            sides: 5,
            radius: 50,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Hexagon":
          newShape = {
            id: objects.length + Date.now(),
            type: "RegularPolygon",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            sides: 6,
            radius: 50,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Line":
          newShape = {
            id: objects.length + Date.now(),
            type: "Line",
            points: [
              rectX + rectWidth / 2 - 50,
              rectY + rectHeight / 2,
              rectX + rectWidth / 2 + 50,
              rectY + rectHeight / 2,
            ],
            stroke: "rgba(127,127,127,1)",
            strokeWidth: 4,
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Arrow":
          newShape = {
            id: objects.length + Date.now(),
            type: "Arrow",
            points: [
              rectX + rectWidth / 2 - 50,
              rectY + rectHeight / 2,
              rectX + rectWidth / 2 + 50,
              rectY + rectHeight / 2,
            ],
            pointerLength: 10,
            pointerWidth: 10,
            stroke: "rgba(127,127,127,1)",
            strokeWidth: 4,
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Path":
          newShape = {
            id: objects.length + Date.now(),
            type: "Path",
            data: "M12.582,9.551C3.251,16.237,0.921,29.021,7.08,38.564l-2.36,1.689l4.893,2.262l4.893,2.262l-0.568-5.36l-0.567-5.359l-2.365,1.694c-4.657-7.375-2.83-17.185,4.352-22.33c7.451-5.338,17.817-3.625,23.156,3.824c5.337,7.449,3.625,17.813-3.821,23.152l2.857,3.988c9.617-6.893,11.827-20.277,4.935-29.896C35.591,4.87,22.204,2.658,12.582,9.551z",
            x: rectX + rectWidth / 2 - 25,
            y: rectY + rectHeight / 2 - 25,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Wedge":
          newShape = {
            id: objects.length + Date.now(),
            type: "Wedge",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            radius: 70,
            angle: 60,
            rotation: 0,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Arc":
          newShape = {
            id: objects.length + Date.now(),
            type: "Arc",
            x: rectX + rectWidth / 2 - 25,
            y: rectY + rectHeight / 2 - 25,
            innerRadius: 20,
            outerRadius: 70,
            angle: 60,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;

        case "Ring":
          newShape = {
            id: objects.length + Date.now(),
            type: "Ring",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            innerRadius: 30,
            outerRadius: 35,
            angle: 60,
            fill: "rgba(127,127,127,1)",
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        case "Heart":
          newShape = {
            id: objects.length + Date.now(),
            type: "Path",
            x: rectX + rectWidth / 2,
            y: rectY + rectHeight / 2,
            data: "M 0 200 C 0 150 50 50 200 50 C 350 50 400 150 400 200 C 400 300 300 400 200 500 C 100 400 0 300 0 200 Z",
            fill: "rgba(127,127,127,1)",
            scaleX: 0.25,
            scaleY: 0.25,
            offsetX: 200,
            offsetY: 250,
          };
          setObjects([...objects, newShape]);
          setSelectedIds([newShape.id]);
          break;
        default:
          break;
      }
    },
    addCircle: () => {
      const newShape = {
        id: objects.length + Date.now(),
        type: "Circle",
        x: rectX + rectWidth / 2,
        y: rectY + rectHeight / 2,
        radius: 30 + Math.random() * 20,
        fill: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      };
      setObjects([...objects, newShape]);
    },
    addStar: () => {
      const newShape = {
        id: objects.length + Date.now(),
        type: "Star",
        x: rectX + Math.random() * rectWidth,
        y: rectY + Math.random() * rectHeight,
        numPoints: 5,
        innerRadius: 20,
        outerRadius: 40,
        fill: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      };
      setObjects([...objects, newShape]);
    },
    addTriangle: () => {
      const newShape = {
        id: objects.length + Date.now(),
        type: "Triangle",
        x: rectX + Math.random() * rectWidth,
        y: rectY + Math.random() * rectHeight,
        sides: 3,
        radius: 30 + Math.random() * 20,
        fill: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      };
      setObjects([...objects, newShape]);
    },
    addText: (
      content,
      fontSize,
      fontFamily,
      fontStyle,
      textAlign,
      letterSpacing,
      lineHeight,
      color,
      opacity,
      x,
      y
    ) => {
      const newShape = {
        id: objects.length + Date.now(),
        type: "Text",
        text: content,
        x: rectX + rectWidth / 2 - fontSize * 1.375 + x,
        y: rectY + rectHeight / 2 - fontSize / 2 + y,
        fontSize: fontSize,
        fontFamily: fontFamily,
        fontStyle: fontStyle,
        textAlign: textAlign,
        letterSpacing: letterSpacing,
        lineHeight: lineHeight,
        fill: color,
        opacity: opacity,
      };
      setObjects([...objects, newShape]);
      setSelectedIds([newShape.id]);
    },
    editText: (id, content) => {
      setObjects(
        objects.map((shape) => {
          if (shape.id === id) {
            console.log("shape", shape);
            console.log("selectedNode", selectedNode);
            const textNode = new Konva.Text({
              text: content,
              fontSize: selectedNode.fontSize(),
              fontFamily: selectedNode.fontFamily(),
            });
            const newWidth = textNode.width() + selectedNode.fontSize();
            const newHeight = selectedNode.fontSize();
            return {
              ...shape,
              text: content,
              width: newWidth,
              height: newHeight,
            };
          }
          return shape;
        })
      );
    },
    addClone: (id) => {
      console.log("selectedNode", selectedNode);
      if (selectedNode && selectedNode !== "그룹") {
        const objs = [...objects];
        const className = selectedNode.className;
        if (className === "Circle") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Circle",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            radius: selectedNode.radius(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Text") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Text",
            text: selectedNode.text(),
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            fontFamily: selectedNode.fontFamily(),
            fontSize: selectedNode.fontSize(),
            fontStyle: selectedNode.fontStyle(),
            textDecoration: selectedNode.textDecoration(),
            align: selectedNode.align(),
            letterSpacing: selectedNode.letterSpacing(),
            lineHeight: selectedNode.lineHeight(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Star") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Star",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            opacity: selectedNode.opacity(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            fill: selectedNode.fill(),
            innerRadius: selectedNode.innerRadius(),
            numPoints: selectedNode.numPoints(),
            outerRadius: selectedNode.outerRadius(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);

          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Image") {
          console.log("이미지 개체 복사", selectedNode);
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Image",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            height: selectedNode.height(),
            image: selectedNode.image().src,
            opacity: selectedNode.opacity(),
            // imageId: selectedNode.(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Rect") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Rect",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "RegularPolygon") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "RegularPolygon",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            radius: selectedNode.radius(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            sides: selectedNode.sides(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Ellipse") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Ellipse",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Arrow") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Arrow",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            points: selectedNode.points(),
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            pointerLength: selectedNode.pointerLength(),
            pointerWidth: selectedNode.pointerWidth(),
            stroke: selectedNode.stroke(),
            strokeWidth: selectedNode.strokeWidth(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Path") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Path",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            data: selectedNode.data(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Wedge") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Wedge",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
            radius: selectedNode.radius(),
            angle: selectedNode.angle(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Ring") {
          const clonedObj = {
            id: objs.length + Date.now(),
            type: "Ring",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            stroke: selectedNode.stroke(),
            strokeWidth: selectedNode.strokeWidth(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
            innerRadius: selectedNode.innerRadius(),
            outerRadius: selectedNode.outerRadius(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        }
      }
    },
    deleteObject: () => {
      if (selectedIds.length > 0) {
        const updatedObjects = objects.filter(
          (obj) => !selectedIds.includes(obj.id)
        );
        setObjects(updatedObjects);

        // 이미지 객체 삭제 시 해당 이미지 배열에서도 제거
        const updatedImages = { ...images };
        selectedIds.forEach((id) => {
          const obj = objects.find((o) => o.id === id);
          if (obj && obj.type === "Image") {
            delete updatedImages[id];
          }
        });
        setImages(updatedImages);

        setSelectedIds([]);
      }
    },
    groupObjects: () => {
      if (selectedIds.length > 1) {
        const children = selectedIds.map((id) =>
          StageRef.current.findOne(`#${id}`)
        );

        children.map((child) => {
          if (child.nodeType === "Group") {
            return;
          }
        });
        console.log("children", children);

        const newGroup = {
          id: `group_${Date.now()}`,
          type: "group",
          children: children,
        };

        setGroups([...groups, newGroup]);
        // 선택된 객체들을 objects 배열에서 제거
        setObjects(objects.filter((obj) => !selectedIds.includes(obj.id)));
        setSelectedIds([]);
      }
    },
    unGroupObjects: () => {
      if (selectedIds.length === 1) {
        const selectedGroup = StageRef.current.findOne(`#${selectedIds[0]}`);
        console.log("selectedGroup", selectedGroup);
        if (selectedGroup && selectedGroup.nodeType === "Group") {
          const children = selectedGroup.getChildren();
          const absolutePosition = selectedGroup.absolutePosition();
          const ungroupedObjects = children.map((child) => ({
            id: `ungrouped_${Date.now()}_${Math.random()
              .toString(36)
              .substr(2, 9)}`,
            ...child.attrs,
            x: child.absolutePosition().x,
            y: child.absolutePosition().y,
            width: child.width(),
            height: child.height(),
            scaleX: child.scaleX() * selectedGroup.scaleX(),
            scaleY: child.scaleY() * selectedGroup.scaleY(),
            skewX: child.skewX() + selectedGroup.skewX(),
            skewY: child.skewY() + selectedGroup.skewY(),
            rotation: child.rotation() + selectedGroup.rotation(),
            draggable: true,
            type: child.getClassName(),
          }));

          console.log("ungroupedObjects", ungroupedObjects);

          setObjects([...objects, ...ungroupedObjects]);
          setGroups(groups.filter((group) => group.id !== selectedGroup.id()));
          selectedGroup.destroy();
          setSelectedIds([]);
        }
      }
    },
    getProjectData: () => {
      const saveObjects = [];
      const saveGroups = [];
      layerRef.current.children.map((child) => {
        console.log("child.attrs.type", child.attrs.type);
        if (
          child.attrs.className === "Background" ||
          child.attrs.className === "Bleed"
        )
          return;
        const saveObject = {
          ...child.attrs,
          id: child.id(),
          type: child.getClassName(),
          zIndex: child.zIndex(),
          x: child.x(),
          y: child.y(),
          width: child.width(),
          height: child.height(),
          scaleX: child.scaleX(),
          scaleY: child.scaleY(),
          skewX: child.skewX(),
          skewY: child.skewY(),
          rotation: child.rotation(),
        };
        if (child.getClassName() === "Group") {
          const groupObject = {
            id: child.id(),
            type: "group",
            x: child.x(),
            y: child.y(),
            width: child.width(),
            height: child.height(),
            scaleX: child.scaleX(),
            scaleY: child.scaleY(),
            skewX: child.skewX(),
            skewY: child.skewY(),
            rotation: child.rotation(),
            zIndex: child.zIndex(),

            children: child.getChildren().map((groupChild) => ({
              attrs: {
                ...groupChild.attrs,
                x: groupChild.x(),
                y: groupChild.y(),
                width: groupChild.width(),
                height: groupChild.height(),
                scaleX: groupChild.scaleX(),
                scaleY: groupChild.scaleY(),
                skewX: groupChild.skewX(),
                skewY: groupChild.skewY(),
                rotation: groupChild.rotation(),
                type: groupChild.getClassName(),
                zIndex: groupChild.zIndex(),
              },
              className: groupChild.getClassName(),
            })),
          };
          console.log("그룹의 오브젝트", groupObject);
          saveGroups.push(groupObject);
        } else {
          saveObjects.push(saveObject);
        }
      });

      console.log("saveObjects", saveObjects);
      console.log("saveGroups", saveGroups);
      console.log("이게 세이브됨", {
        objects: saveObjects,
        groups: saveGroups,
      });
      setSavePoint({
        objects: saveObjects,
        groups: saveGroups,
      });
    },
    loadProjectData: () => {
      // 저장된 프로젝트 데이터 유효성 검사
      if (!savePoint || !savePoint.objects || !savePoint.groups) {
        console.error("저장된 프로젝트 데이터가 없거나 유효하지 않습니다.");
        return;
      }

      console.log("savePoint", savePoint);

      // objects State 업데이트
      const updatedObjects = savePoint.objects.map((obj) => ({
        ...obj,
        x: obj.x + Math.random() * 0.00000000001,
        y: obj.y + Math.random() * 0.00000000001,
        scaleX: obj.scaleX + Math.random() * 0.00000000001,
        scaleY: obj.scaleY + Math.random() * 0.00000000001,
        skewX: obj.skewX + Math.random() * 0.00000000001,
        skewY: obj.skewY + Math.random() * 0.00000000001,
        rotation: obj.rotation + Math.random() * 0.00000000001,
        fill: obj.fill,
      }));

      // groups State 업데이트
      const updatedGroups = savePoint.groups.map((group) => ({
        ...group,
        x: group.x + Math.random() * 0.00000000001,
        y: group.y + Math.random() * 0.00000000001,
        children: group.children.map((child) => ({
          ...child,
          attrs: {
            ...child.attrs,
            x: child.attrs.x,
            y: child.attrs.y,
            scaleX: child.attrs.scaleX,
            scaleY: child.attrs.scaleY,
            skewX: child.attrs.skewX,
            skewY: child.attrs.skewY,
            rotation: child.attrs.rotation,
            fill: child.attrs.fill ? `${child.attrs.fill}` : child.attrs.fill,
          },
        })),
      }));

      // State 업데이트
      setObjects(updatedObjects);
      setGroups(updatedGroups);
      setRenderKey((prevKey) => prevKey + 1);
      setSelectedIds([]);
      console.log("업데이트된 객체들:", updatedObjects);
      console.log("업데이트된 그룹들:", updatedGroups);
    },
    //히스토리 세이브
    historySave: () => {
      const saveObjects = [];
      const saveGroups = [];

      const backgroundFill = backgroundRef.current.getFill();
      console.log("배경 색상", backgroundFill);

      layerRef.current.children.map((child) => {
        if (
          child.attrs.className === "Background" ||
          child.attrs.className === "Bleed" ||
          child.attrs.className === "OutOfBackground"
        )
          return;

        console.log("체크체크", child);

        let saveObject;
        if (child.className === "Image") {
          console.log("이미지 체크", child.attrs.image);
          saveObject = {
            ...child.attrs,
            id: child.id(),
            type: child.getClassName(),
            zIndex: child.zIndex(),
            x: child.x(),
            y: child.y(),
            width: child.width(),
            height: child.height(),
            image: child.image().src,
          };
        } else {
          saveObject = {
            ...child.attrs,
            id: child.id(),
            type: child.getClassName(),
            zIndex: child.zIndex(),
            x: child.x(),
            y: child.y(),
            width: child.width(),
            height: child.height(),
            scaleX: child.scaleX(),
            scaleY: child.scaleY(),
            skewX: child.skewX(),
            skewY: child.skewY(),
            rotation: child.rotation(),
          };
        }
        if (child.getClassName() === "Group") {
          const groupObject = {
            id: child.id(),
            type: "group",
            x: child.x(),
            y: child.y(),
            width: child.width(),
            height: child.height(),
            scaleX: child.scaleX(),
            scaleY: child.scaleY(),
            skewX: child.skewX(),
            skewY: child.skewY(),
            rotation: child.rotation(),
            zIndex: child.zIndex(),

            children: child.getChildren().map((groupChild) => ({
              attrs: {
                ...groupChild.attrs,
                x: groupChild.x(),
                y: groupChild.y(),
                width: groupChild.width(),
                height: groupChild.height(),
                scaleX: groupChild.scaleX(),
                scaleY: groupChild.scaleY(),
                skewX: groupChild.skewX(),
                skewY: groupChild.skewY(),
                rotation: groupChild.rotation(),
                type: groupChild.getClassName(),
                zIndex: groupChild.zIndex(),
              },
              className: groupChild.getClassName(),
            })),
          };
          console.log("그룹의 오브젝트", groupObject);
          saveGroups.push(groupObject);
        } else {
          saveObjects.push(saveObject);
        }
      });

      console.log("saveObjects", saveObjects);
      console.log("saveGroups", saveGroups);
      console.log("이게 세이브됨", {
        objects: saveObjects,
        groups: saveGroups,
        backgroundFill: backgroundFill,
      });

      const saveData = {
        objects: saveObjects,
        groups: saveGroups,
        backgroundFill: backgroundFill,
        images: images,
      };
      setHistory([...history, saveData]);
      setCurrentHistoryIndex(history.length);

      console.log("저장후 히스토리", history);
      console.log("저장후 현재 히스토리 인덱스", currentHistoryIndex);
      return saveData;
    },
    //히스토리 로드
    historyLoad: (data) => {
      // 저장된 프로젝트 데이터 유효성 검사
      if (currentHistoryIndex < 0) {
        console.error("저장된 프로젝트 데이터가 없거나 유효하지 않습니다.");
        return;
      }

      setObjects(data.objects);
      setGroups(data.groups);
      setRenderKey((prevKey) => prevKey + 1);
      setSelectedIds([]);
      backgroundRef.current.setFill(data.backgroundFill);
    },
    saveDataLoad: (data) => {
      setObjects(data.objects);
      setGroups(data.groups);
      setRenderKey((prevKey) => prevKey + 1);
      setSelectedIds([]);
      backgroundRef.current.setFill(data.backgroundFill);
    },
    undo: () => {
      if (currentHistoryIndex > 0) {
        console.log("언도");
        isHistoryAction.current = true;
        const prevState = history[currentHistoryIndex - 1];
        functions.historyLoad(prevState);
        setCurrentHistoryIndex((prevIndex) => prevIndex - 1);
      }
    },

    redo: () => {
      if (currentHistoryIndex < history.length - 1) {
        isHistoryAction.current = true;
        const nextState = history[currentHistoryIndex + 1];
        functions.historyLoad(nextState);
        setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
      }
    },
    addImage: (image, width, height) => {
      const newShape = {
        id: objects.length + Date.now(),
        type: "Image",
        x: rectX + rectWidth / 2 - (width ? width / 2 : 75),
        y: rectY + rectHeight / 2 - (height ? height / 2 : 75),
        width: width ? width : 150,
        height: height ? height : 150,
        image: image,
        zIndex: 1,
      };
      setObjects([...objects, newShape]);

      // id: 4,
      // type: "Image",
      // x: 500,
      // y: 500,
      // width: 100,
      // height: 100,
      // image: image1,
      // zIndex: 1,
    },
  };

  const handleDblClick = (e, obj) => {
    console.log("더블클릭 이벤트", obj);
    if (obj.type === "Text") {
      setTextEditMode(true);
      setTextEditObj(obj);
      setTextEditInput(obj.text);
      textEditRef?.current?.focus();
    }
  };

  const handleTransform = (e, obj) => {
    console.log("트랜스폼", e);
    console.log(e.target.className);
    const node = e.target;
    console.log(node?.attrs);

    if (node?.className === "Text") {
      node.setAttrs({
        width: node.width() * node.scaleX(),
        height: node.height() * node.scaleY(),
        scaleX: 1,
        scaleY: 1,
      });
    }
  };

  const handleDragBound = (pos, obj) => {
    let boundX, boundY;

    boundX = pos.x;
    boundY = pos.y;

    return { x: boundX, y: boundY };
  };

  const renderGroup = (group) => {
    console.log("group.children", group.children);
    console.log("group.children[0].attrs", {
      ...group.children[0].attrs,
    });
    console.log("type?? ", group.children[0].className);
    return (
      <Group
        key={group.id}
        id={group.id}
        x={group.x}
        y={group.y}
        draggable
        onClick={(e) => handleShapeClick(e, group.id)}
        onDragMove={handleDragMove2}
        onDragEnd={(e) => handleDragEnd(e, group.id)}
        zIndex={group.zIndex}
      >
        {group.children.map((child) =>
          renderObject({
            ...child.attrs,
            draggable: false,
            type: child.className,
          })
        )}
      </Group>
    );
  };

  // 렌더링 함수
  const renderObject = (obj) => {
    console.log("rednerObject", obj);
    console.log("rednerObject,type", obj.type);
    console.log("obj.id", obj.id);
    const commonProps = {
      key: obj.id,
      id: obj.id.toString(),
      x: obj.x,
      y: obj.y,
      width: obj.width,
      height: obj.height,
      fill: obj.fill,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      skewX: obj.skewX,
      skewY: obj.skewY,
      rotation: obj.rotation,
      fontFamily: obj.fontFamily,
      fontSize: obj.fontSize,
      fontStyle: obj.fontStyle,
      textDecoration: obj.textDecoration,
      align: obj.align,
      letterSpacing: obj.letterSpacing,
      lineHeight: obj.lineHeight,
      opacity: obj.opacity,
      innerRadius: obj.innerRadius,
      numPoints: obj.numPoints,
      outerRadius: obj.outerRadius,
      draggable: obj.draggable === undefined ? true : obj.draggable,
      zIndex: obj.zIndex,

      // onDragMove: (e) => {
      //   //handleDragMove(e, obj.id);
      // },
      // ondragend: (e) => {
      //   handleobjDragTransfom(e, obj.id);
      // },
      onDragMove: (e) => {
        handleDragMove2(e);
      },
      onDragEnd: (e) => handleDragEnd(e, obj.id),
      onClick: (e) => handleShapeClick(e, obj.id),
      onDblClick: (e) => handleDblClick(e, obj),
      // onMouseEnter: () => setHoveredId(obj.id),
      // onMouseLeave: () => setHoveredId(null),
      dragBoundFunc: (pos) => handleDragBound(pos, obj.id),
      onTransform: (e) => handleTransform(e, obj),
    };

    switch (obj.type) {
      case "Circle":
        console.log("싸이클 랜더링 radious", obj.radius);
        return (
          <Circle
            {...commonProps}
            radius={parseInt(obj.radius)}
            width={obj.radius}
            height={obj.radius}
          />
        );
      case "Rect":
        return <Rect {...commonProps} width={obj.width} height={obj.height} />;
      case "Star":
        return (
          <Star
            {...commonProps}
            numPoints={obj.numPoints}
            innerRadius={obj.innerRadius}
            outerRadius={obj.outerRadius}
          />
        );
      case "Triangle":
        return (
          <RegularPolygon
            {...commonProps}
            sides={obj.sides}
            radius={obj.radius}
          />
        );
      case "Text":
        return <Text {...commonProps} text={obj.text} />;
      case "Image":
        return images[obj.id] ? (
          <Image
            {...commonProps}
            image={images[obj.id]}
            width={obj.width}
            height={obj.height}
          />
        ) : null;
      case "Ellipse":
        return (
          <Ellipse {...commonProps} width={obj.width} height={obj.height} />
        );
      case "RegularPolygon":
        return (
          <RegularPolygon
            {...commonProps}
            sides={obj.sides}
            radius={obj.radius}
          />
        );
      case "Line":
        return (
          <Line
            {...commonProps}
            points={obj.points}
            stroke={obj.stroke}
            strokeWidth={obj.strokeWidth}
          />
        );
      case "Arrow":
        return (
          <Arrow
            {...commonProps}
            points={obj.points}
            stroke={obj.stroke}
            strokeWidth={obj.strokeWidth}
          />
        );
      case "Path":
        return <Path {...commonProps} data={obj.data} />;
      case "Wedge":
        return (
          <Wedge
            {...commonProps}
            radius={obj.radius}
            angle={obj.angle}
            rotation={obj.rotation}
          />
        );
      case "Arc":
        return (
          <Arc
            {...commonProps}
            innerRadius={obj.innerRadius}
            outerRadius={obj.outerRadius}
            angle={obj.angle}
          />
        );
      case "Ring":
        return (
          <Ring
            {...commonProps}
            innerRadius={obj.innerRadius}
            outerRadius={obj.outerRadius}
            angle={obj.angle}
          />
        );
      default:
        return null;
    }
  };

  const handleShapeClick = (e, id) => {
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;

    if (!metaPressed) {
      setSelectedIds([id]);
    } else {
      const index = selectedIds.indexOf(id);
      if (index === -1) {
        setSelectedIds([...selectedIds, id]);
      } else {
        const newSelectedIds = [...selectedIds];
        newSelectedIds.splice(index, 1);
        setSelectedIds(newSelectedIds);
      }
    }
  };
  const handleShapeDragTransfom = (e, id) => {
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;

    if (selectedIds.length > 1) {
      return;
    }
    if (!metaPressed) {
      setSelectedIds([id]);
    } else {
      const index = selectedIds.indexOf(id);
      if (index === -1) {
        setSelectedIds([...selectedIds, id]);
      } else {
        const newSelectedIds = [...selectedIds];
        newSelectedIds.splice(index, 1);
        setSelectedIds(newSelectedIds);
      }
    }
  };

  const handleStageMouseDown = (e) => {
    // 여기가 컬렉션 인식

    console.log(e.target);
    // setSelectedNode(e.target);

    if (!e.target._partialText) {
      setTextEditMode(false);
    }

    if (
      e.target === e.target.getStage() ||
      e.target.attrs.className === "Background" ||
      e.target.attrs.className === "OutOfBackground" ||
      e.target.attrs.className === "Bleed"
    ) {
      setSelectedIds([]);
      const pos = e.target.getStage().getPointerPosition();
      setSelectionRect({
        x: pos.x - StageRef.current.absolutePosition().x,
        y: pos.y - StageRef.current.absolutePosition().y,
        width: 0,
        height: 0,
      });
      setIsDragging(true);
    }
  };

  const handleStageMouseMove = (e) => {
    if (isDragging) {
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();

      setSelectionRect({
        ...selectionRect,
        width: pos.x - StageRef.current.absolutePosition().x - selectionRect.x,
        height: pos.y - StageRef.current.absolutePosition().y - selectionRect.y,
      });
    }
  };

  const handleStageMouseUp = () => {
    console.log("마우스업");
    functions.historySave();
    if (isDragging) {
      const stageAbsPos = StageRef.current.absolutePosition();
      const selectedobjects = objects.filter((shape) => {
        const shapeNode = layerRef.current.findOne(`#${shape.id}`);
        const shapeRect = shapeNode.getClientRect();
        const adjustedSelectionRect = {
          x: selectionRect.x + stageAbsPos.x,
          y: selectionRect.y + stageAbsPos.y,
          width: selectionRect.width,
          height: selectionRect.height,
        };
        return (
          Math.abs(shapeRect.x - adjustedSelectionRect.x) <
            Math.abs(adjustedSelectionRect.width) &&
          Math.abs(shapeRect.y - adjustedSelectionRect.y) <
            Math.abs(adjustedSelectionRect.height) &&
          Math.abs(shapeRect.x + shapeRect.width - adjustedSelectionRect.x) <
            Math.abs(adjustedSelectionRect.width) &&
          Math.abs(shapeRect.y + shapeRect.height - adjustedSelectionRect.y) <
            Math.abs(adjustedSelectionRect.height)
        );
      });

      setSelectedIds(selectedobjects.map((shape) => shape.id));
      setSelectionRect(null);
      setIsDragging(false);
    }
  };

  /////////// 스냅 그리드

  const GUIDELINE_OFFSET = 5;

  const getLineGuideStops = useCallback(
    (skipShape) => {
      const vertical = [0, rectX, rectX + rectWidth / 2, rectX + rectWidth];
      const horizontal = [0, rectY, rectY + rectHeight / 2, rectY + rectHeight];

      objects.forEach((shape) => {
        if (shape.id === skipShape) return;
        vertical.push(shape.x, shape.x + shape.radius * 2);
        horizontal.push(shape.y, shape.y + shape.radius * 2);
      });

      return { vertical, horizontal };
    },
    [objects, rectX, rectY, rectWidth, rectHeight]
  );

  const getObjectSnappingEdges = useCallback((node) => {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        { guide: box.x, offset: absPos.x - box.x, snap: "start" },
        {
          guide: box.x + box.width / 2,
          offset: absPos.x - box.x - box.width / 2,
          snap: "center",
        },
        {
          guide: box.x + box.width,
          offset: absPos.x - box.x - box.width,
          snap: "end",
        },
      ],
      horizontal: [
        { guide: box.y, offset: absPos.y - box.y, snap: "start" },
        {
          guide: box.y + box.height / 2,
          offset: absPos.y - box.y - box.height / 2,
          snap: "center",
        },
        {
          guide: box.y + box.height,
          offset: absPos.y - box.y - box.height,
          snap: "end",
        },
      ],
    };
  }, []);

  const getGuides = useCallback((lineGuideStops, itemBounds) => {
    const resultV = [];
    const resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });
    const guides = [];

    const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0];

    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: "V",
        snap: minV.snap,
      });
    }

    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: "H",
        snap: minH.snap,
      });
    }

    return guides;
  }, []);

  const drawGuides = useCallback((guides) => {
    guides.forEach((lg) => {
      if (lg.orientation === "H") {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: "rgb(0, 161, 255)",
          strokeWidth: 1,
          name: "guid-line",
          dash: [4, 6],
        });
        layerRef.current.add(line);
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide,
        });
      } else if (lg.orientation === "V") {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: "rgb(0, 161, 255)",
          strokeWidth: 1,
          name: "guid-line",
          dash: [4, 6],
        });
        layerRef.current.add(line);
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0,
        });
      }
    });
  }, []);

  const handleDragMove2 = useCallback(
    (e) => {
      if (trRef.current.nodes().length > 1) {
        return;
      }
      layerRef.current.find(".guid-line").forEach((l) => l.destroy());

      const lineGuideStops = getLineGuideStops(e.target.id());
      const itemBounds = getObjectSnappingEdges(e.target);
      const guides = getGuides(lineGuideStops, itemBounds);

      if (!guides.length) {
        return;
      }

      drawGuides(guides);

      const absPos = e.target.absolutePosition();
      guides.forEach((lg) => {
        switch (lg.orientation) {
          case "V":
            absPos.x = lg.lineGuide + lg.offset;
            break;
          case "H":
            absPos.y = lg.lineGuide + lg.offset;
            break;
        }
      });
      e.target.absolutePosition(absPos);
    },
    [getLineGuideStops, getObjectSnappingEdges, getGuides, drawGuides]
  );

  const handleDragEnd = useCallback((e, obj) => {
    console.log("handleDragEnd", selectedIds);

    //handleShapeDragTransfom(e, obj);
    layerRef.current.find(".guid-line").forEach((l) => l.destroy());
  }, []);

  return (
    <div ref={containerRef}>
      {/* <div
        onClick={() => {
          functions.savePDF();
        }}
      >
        ㅇㅇ
      </div> */}
      <Stage
        ref={StageRef}
        width={stageWidth}
        height={stageHeight}
        onMouseDown={handleStageMouseDown}
        onMouseMove={handleStageMouseMove}
        onMouseUp={handleStageMouseUp}
      >
        <Layer ref={layerRef}>
          <Rect
            listening={false}
            ref={backgroundRef}
            className="Background"
            x={rectX}
            y={rectY}
            width={rectWidth}
            height={rectHeight}
            fill="white"
            stroke="#777"
            strokeWidth={2}
            zIndex={0}
            index={0}
          />
          <Rect
            listening={false}
            // ref={backgroundRef}
            className="Bleed"
            x={rectX + (4 * 300) / 25.4 / 1.5 / 2}
            y={rectY + (4 * 300) / 25.4 / 1.5 / 2}
            width={rectWidth - (4 * 300) / 25.4 / 1.5}
            height={rectHeight - (4 * 300) / 25.4 / 1.5}
            fill="transparent"
            stroke="red"
            strokeWidth={1}
            dash={[5, 5]}
            zIndex={1}
          />

          <React.Fragment key={renderKey}>
            {objects.map(renderObject)}
            {groups.map(renderGroup)}
          </React.Fragment>
          <Group
            listening={false}
            draggable={false}
            className="OutOfBackground"
            zIndex={1000}
          >
            <Rect
              id="OutOfBackground1"
              className="OutOfBackground"
              x={-1920}
              y={-1700}
              width={stageWidth + 4500}
              height={rectY + 1700}
              fill={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
              // fill="red"
              strokeWidth={1}
              stroke={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
            />
            <Rect
              id="OutOfBackground2"
              className="OutOfBackground"
              x={-3000}
              y={rectY - 200}
              width={rectX + 3000}
              height={rectHeight + 2000}
              fill={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
              // fill="yellow"
              strokeWidth={1}
              stroke={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
            />
            <Rect
              id="OutOfBackground3"
              className="OutOfBackground"
              x={rectX + rectWidth}
              y={rectY - 200}
              width={rectX + rectWidth}
              height={rectHeight + 2000}
              fill={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
              // fill="green"
              strokeWidth={1}
              stroke={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
            />
            <Rect
              id="OutOfBackground4"
              className="OutOfBackground"
              x={-1920}
              y={rectY + rectHeight}
              width={stageWidth + 4500}
              height={stageHeight - (rectY + rectHeight) + 1000}
              fill={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
              // fill="blue"
              strokeWidth={1}
              stroke={
                isSelected ? "rgba(211, 211, 211, 1)" : "rgba(232, 232, 232, 1)"
              }
            />
          </Group>
        </Layer>
        <Layer listening={false} ref={layerRef2}></Layer>
        <Layer>
          <Transformer ref={trRef} />
          {selectionRect && (
            <Rect
              x={selectionRect.x}
              y={selectionRect.y}
              width={selectionRect.width}
              height={selectionRect.height}
              fill="rgba(0,0,255,0.2)"
            />
          )}
        </Layer>
      </Stage>
      {textEditMode && (
        <TextEditFormWrapper>
          <TextEditForm>
            <TextField
              ref={textEditRef}
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "white" }}
              value={textEditInput}
              autoFocus
              onChange={(e) => setTextEditInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setTextEditMode(false);
                  console.log(textEditObj.id);
                  functions.editText(textEditObj.id, textEditInput);
                }
              }}
            />
          </TextEditForm>
        </TextEditFormWrapper>
      )}
    </div>
  );
};

export default New_EditorKonva;

const TextEditFormWrapper = styled.div`
  position: absolute;
  left: calc(50% - 200px);
  top: 50px;
  width: 400px;
  height: 56px;
`;

const TextEditForm = styled.div`
  position: fixed;
  width: 400px;
`;

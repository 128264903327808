import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const Leaflet = ({ prod, SelectOptions, setSelectOptions }) => {
  const osiRef = useRef(null);
  const osiAndFoldingRef = useRef(null);
  const foldingRef = useRef(null);

  const [osiChecked, setOsiChecked] = useState(true);
  const [osiAndFoldingChecked, setOsiAndFoldingChecked] = useState(false);
  const [foldingChecked, setFoldingChecked] = useState(false);

  const [PaperSize, setPaperSize] = useState("210x297");
  const [PaperWidth, setPaperWidth] = useState(210);
  const [PaperHeight, setPaperHeight] = useState(297);
  const [DefaultPaper, setDefaultPaper] = useState("스노우");
  const [DefaultGram, setDefaultGram] = useState("250g");
  const [PrintMethod, setPrintMethod] = useState("양면");
  const [Quantity, setQuantity] = useState(10);

  const [step, setStep] = useState(1);

  const [progress, setProgress] = useState(false);
  //기본세팅
  useEffect(() => {
    setProgress(true);
    setTimeout(() => {
      setDefaultPaper("스노우");
      setDefaultGram("250g");
      setProgress(false);
    }, 666);
  }, []);

  useEffect(() => {
    if (PaperSize === "직접입력") {
      return;
    }
    setPaperWidth(parseInt(PaperSize.split("x")[0]));
    setPaperHeight(parseInt(PaperSize.split("x")[1]));
  }, [PaperSize]);

  const [papers, setPapers] = useState({});

  const getPriceData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/global/prices",
      {
        params: {
          PRICE_PROD_CATE: prod,
          PRICE_OPTION_CATE: "용지",
        },
      }
    );

    const papers_copy = {};

    res.data.forEach((item) => {
      const name = item.PRICE_OPTION_NM.match(/[가-힣a-zA-Z]+/)[0]; // 용지 이름 추출
      const weight = parseInt(item.PRICE_OPTION_NM.match(/\d+/)[0], 10); // 그람수 추출
      console.log(name);
      console.log(weight);

      if (!papers_copy[name]) {
        papers_copy[name] = [];
      }

      if (!papers_copy[name].includes(weight)) {
        papers_copy[name].push(weight);
      }
    });

    setPapers(papers_copy);
    setDefaultPaper(Object.keys(papers_copy)[0]);
  };

  const menuItems_papers = Object.keys(papers).map((key) => (
    <MenuItem
      sx={{ fontSize: "14px" }}
      value={key}
      key={key}
      className="paperItem"
    >
      {key}
    </MenuItem>
  ));

  useEffect(() => {
    getPriceData();
  }, []);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.리플릿.규격 = PaperSize;
    copyOptions.리플릿.가로 = parseInt(PaperWidth);
    copyOptions.리플릿.세로 = parseInt(PaperHeight);
    copyOptions.리플릿.용지 = DefaultPaper + DefaultGram;
    copyOptions.리플릿.인쇄 = PrintMethod;
    copyOptions.리플릿.수량 = parseInt(Quantity);
    setSelectOptions(copyOptions);
  }, [
    PaperSize,
    PaperWidth,
    PaperHeight,
    DefaultPaper,
    DefaultGram,
    Quantity,
    PrintMethod,
  ]);

  //오시 접지 초기화
  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    // 처음 동작 안되면 이후 동작 안되게
    if (step <= 1) {
      copyOptions.리플릿.오시 = true;
      setOsiChecked(true);
      setStep(step + 1);
      return;
    }

    copyOptions.리플릿.오시 = false;
    copyOptions.리플릿.오시and접지 = false;
    copyOptions.리플릿.접지 = false;

    setSelectOptions(copyOptions);

    setOsiChecked(false);
    setOsiAndFoldingChecked(false);
    setFoldingChecked(false);
  }, [DefaultGram]);

  const [DefaultCount, setDefaultCount] = useState(1);
  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.리플릿.건 = DefaultCount;
    setSelectOptions(copyOptions);
  }, [DefaultCount]);

  return (
    <Box>
      {progress && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "500vh",
            textAlign: "center",
            lineHeight: "800px",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{}}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          규격
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{ width: "98%", height: "40px", fontSize: "14px" }}
            fullWidth
            value={PaperSize}
            onChange={(e) => {
              setPaperSize(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={"297x420"}>
              A3 (297mm x 420mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"210x297"}>
              A4 (210mm x 297mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"148x210"}>
              A5 (148mm x 210mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"105x148"}>
              A6 (105mm x 148mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"257x364"}>
              B4 (257mm x 364mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"188x257"}>
              B5 (188mm x 257mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"127x188"}>
              B6 (127mm x 188mm)
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"직접입력"}>
              직접입력
            </MenuItem>
          </Select>
        </Box>
        {PaperSize === "직접입력" && (
          <Box
            sx={{
              marginTop: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              sx={{ width: "48%" }}
              label="가로"
              value={PaperWidth}
              onChange={(e) => {
                setPaperWidth(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value < 50) {
                  setPaperWidth(50);
                  return;
                }
                if (e.target.value > 420) {
                  setPaperWidth(420);
                  return;
                }
              }}
            />{" "}
            x{" "}
            <TextField
              size="small"
              sx={{ width: "48%" }}
              label="세로"
              value={PaperHeight}
              onChange={(e) => {
                setPaperHeight(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value < 50) {
                  setPaperHeight(50);
                  return;
                }
                if (e.target.value > 420) {
                  setPaperHeight(420);
                  return;
                }
              }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          용지
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultPaper}
            onChange={(e) => {
              setDefaultPaper(e.target.value);
              setDefaultGram(papers[e.target.value][0] + "g");
            }}
          >
            {menuItems_papers}
          </Select>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultGram}
            onChange={(e) => {
              setDefaultGram(e.target.value);
            }}
          >
            {papers[DefaultPaper]?.map((gram) => (
              <MenuItem sx={{ fontSize: "14px" }} value={gram + "g"}>
                {gram + "g"}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          인쇄방법
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={PrintMethod}
            onChange={(e) => {
              setPrintMethod(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={"양면"}>
              양면
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"단면"}>
              단면
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          수량
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <TextField
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            size="small"
            value={Quantity}
            onChange={(e) => {
              let num = e.target.value.replace(/[^0-9]/g, ""); // 숫자만 남기기
              if (parseInt(num) < 0) {
                num = "1"; // 빈 값이거나 0 이하일 경우 1로 설정
              }
              setQuantity(num);
            }}
            type="number"
          />
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultCount}
            onChange={(e) => {
              setDefaultCount(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              1건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={2}>
              2건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              3건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={4}>
              4건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={5}>
              5건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={6}>
              6건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={7}>
              7건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={8}>
              8건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={9}>
              9건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={10}>
              10건
            </MenuItem>
          </Select>
        </Box>
      </Box>

      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "12px",
            marginBottom: "12px",
          }}
        >
          후가공
        </InputLabel>

        <FormControlLabel
          value="코팅"
          control={
            <Checkbox size="small" checked={SelectOptions.리플릿.코팅} />
          }
          label="코팅"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
        {/* <FormControlLabel
          value="귀도리"
          control={
            <Checkbox size="small" checked={SelectOptions.리플릿.귀도리} />
          }
          label="귀도리"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        /> */}
        <FormControlLabel
          value="오시"
          control={
            <Checkbox
              ref={osiRef}
              size="small"
              disabled={
                parseInt(DefaultGram) < 150 ||
                SelectOptions.리플릿.오시and접지 === true
                  ? true
                  : false
              }
              checked={osiChecked}
            />
          }
          label="오시"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
            setOsiChecked(e.target.checked);
          }}
        />
        <FormControlLabel
          value="오시+접지"
          control={
            <Checkbox
              ref={osiAndFoldingRef}
              size="small"
              disabled={
                parseInt(DefaultGram) < 150 ||
                SelectOptions.리플릿.오시 === true
                  ? true
                  : false
              }
              checked={osiAndFoldingChecked}
            />
          }
          label="오시+접지"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿.오시and접지 = e.target.checked;
            setSelectOptions(copyOptions);
            setOsiAndFoldingChecked(e.target.checked);
          }}
        />
        <FormControlLabel
          value="접지"
          control={
            <Checkbox
              ref={foldingRef}
              size="small"
              disabled={parseInt(DefaultGram) >= 150 ? true : false}
              checked={foldingChecked}
            />
          }
          label="접지"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
            setFoldingChecked(e.target.checked);
          }}
        />
        <FormControlLabel
          value="미싱"
          control={
            <Checkbox size="small" checked={SelectOptions.리플릿.미싱} />
          }
          label="미싱"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        />
        {/* <FormControlLabel
          value="타공"
          control={
            <Checkbox size="small" checked={SelectOptions.리플릿.타공} />
          }
          label="타공"
          labelPlacement="top"
          classes={{ label: "custom-label" }}
          onChange={(e) => {
            const copyOptions = { ...SelectOptions };
            copyOptions.리플릿[e.target.value] = e.target.checked;
            setSelectOptions(copyOptions);
          }}
        /> */}
      </Box>
    </Box>
  );
};

export default Leaflet;

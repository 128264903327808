import { MenuItem } from "@mui/material";

export const menuItems_count = Array.from(
  { length: 50 },
  (_, index) => index + 1
).map((key) => (
  <MenuItem sx={{ fontSize: "14px" }} value={key} key={key}>
    {key}건
  </MenuItem>
));

import styled from "styled-components";

export const PanelItem = styled.div`
  width: 100%;
  height: 80px;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  white-space: pre;
  &.active {
    background-color: rgba(19, 124, 189, 0.2);
  }
  &:hover {
    background-color: rgba(19, 124, 189, 0.2);
  }
  & p {
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 14px !important;
    font-weight: 550;
    width: 55px;
  }
  & button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: none;
    }
  }
`;

export const NavBar = styled.div`
  display: flex;
  height: 100% !important;
  padding: 0px !important;
  position: relative;
  background-color: #ffffff;
`;

export const SideTabs = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 72px;
`;

export const SideInner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SideAdditionContainer = styled.div`
  width: 350px;
  height: 100% !important;
  padding: 10px 10px 0px 10px !important;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  position: relative;
  z-index: 10;
`;

export const SideAdditionInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SideAdditionTitle = styled.div`
  display: "block";
  position: relative;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const SideAdditionContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
`;

export const TextTable = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  margin-top: 20px;
  height: ${(props) => (props.height ? props.height : "90vh")};
`;

export const TextTableCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px; // 셀의 높이를 조정하세요
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 7.5px;

  &:hover {
    background-color: #dfdfdf;
  }

  img {
    width: 150px; // 이미지 크기를 조정하세요
    height: 150px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
`;

export const BgColorBox = styled.div`
  width: 100%;
  height: 100%;
  & p {
    margin-top: 20px;
    padding-left: 10px;
    font-size: 14px;
    color: #333;
  }
`;

export const BgColorPanel = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  padding: 20px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

export const BgColorPanelItem = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
    border: 1px solid #333;
  }
`;

export const BgColorPanelItem2 = styled.div`
  margin: 10px auto;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s;
  border: 1px solid #333;
  &:hover {
    transform: scale(1.1);
  }
`;

import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../hooks/Utill";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Cookies } from "react-cookie";
import { useQueryClient } from "react-query";

const AdminLoginPage = () => {
  const [code, setCode] = useState("");
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);

  const [id, setId] = useState("");

  const columns = [
    // { field: "id", headerName: "순번", width: 150 },
    {
      field: "USER_NM",
      headerName: "이름",
      width: 100,
    },
    {
      field: "USER_ID",
      headerName: "아이디",
      width: 150,
    },
    {
      field: "USER_TEL0",
      headerName: "전화번호",
      width: 150,
    },
  ];

  const [dataRows, setDataRows] = useState([]);

  const cookies = new Cookies();
  const queryClient = useQueryClient();

  const initdb = async () => {
    const res = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/user")
    ).data;
    setDataRows(res);
  };

  useEffect(() => {
    if (code === "1234") {
      setIsCodeCorrect(true);
      initdb();
    }
  }, [code]);

  const handleLogin = async () => {
    const res_login = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/login_admin",
      {
        userid: id,
      }
    );

    if (res_login.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.setItem("token", res_login.data.accessToken);
      localStorage.setItem("refreshToken", res_login.data.refreshToken);

      cookies.remove("token");
      cookies.remove("refreshToken");
      cookies.set("token", res_login.data.accessToken);
      cookies.set("refreshToken", res_login.data.refreshToken);
      queryClient.invalidateQueries("userinfo");
      queryClient.setQueryData("userinfo");

      alert(`${id} 로그인 성공`);
    } else {
      alert(`로그인 실패`);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>관리자 로그인</Typography>
        <Box
          sx={{
            mt: 3,
          }}
        >
          {isCodeCorrect ? (
            <>
              <TextField
                fullWidth
                label="아이디"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
              <Button
                sx={{ mt: 1 }}
                fullWidth
                variant="contained"
                onClick={() => {
                  handleLogin();
                }}
              >
                로그인
              </Button>
              <Box sx={{ height: 300, width: "100%", margin: "36px auto" }}>
                <DataGrid
                  rows={dataRows}
                  columns={columns}
                  onRowDoubleClick={(params) => {
                    setId(params.row.USER_ID);
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </>
          ) : (
            <TextField
              label="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminLoginPage;

export const PriceCalc = (
  priceTable,
  priceTable_global,
  prodNm,
  SelectOptions,
  optionList
) => {
  console.log("SelectOptions", SelectOptions);
  console.log("optionList", optionList);

  let print_price = 0;
  let option_price = 0;

  const CalcSalePrice = (price, qty) => {
    if (qty >= 0 && qty <= 9) {
      return price * 2;
    } else if (qty >= 10 && qty <= 49) {
      const discountRate = 1.99 - (qty - 10) * (0.49 / 39);
      return price * discountRate;
    } else if (qty >= 50 && qty <= 99) {
      const discountRate = 1.49 - (qty - 50) * (0.49 / 49);
      return price * discountRate;
    } else if (qty >= 100 && qty <= 199) {
      const discountRate = 1 - (qty - 100) * (0.09 / 50);
      return price * discountRate;
    } else if (qty >= 200 && qty <= 299) {
      const discountRate = 0.79 - (qty - 200) * (0.09 / 99);
      return price * discountRate;
    } else if (qty >= 300 && qty <= 499) {
      const discountRate = 0.69 - (qty - 300) * (0.09 / 199);
      return price * discountRate;
    } else if (qty >= 500 && qty <= 1000) {
      const discountRate = 0.59 - (qty - 500) * (0.09 / 500);
      return price * discountRate;
    } else {
      return price * 0.5;
    }
  };

  // CalcSale 함수의 결과를 1단위로 1000까지 로그 출력
  // for (let i = 1; i <= 1000; i++) {
  //   console.log(`수량 ${i}일 때 할인율: ${CalcSalePrice(100, i) / 100}`);
  // }

  let countPaper = 0;
  // ===일반지명함===
  if (prodNm === "일반지명함") {
    // 467 315 기준
    const Count = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", Count);
    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / Count);
    console.log("몇판 들어가나", paperCount);

    // 용지 테이블 불러오기
    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    // 용지 단가 계산
    const paper_unit_price = filterByQty(paperTable, paperCount)?.PRICE_PRICE;
    print_price = paper_unit_price * paperCount;

    // ★ 일반지 명함 - 코팅
    if (optionList[prodNm]?.코팅) {
      // 코팅 테이블 불러오기
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );
      //코팅 단가 계산
      const coating_unit_price = filterByQty(
        coatingTable,
        paperCount
      )?.PRICE_PRICE;

      //기본비용 단면 3000원, 양면 5000원
      const default_price = optionList[prodNm]?.코팅.includes("단면")
        ? 1000
        : optionList[prodNm]?.코팅.includes("양면")
        ? 1500
        : 0;

      if (coating_unit_price !== undefined) {
        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    // ★ 일반지 명함 - 귀도리
    if (SelectOptions[prodNm]?.귀도리) {
      // 귀도리 테이블 불러오기
      const roundTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "귀도리"
      );

      // 귀도리 단가 계산
      const round_unit_price = filterByQty(roundTable, paperCount)?.PRICE_PRICE;
      if (round_unit_price !== undefined) {
        // 최저 금액 1000원
        if (round_unit_price * paperCount < 1000) {
          option_price += 1000;
        } else {
          option_price += round_unit_price * paperCount;
        }
      }
    }

    // ★ 일반지 명함 - 오시
    if (SelectOptions[prodNm]?.오시) {
      // 오시 테이블 불러오기
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      // 오시 단가 계산
      const osi_unit_price = filterByQty(osiTable, paperCount)?.PRICE_PRICE;
      if (osi_unit_price !== undefined) {
        if (
          optionList[prodNm]?.오시 === "3줄" &&
          osi_unit_price * paperCount < 6000
        ) {
          option_price += 6000;
        } else if (
          optionList[prodNm]?.오시 !== "3줄" &&
          osi_unit_price * paperCount < 3000
        ) {
          option_price += 3000;
        } else {
          option_price += osi_unit_price * paperCount;
        }
      }
    }

    // ★ 일반지 명함 - 미싱
    if (SelectOptions[prodNm]?.미싱) {
      // 미싱 테이블 불러오기
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      // 미싱 단가 계산
      const missing_unit_price = filterByQty(
        missingTable,
        paperCount
      )?.PRICE_PRICE;

      if (missing_unit_price !== undefined) {
        if (
          optionList[prodNm]?.미싱 === "3줄" &&
          missing_unit_price * paperCount < 6000``
        ) {
          option_price += 6000;
        } else if (
          optionList[prodNm]?.미싱 !== "3줄" &&
          missing_unit_price * paperCount < 3000
        ) {
          option_price += 3000;
        } else {
          option_price += missing_unit_price * paperCount;
        }
      }
    }

    // ★ 일반지 명함 - 타공
    if (SelectOptions[prodNm]?.타공) {
      // 타공 테이블 불러오기
      const punchingTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "타공"
      );

      const punching_unit_price = filterByQty(
        punchingTable,
        paperCount
      )?.PRICE_PRICE;

      // 타공 단가 계산
      if (punching_unit_price !== undefined) {
        if (punching_unit_price * paperCount < 9000) {
          option_price += 9000;
        } else {
          option_price += punching_unit_price * paperCount;
        }
      }
    }

    print_price = CalcSalePrice(print_price, paperCount);
    option_price = CalcSalePrice(option_price, paperCount);
    countPaper = paperCount;
  }
  // ===일반지명함 끝===

  // ===고급지명함===
  if (prodNm === "고급지명함") {
    // 467 315 기준
    const Count = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / Count);

    console.log("한 판에 개수", Count);
    console.log("몇판 들어가나", paperCount);

    // 용지 테이블 불러오기
    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    console.log("용지 테이블", paperTable);

    // 용지 단가 계산
    const paper_unit_price = filterByQty(paperTable, paperCount)?.PRICE_PRICE;
    print_price = paper_unit_price * paperCount;

    // ★ 고급지명함 - 코팅
    // if (optionList[prodNm]?.코팅) {
    //   // 코팅 테이블 불러오기
    //   const coatingTable = priceTable.filter(
    //     (item) =>
    //       item.PRICE_OPTION_CATE === "코팅" &&
    //       item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
    //   );
    //   //코팅 단가 계산
    //   const coating_unit_price = filterByQty(
    //     coatingTable,
    //     paperCount
    //   )?.PRICE_PRICE;

    //   //기본비용 단면 3000원, 양면 5000원
    //   const default_price = optionList[prodNm]?.코팅.includes("단면")
    //     ? 3000
    //     : optionList[prodNm]?.코팅.includes("양면")
    //     ? 4000
    //     : 0;

    //   if (coating_unit_price !== undefined) {
    //     option_price += coating_unit_price * paperCount + default_price;
    //   }
    // }

    // ★ 고급지명함 - 귀도리
    if (SelectOptions[prodNm]?.귀도리) {
      // 귀도리 테이블 불러오기
      const roundTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "귀도리"
      );

      // 귀도리 단가 계산
      const round_unit_price = filterByQty(roundTable, paperCount)?.PRICE_PRICE;
      if (round_unit_price !== undefined) {
        // 최저 금액 1000원
        if (round_unit_price * paperCount < 1000) {
          option_price += 1000;
        } else {
          option_price += round_unit_price * paperCount;
        }
      }
    }

    // ★ 고급지명함 - 오시
    if (SelectOptions[prodNm]?.오시) {
      // 오시 테이블 불러오기
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      // 오시 단가 계산
      const osi_unit_price = filterByQty(osiTable, paperCount)?.PRICE_PRICE;
      if (osi_unit_price !== undefined) {
        if (
          optionList[prodNm]?.오시 === "3줄" &&
          osi_unit_price * paperCount < 6000
        ) {
          option_price += 6000;
        } else if (
          optionList[prodNm]?.오시 !== "3줄" &&
          osi_unit_price * paperCount < 3000
        ) {
          option_price += 3000;
        } else {
          option_price += osi_unit_price * paperCount;
        }
      }
    }

    // ★ 고급지명함 - 미싱
    if (SelectOptions[prodNm]?.미싱) {
      // 미싱 테이블 불러오기
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      // 미싱 단가 계산
      const missing_unit_price = filterByQty(
        missingTable,
        paperCount
      )?.PRICE_PRICE;

      if (missing_unit_price !== undefined) {
        if (
          optionList[prodNm]?.미싱 === "3줄" &&
          missing_unit_price * paperCount < 6000``
        ) {
          option_price += 6000;
        } else if (
          optionList[prodNm]?.미싱 !== "3줄" &&
          missing_unit_price * paperCount < 3000
        ) {
          option_price += 3000;
        } else {
          option_price += missing_unit_price * paperCount;
        }
      }
    }

    // ★ 고급지명함 - 타공
    if (SelectOptions[prodNm]?.타공) {
      // 타공 테이블 불러오기
      const punchingTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "타공"
      );

      const punching_unit_price = filterByQty(
        punchingTable,
        paperCount
      )?.PRICE_PRICE;

      // 타공 단가 계산
      if (punching_unit_price !== undefined) {
        if (punching_unit_price * paperCount < 9000) {
          option_price += 9000;
        } else {
          option_price += punching_unit_price * paperCount;
        }
      }
    }

    print_price = CalcSalePrice(print_price, paperCount);
    option_price = CalcSalePrice(option_price, paperCount);
    countPaper = paperCount;
  }
  // ===고급지명함 끝===

  // ===펄지명함===
  if (prodNm === "펄지명함") {
    // 용지 계산
    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    const paper_unit_price = filterByQty(
      paperTable,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    print_price = paper_unit_price * SelectOptions[prodNm]?.수량;

    // 귀도리 계산
    if (SelectOptions[prodNm]?.귀도리) {
      const roundTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "귀도리"
      );

      const round_unit_price = filterByQty(
        roundTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (round_unit_price !== undefined) {
        option_price += round_unit_price * SelectOptions[prodNm]?.수량;
      }
    }

    // 오시 계산
    if (SelectOptions[prodNm]?.오시) {
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      const osi_unit_price = filterByQty(
        osiTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (osi_unit_price !== undefined) {
        if (
          optionList[prodNm]?.오시 === "3줄" &&
          osi_unit_price * SelectOptions[prodNm]?.수량 < 11000
        ) {
          option_price += 11000;
        } else if (
          optionList[prodNm]?.오시 !== "3줄" &&
          osi_unit_price * SelectOptions[prodNm]?.수량 < 5000
        ) {
          option_price += 5000;
        } else {
          option_price += osi_unit_price * SelectOptions[prodNm]?.수량;
        }
      }
    }

    // 미싱 계산
    if (SelectOptions[prodNm]?.미싱) {
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      const missing_unit_price = filterByQty(
        missingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (missing_unit_price !== undefined) {
        if (
          optionList[prodNm]?.미싱 === "3줄" &&
          missing_unit_price * SelectOptions[prodNm]?.수량 < 7500
        ) {
          option_price += 7500;
        } else if (
          optionList[prodNm]?.미싱 !== "3줄" &&
          missing_unit_price * SelectOptions[prodNm]?.수량 < 6500
        ) {
          option_price += 6500;
        } else {
          option_price += missing_unit_price * SelectOptions[prodNm]?.수량;
        }
      }
    }

    // 타공 계산
    if (SelectOptions[prodNm]?.타공) {
      const punchingTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "타공"
      );

      const punching_unit_price = filterByQty(
        punchingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (punching_unit_price !== undefined) {
        if (punching_unit_price * SelectOptions[prodNm]?.수량 < 9000) {
          option_price += 9000;
        } else {
          option_price += punching_unit_price * SelectOptions[prodNm]?.수량;
        }
      }
    }
  }
  // ===펄지명함 끝===

  // ===전단지===
  if (prodNm === "전단지") {
    const Count = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", Count);
    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / Count);
    console.log("몇판 들어가나", paperCount);

    // 용지 계산
    const paperTable_global = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    console.log("용지 테이블 >>>", paperTable_global);

    const paper_unit_price = filterByQty(
      paperTable_global,
      paperCount
    )?.PRICE_PRICE;

    console.log("용지 1장당 기준 가격 >>>", paper_unit_price);

    print_price = paper_unit_price * paperCount;

    if (SelectOptions[prodNm]?.인쇄 === "양면") {
      print_price += paperCount * 100;
    } else if (SelectOptions[prodNm]?.인쇄 === "단면") {
      print_price += 50;
    }

    // 코팅 계산
    if (optionList[prodNm]?.코팅) {
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );

      const coating_unit_price = filterByQty(
        coatingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (coating_unit_price !== undefined) {
        let default_price = 0;
        if (optionList[prodNm]?.코팅.indexOf("단면") !== -1) {
          default_price = 3000;
        } else if (optionList[prodNm]?.코팅.indexOf("양면") !== -1) {
          default_price = 4000;
        }

        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    // 귀도리 계산
    if (SelectOptions[prodNm]?.귀도리) {
      const roundTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "귀도리"
      );

      console.log("귀도리 테이블 >>>", roundTable);

      const round_unit_price = filterByQty(roundTable, paperCount)?.PRICE_PRICE;

      console.log("귀도리 1장당 기준 가격 >>>", round_unit_price);
      if (round_unit_price !== undefined) {
        let default_price = 1000;

        option_price +=
          round_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 오시 계산
    if (SelectOptions[prodNm]?.오시) {
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      const osi_unit_price = filterByQty(
        osiTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.오시.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (osi_unit_price !== undefined) {
        option_price +=
          osi_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 미싱 계산
    if (SelectOptions[prodNm]?.미싱) {
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      const missing_unit_price = filterByQty(
        missingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.미싱.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (missing_unit_price !== undefined) {
        option_price +=
          missing_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 타공 계산
    if (SelectOptions[prodNm]?.타공) {
      const punchingTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "타공"
      );

      const punching_unit_price = filterByQty(
        punchingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (punching_unit_price !== undefined) {
        let default_price = 4500;
        option_price +=
          punching_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }
    print_price = CalcSalePrice(print_price, paperCount);
    option_price = CalcSalePrice(option_price, paperCount);
    countPaper = paperCount;
    if (print_price < 3000) {
      print_price = 3000;
    }
  }
  // ===전단지 끝===

  // ===엽서===
  if (prodNm === "엽서") {
    const Count = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", Count);
    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / Count);
    console.log("몇판 들어가나", paperCount);

    // 용지 계산
    const paperTable_global = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    console.log("용지 테이블 >>>", paperTable_global);

    const paper_unit_price = filterByQty(
      paperTable_global,
      paperCount
    )?.PRICE_PRICE;

    console.log("용지 1장당 기준 가격 >>>", paper_unit_price);

    print_price = paper_unit_price * paperCount;

    if (SelectOptions[prodNm]?.인쇄 === "양면") {
      print_price += paperCount * 100;
    } else if (SelectOptions[prodNm]?.인쇄 === "단면") {
      print_price += 50;
    }

    // 코팅 계산
    if (optionList[prodNm]?.코팅) {
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );

      const coating_unit_price = filterByQty(
        coatingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (coating_unit_price !== undefined) {
        let default_price = 0;
        if (optionList[prodNm]?.코팅.indexOf("단면") !== -1) {
          default_price = 3000;
        } else if (optionList[prodNm]?.코팅.indexOf("양면") !== -1) {
          default_price = 4000;
        }

        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    // 귀도리 계산
    if (SelectOptions[prodNm]?.귀도리) {
      const roundTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "귀도리"
      );

      console.log("귀도리 테이블 >>>", roundTable);

      const round_unit_price = filterByQty(roundTable, paperCount)?.PRICE_PRICE;

      console.log("귀도리 1장당 기준 가격 >>>", round_unit_price);
      if (round_unit_price !== undefined) {
        let default_price = 1000;

        option_price +=
          round_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 오시 계산
    if (SelectOptions[prodNm]?.오시) {
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      const osi_unit_price = filterByQty(
        osiTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.오시.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (osi_unit_price !== undefined) {
        option_price +=
          osi_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 미싱 계산
    if (SelectOptions[prodNm]?.미싱) {
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      const missing_unit_price = filterByQty(
        missingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.미싱.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (missing_unit_price !== undefined) {
        option_price +=
          missing_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 타공 계산
    if (SelectOptions[prodNm]?.타공) {
      const punchingTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "타공"
      );

      const punching_unit_price = filterByQty(
        punchingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (punching_unit_price !== undefined) {
        let default_price = 4500;
        option_price +=
          punching_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }
    print_price = CalcSalePrice(print_price, paperCount);
    option_price = CalcSalePrice(option_price, paperCount);
    countPaper = paperCount;
    if (print_price < 3000) {
      print_price = 3000;
    }
  }
  // ===엽서 끝===

  // ===포스터===
  if (prodNm === "포스터") {
    const Count = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", Count);
    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / Count);
    console.log("몇판 들어가나", paperCount);

    // 용지 계산
    const paperTable_global = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    console.log("용지 테이블 >>>", paperTable_global);

    const paper_unit_price = filterByQty(
      paperTable_global,
      paperCount
    )?.PRICE_PRICE;

    console.log("용지 1장당 기준 가격 >>>", paper_unit_price);

    print_price = paper_unit_price * paperCount;

    if (SelectOptions[prodNm]?.인쇄 === "양면") {
      print_price += paperCount * 100;
    } else if (SelectOptions[prodNm]?.인쇄 === "단면") {
      print_price += 50;
    }

    // 코팅 계산
    if (optionList[prodNm]?.코팅) {
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );

      const coating_unit_price = filterByQty(
        coatingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (coating_unit_price !== undefined) {
        let default_price = 0;
        if (optionList[prodNm]?.코팅.indexOf("단면") !== -1) {
          default_price = 3000;
        } else if (optionList[prodNm]?.코팅.indexOf("양면") !== -1) {
          default_price = 4000;
        }

        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    // 귀도리 계산
    if (SelectOptions[prodNm]?.귀도리) {
      const roundTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "귀도리"
      );

      console.log("귀도리 테이블 >>>", roundTable);

      const round_unit_price = filterByQty(roundTable, paperCount)?.PRICE_PRICE;

      console.log("귀도리 1장당 기준 가격 >>>", round_unit_price);
      if (round_unit_price !== undefined) {
        let default_price = 1000;

        option_price +=
          round_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 오시 계산
    if (SelectOptions[prodNm]?.오시) {
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      const osi_unit_price = filterByQty(
        osiTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.오시.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (osi_unit_price !== undefined) {
        option_price +=
          osi_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 미싱 계산
    if (SelectOptions[prodNm]?.미싱) {
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      const missing_unit_price = filterByQty(
        missingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.미싱.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (missing_unit_price !== undefined) {
        option_price +=
          missing_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 타공 계산
    if (SelectOptions[prodNm]?.타공) {
      const punchingTable = priceTable.filter(
        (item) => item.PRICE_OPTION_CATE === "타공"
      );

      const punching_unit_price = filterByQty(
        punchingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (punching_unit_price !== undefined) {
        let default_price = 4500;
        option_price +=
          punching_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }
    print_price = CalcSalePrice(print_price, paperCount);
    option_price = CalcSalePrice(option_price, paperCount);
    countPaper = paperCount;
    if (print_price < 3000) {
      print_price = 3000;
    }
  }
  // ===포스터 끝===

  // ===리플릿===
  if (prodNm === "리플릿") {
    const Count = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", Count);
    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / Count);
    console.log("몇판 들어가나", paperCount);

    // 용지 계산
    const paperTable_global = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    console.log("용지 테이블 >>>", paperTable_global);

    const paper_unit_price = filterByQty(
      paperTable_global,
      paperCount
    )?.PRICE_PRICE;

    console.log("용지 1장당 기준 가격 >>>", paper_unit_price);

    print_price = paper_unit_price * paperCount;

    if (SelectOptions[prodNm]?.인쇄 === "양면") {
      print_price += paperCount * 100;
    } else if (SelectOptions[prodNm]?.인쇄 === "단면") {
      print_price += 50;
    }

    // 코팅 계산
    if (optionList[prodNm]?.코팅) {
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );

      const coating_unit_price = filterByQty(
        coatingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (coating_unit_price !== undefined) {
        let default_price = 0;
        if (optionList[prodNm]?.코팅.indexOf("단면") !== -1) {
          default_price = 3000;
        } else if (optionList[prodNm]?.코팅.indexOf("양면") !== -1) {
          default_price = 4000;
        }

        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    // 오시 계산
    if (SelectOptions[prodNm]?.오시) {
      const osiTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시
      );

      const osi_unit_price = filterByQty(
        osiTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.오시.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (osi_unit_price !== undefined) {
        option_price +=
          osi_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 오시+접지 계산
    if (SelectOptions[prodNm]?.오시and접지) {
      const osi_jupTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "오시and접지" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.오시and접지
      );

      const osi_jup_unit_price = filterByQty(
        osi_jupTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (["2단접지", "3단접지"].includes(optionList[prodNm]?.오시and접지)) {
        default_price = 3000;
      } else {
        default_price = 5000;
      }

      if (osi_jup_unit_price !== undefined) {
        option_price +=
          osi_jup_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 접지 계산
    if (SelectOptions[prodNm]?.접지) {
      const jupTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "접지" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.접지
      );

      const jup_unit_price = filterByQty(
        jupTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (["2단접지", "3단접지"].includes(optionList[prodNm]?.접지)) {
        default_price = 3000;
      } else {
        default_price = 5000;
      }

      if (jup_unit_price !== undefined) {
        option_price +=
          jup_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }

    // 미싱 계산
    if (SelectOptions[prodNm]?.미싱) {
      const missingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "미싱" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.미싱
      );

      const missing_unit_price = filterByQty(
        missingTable,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      let default_price = 0;
      if (optionList[prodNm]?.미싱.indexOf("3줄") !== -1) {
        default_price = 5000;
      } else {
        default_price = 3000;
      }

      if (missing_unit_price !== undefined) {
        option_price +=
          missing_unit_price * SelectOptions[prodNm]?.수량 + default_price;
      }
    }
    print_price = CalcSalePrice(print_price, paperCount);
    option_price = CalcSalePrice(option_price, paperCount);
    countPaper = paperCount;
    if (print_price < 3000) {
      print_price = 3000;
    }
  }
  // ===리플릿 끝===

  // ===단행본===
  if (
    prodNm === "단행본" ||
    prodNm === "브로슈어" ||
    prodNm === "스프링노트" ||
    prodNm === "제안서" ||
    prodNm === "노트"
  ) {
    prodNm = "책자";

    const CoverPaperCount = SelectOptions[prodNm].수량;

    let InnerPer = calculateImposition(
      315,
      467,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", InnerPer);

    if (SelectOptions[prodNm]?.내지인쇄 === "양면") {
    } else if (SelectOptions[prodNm]?.내지인쇄 === "단면") {
      InnerPer = InnerPer / 1.5;
    }

    const InnerPaperCount = Math.ceil(
      (SelectOptions[prodNm].페이지 / InnerPer) * SelectOptions[prodNm].수량
    ); //책자는 무조건 두판걸이
    console.log("표지는 몇판 들어가나", CoverPaperCount);
    console.log("내지는 몇판 들어가나", InnerPaperCount);

    // 표지 계산

    console.log("테이블 >>>", priceTable);

    const CoverPaperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.표지
    );

    console.log("표지 선택 >>>", SelectOptions[prodNm]?.표지);
    console.log("표지 테이블 >>>", CoverPaperTable);

    const InnerPaperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.내지
    );

    let CoverPriceUnit = filterByQty(
      CoverPaperTable,
      CoverPaperCount
    )?.PRICE_PRICE;

    let InnerPriceUnit = filterByQty(
      InnerPaperTable,
      InnerPaperCount
    )?.PRICE_PRICE;

    console.log("표지 1장당 기준 가격 >>>", CoverPriceUnit);
    console.log("내지 1장당 기준 가격 >>>", InnerPriceUnit);

    if (SelectOptions[prodNm]?.표지인쇄 === "양면") {
      CoverPriceUnit += 50;
    } else if (SelectOptions[prodNm]?.표지인쇄 === "단면") {
      CoverPriceUnit += 25;
    }

    if (SelectOptions[prodNm]?.내지인쇄 === "양면") {
      InnerPriceUnit += 50;
    } else if (SelectOptions[prodNm]?.내지인쇄 === "단면") {
      InnerPriceUnit += 25;
    }

    console.log("표지 1장당 기준 가격 >>>", CoverPriceUnit);
    console.log("내지 1장당 기준 가격 >>>", InnerPriceUnit);

    print_price += CoverPriceUnit * CoverPaperCount;
    print_price += InnerPriceUnit * InnerPaperCount;

    //스프링 제본

    if (SelectOptions[prodNm]?.제본 === "스프링") {
      const BindingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "제본" &&
          item.PRICE_OPTION_NM === SelectOptions[prodNm]?.제본
      );
      console.log("제본 테이블 >>>", BindingTable);

      const BindingPriceUnit = filterByQty(BindingTable, 1)?.PRICE_PRICE;

      console.log("제본 1장당 기준 가격 >>>", BindingPriceUnit);

      option_price += BindingPriceUnit * SelectOptions[prodNm].수량;
    } else if (SelectOptions[prodNm]?.제본 === "무선") {
      option_price += 800 * SelectOptions[prodNm].수량;
    } else if (SelectOptions[prodNm]?.제본 === "중철") {
      option_price += 500 * SelectOptions[prodNm].수량;
    }

    print_price = CalcSalePrice(
      print_price,
      InnerPaperCount / 5 + CoverPaperCount
    );
    option_price = CalcSalePrice(
      option_price,
      InnerPaperCount / 5 + CoverPaperCount
    );
    if (print_price < 3000) {
      print_price = 3000;
    }

    // const coverAmt =
    //   cover_paper_price *
    //   multiple_1 *
    //   multiple_2 *
    //   4 *
    //   SelectOptions[prodNm]?.수량;

    // //내지 계산
    // const paperTable_global_inner = priceTable_global.filter(
    //   (item) =>
    //     item.PRICE_OPTION_CATE === "용지" &&
    //     item.PRICE_OPTION_NM === SelectOptions[prodNm]?.내지
    // );

    // const inner_paper_unit_price = filterByQty(
    //   paperTable_global_inner,
    //   SelectOptions[prodNm]?.수량
    // )?.PRICE_PRICE;

    // let inner_paper_price = inner_paper_unit_price / 4000;

    // if (SelectOptions[prodNm]?.내지인쇄 === "양면") {
    //   inner_paper_price += 100;
    // } else if (SelectOptions[prodNm]?.내지인쇄 === "단면") {
    //   inner_paper_price += 50;
    // }

    // let inner_paper_qty =
    //   SelectOptions[prodNm]?.내지인쇄 === "양면"
    //     ? SelectOptions[prodNm]?.페이지 / 2
    //     : SelectOptions[prodNm]?.페이지;

    // console.log("??>", inner_paper_qty);
    // const innerAmt =
    //   inner_paper_price *
    //   inner_paper_qty *
    //   multiple_1 *
    //   multiple_2 *
    //   SelectOptions[prodNm]?.수량;

    // console.log("내지 가격 >> ", innerAmt);
    // console.log("표지 가격 >> ", coverAmt);

    // print_price = innerAmt + coverAmt;

    // //제본 계산
    // if (SelectOptions[prodNm]?.제본 !== undefined) {
    //   const bindingTable = priceTable_global.filter(
    //     (item) =>
    //       item.PRICE_OPTION_CATE === "제본" &&
    //       item.PRICE_OPTION_NM === SelectOptions[prodNm]?.제본
    //   );

    //   const binding_unit_price = filterByQty(
    //     bindingTable,
    //     SelectOptions[prodNm]?.수량
    //   )?.PRICE_PRICE;

    //   option_price += binding_unit_price * SelectOptions[prodNm]?.수량;
    // }

    // 코팅 계산
    // if (optionList[prodNm]?.표지코팅) {
    //   const coatingTable = priceTable.filter(
    //     (item) =>
    //       item.PRICE_OPTION_CATE === "표지코팅" &&
    //       item.PRICE_OPTION_NM === "표지코팅"
    //   );

    //   const coating_unit_price = filterByQty(
    //     coatingTable,
    //     SelectOptions[prodNm]?.수량
    //   )?.PRICE_PRICE;

    //   if (coating_unit_price !== undefined) {
    //     option_price += coating_unit_price * SelectOptions[prodNm]?.수량;
    //   }
    // }

    // // 박
    // if (SelectOptions[prodNm].박) {
    //   option_price += 150000;
    // }
    // if (SelectOptions[prodNm].형압) {
    //   option_price += 150000;
    // }
    // if (SelectOptions[prodNm].부분코팅) {
    //   option_price += 200000;
    // }
  }
  // ===단행본 끝===

  // ===X배너===
  if (prodNm === "X배너") {
    let Xbanner_Material =
      SelectOptions[prodNm]?.규격 === "600x1800"
        ? "일반"
        : SelectOptions[prodNm]?.규격 === "500x720"
        ? "미니"
        : "비규격";

    //일반일 경우 소재까지 선택
    if (Xbanner_Material === "일반") {
      Xbanner_Material += SelectOptions[prodNm]?.소재;
    }

    const Standard_Price_Table = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "소재" &&
        item.PRICE_OPTION_NM === Xbanner_Material
    );

    // 일반이나 미니일 경우 1개당 가격
    // 비규격일 경우 1회배당 가격
    const Standard_Price = filterByQty(
      Standard_Price_Table,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    if (Xbanner_Material === "비규격") {
      let Imposition =
        (SelectOptions[prodNm]?.가로 * SelectOptions[prodNm]?.세로) / 1000000;

      print_price += Standard_Price * Imposition * SelectOptions[prodNm]?.수량;
    } else {
      print_price += Standard_Price * SelectOptions[prodNm]?.수량;
    }

    //후가공
    if (SelectOptions[prodNm]?.후가공) {
      const Finishing_Price_Table = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "후가공" &&
          item.PRICE_OPTION_NM === SelectOptions[prodNm]?.후가공
      );

      const Finishing_Price = filterByQty(
        Finishing_Price_Table,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (typeof Finishing_Price === "number") {
        option_price += Finishing_Price * SelectOptions[prodNm]?.수량;
      }
    }

    //열재단
    if (SelectOptions[prodNm]?.열재단) {
      const Fire_Price_Table = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "열재단" &&
          item.PRICE_OPTION_NM === SelectOptions[prodNm]?.열재단
      );

      const Fire_Price = filterByQty(
        Fire_Price_Table,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (typeof Fire_Price === "number") {
        option_price += Fire_Price * SelectOptions[prodNm]?.수량;
      }
    }

    //거치대
    if (SelectOptions[prodNm]?.거치대) {
      const Holder_Price_Table = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "거치대" &&
          item.PRICE_OPTION_NM === SelectOptions[prodNm]?.거치대
      );

      const Holder_Price = filterByQty(
        Holder_Price_Table,
        SelectOptions[prodNm]?.거치대수량
      )?.PRICE_PRICE;

      if (typeof Holder_Price === "number") {
        option_price += Holder_Price * SelectOptions[prodNm]?.거치대수량;
      }
    }
  }
  // ===X배너 끝===

  // ===배너===
  if (prodNm === "현수막") {
    let Imposition =
      (SelectOptions[prodNm]?.가로 * SelectOptions[prodNm]?.세로) / 1000000;

    let Banner_Material = SelectOptions[prodNm]?.소재;

    if (Imposition >= 4 && Banner_Material === "일반현수막") {
      Banner_Material = "일반현수막4회배";
    }

    const Standard_Price_Table = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "소재" &&
        item.PRICE_OPTION_NM === Banner_Material
    );

    const Base_Price = filterByQty(
      Standard_Price_Table,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    const Standard_Price = filterByQty(
      Standard_Price_Table,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    if (Imposition < 1) {
      if (
        ["일반현수막", "켈", "켈그레이"].includes(SelectOptions[prodNm]?.소재)
      ) {
        print_price +=
          (Base_Price + 2000) * Imposition * SelectOptions[prodNm]?.수량;
      } else {
        print_price +=
          (Base_Price + 3000) * Imposition * SelectOptions[prodNm]?.수량;
      }
    } else {
      print_price += Standard_Price * Imposition * SelectOptions[prodNm]?.수량;
    }

    //후가공
    if (SelectOptions[prodNm]?.후가공) {
      const Finishing_Price_Table = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "후가공" &&
          item.PRICE_OPTION_NM === SelectOptions[prodNm]?.후가공
      );

      const Finishing_Price = filterByQty(
        Finishing_Price_Table,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (typeof Finishing_Price === "number") {
        option_price += Finishing_Price * SelectOptions[prodNm]?.수량;
      }
    }

    //열재단
    if (SelectOptions[prodNm]?.열재단) {
      const Fire_Price_Table = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "열재단" &&
          item.PRICE_OPTION_NM === SelectOptions[prodNm]?.열재단
      );

      const Fire_Price = filterByQty(
        Fire_Price_Table,
        SelectOptions[prodNm]?.수량
      )?.PRICE_PRICE;

      if (typeof Fire_Price === "number") {
        option_price += Fire_Price * SelectOptions[prodNm]?.수량;
      }
    }
  }
  // ===배너 끝===

  // ===대봉투===
  if (prodNm === "대봉투") {
    // 용지 계산
    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    const paper_unit_price = filterByQty(
      paperTable,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    // 양면 테이프
    const TapeTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "양면테이프" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.양면테이프
    );

    const Tape_Price = filterByQty(
      TapeTable,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    if (typeof Tape_Price === "number") {
      option_price += Tape_Price * SelectOptions[prodNm]?.수량;
    }

    print_price = paper_unit_price * SelectOptions[prodNm]?.수량;
  }
  // ===대봉투 끝===

  // ===소봉투===
  if (prodNm === "소봉투") {
    // 용지 계산
    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    const paper_unit_price = filterByQty(
      paperTable,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    // 양면 테이프
    const TapeTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "양면테이프" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.양면테이프
    );

    const Tape_Price = filterByQty(
      TapeTable,
      SelectOptions[prodNm]?.수량
    )?.PRICE_PRICE;

    if (typeof Tape_Price === "number") {
      option_price += Tape_Price * SelectOptions[prodNm]?.수량;
    }

    print_price = paper_unit_price * SelectOptions[prodNm]?.수량;
  }
  // ===소봉투 끝===

  // ===낱장스티커===
  if (prodNm === "낱장스티커") {
    // 용지 계산

    console.log("낱장스티커", SelectOptions[prodNm]);

    console.log(SelectOptions[prodNm].가로);
    console.log(SelectOptions[prodNm].세로);
    console.log(SelectOptions[prodNm].수량);

    const stickerCount = calculateImposition(
      425,
      280,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", stickerCount);

    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / stickerCount);

    console.log("몇판 들어가나", paperCount);

    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    // 용지 단가 계산
    const paper_unit_price = filterByQty(paperTable, paperCount)?.PRICE_PRICE;

    if (SelectOptions[prodNm].모양따기 === "반칼") {
      //반칼 판당 100
      print_price += 100 * paperCount;
    } else {
      //완칼 판당 500
      print_price += 500 * paperCount;
    }

    // 코팅 계산
    if (optionList[prodNm]?.코팅) {
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );

      const coating_unit_price = filterByQty(
        coatingTable,
        paperCount
      )?.PRICE_PRICE;

      if (typeof coating_unit_price === "number") {
        let default_price = 3000;
        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    print_price += paper_unit_price * paperCount;
    // print_price += 75 * SelectOptions[prodNm]?.수량;
    print_price = CalcSalePrice(print_price, paperCount);
    if (print_price < 3000) {
      print_price = 3000;
    }
  }
  // ===낱장스티커 끝===

  // ===DIY스티커===
  if (prodNm === "DIY스티커") {
    // 용지 계산

    console.log("낱장스티커", SelectOptions[prodNm]);

    console.log(SelectOptions[prodNm].가로);
    console.log(SelectOptions[prodNm].세로);
    console.log(SelectOptions[prodNm].수량);

    const stickerCount = calculateImposition(
      425,
      280,
      SelectOptions[prodNm].가로,
      SelectOptions[prodNm].세로
    );

    console.log("한 판에 개수", stickerCount);

    const paperCount = Math.ceil(SelectOptions[prodNm].수량 / stickerCount);

    console.log("몇판 들어가나", paperCount);

    const paperTable = priceTable.filter(
      (item) =>
        item.PRICE_OPTION_CATE === "용지" &&
        item.PRICE_OPTION_NM === SelectOptions[prodNm]?.용지
    );

    // 용지 단가 계산
    const paper_unit_price = filterByQty(paperTable, paperCount)?.PRICE_PRICE;

    if (SelectOptions[prodNm].모양따기 === "반칼") {
      //반칼 판당 100
      print_price += 100 * paperCount;
    }

    // 코팅 계산
    if (optionList[prodNm]?.코팅) {
      const coatingTable = priceTable.filter(
        (item) =>
          item.PRICE_OPTION_CATE === "코팅" &&
          item.PRICE_OPTION_NM === optionList[prodNm]?.코팅
      );

      const coating_unit_price = filterByQty(
        coatingTable,
        paperCount
      )?.PRICE_PRICE;

      if (typeof coating_unit_price === "number") {
        let default_price = 3000;
        option_price += coating_unit_price * paperCount + default_price;
      }
    }

    print_price += paper_unit_price * paperCount;
    print_price = CalcSalePrice(print_price, paperCount);
    if (print_price < 3000) {
      print_price = 3000;
    }
  }
  // ===DIY스티커 끝===

  //최종 단가
  // const final_price = {
  //   print: print_price,
  //   option: option_price,
  // };

  //건당 가격 인하
  let countSale = 1;
  const count = SelectOptions[prodNm]?.건;
  if (countPaper > 5) {
    if (count === 1) {
      countSale = 1;
    } else if (count > 1 && count <= 3) {
      countSale = 0.9;
    } else if (count > 3 && count <= 6) {
      countSale = 0.8;
    } else if (count > 6 && count <= 8) {
      countSale = 0.75;
    } else if (count > 8) {
      countSale = 0.7;
    }
  }

  const final_price = {
    print: print_price * SelectOptions[prodNm]?.건 * countSale,
    option: option_price * SelectOptions[prodNm]?.건 * countSale,
  };

  //

  return final_price;
};

function filterByQty(data, qty) {
  // qty 이하의 PRICE_QTY 값을 필터링하여 리스트로 만듦
  const filteredData = data.filter((item) => item.PRICE_QTY <= qty);

  // 필터링된 리스트가 비어있지 않다면 가장 큰 PRICE_QTY 값을 가진 항목을 반환
  if (filteredData.length > 0) {
    return filteredData.reduce((max, item) =>
      item.PRICE_QTY > max.PRICE_QTY ? item : max
    );
  } else {
    return null; // 적절한 데이터가 없는 경우 처리 방법
  }
}

// 하리꼬미 갯수 계산 함수
const calculateImposition = (
  paperWidth,
  paperHeight,
  objectWidth,
  objectHeight,
  margin = 2
) => {
  const usableWidth = paperWidth - 2 * margin;
  const usableHeight = paperHeight - 2 * margin;

  objectWidth += 4;
  objectHeight += 4;

  const horizontalCount = Math.floor(usableWidth / objectWidth);
  const verticalCount = Math.floor(usableHeight / objectHeight);

  const horizontalCount2 = Math.floor(usableWidth / objectHeight);
  const verticalCount2 = Math.floor(usableHeight / objectWidth);

  const result1 = horizontalCount * verticalCount;
  const result2 = horizontalCount2 * verticalCount2;

  if (result1 === 0 && result2 === 0) {
    return 0.225;
  }

  return result1 > result2 ? result1 : result2;
};

import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import * as S from "../../styles/new_styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatNumber } from "../../hooks/Utill";
import {
  ArrowLeft,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Pageview,
  Payment,
  Payments,
  ShoppingCart,
  ShoppingCartCheckoutSharp,
  UploadFile,
  UploadFileOutlined,
} from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";

const ProdPrice = ({
  USER_ID,
  openPopup,
  setSnackbar,
  designFile,
  setDesignFile,
  designFileName,
  setDesignFileName,
  designCheck,
  setDesignCheck,
  handleSendCart,
  handleSendOrder,
  handleSendDesign,
  imgUrl,
  printPrice,
  optionPrice,
  taxPrice,
  prod,
  progress,
  setProgress,
}) => {
  const navigate = useNavigate();

  const handleUploadDesign = () => {
    if (USER_ID === undefined) {
      openPopup(0);

      return false;
    }
    setProgress(true);

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".ai,.pdf,.zip,.jpg,.jpeg,.png");
    input.click();

    input.addEventListener("cancel", () => {
      setProgress(false);
      // 여기서 추가 동작을 수행할 수 있습니다.
    });
    input.addEventListener("change", async () => {
      const file = input.files[0];

      //체크
      const allowedExtensions = [
        ".ai",
        ".pdf",
        ".zip",
        ".jpg",
        ".jpeg",
        ".png",
      ];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setSnackbar({
          children: "올바른 파일 형식을 선택해주세요.",
          severity: "error",
        });
        setProgress(false);
        return;
      }

      const formData = new FormData();

      formData.append("file", file);
      formData.append("filename", file.name);
      formData.append("type", "design");
      formData.append("userid", USER_ID);
      try {
        const result = await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/upload_design",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const designUrl = result.data;

        setTimeout(() => {
          setProgress(false);
          setSnackbar({
            children: "파일이 업로드 되었습니다.",
            severity: "success",
          });
          setDesignFile(designUrl);
          setDesignFileName(file.name);
          setDesignCheck(true);
        }, 1000);
      } catch (error) {
        console.log("실패");
        setProgress(false);
      }
    });
  };

  const [boxVisible, setBoxVisible] = useState(true);

  const isPc = useMediaQuery({ query: "(min-width:768px)" });

  return (
    <>
      {isPc ? (
        <>
          <Box
            sx={{
              position: "fixed",
              top: "414px",
              right: boxVisible ? "359px" : "0px",
              zIndex: "1002",
              width: "36px",
              height: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              borderLeft: "1px solid #ddd",
              borderRight: "1px solid #fff",
              cursor: "pointer",
              transition: "right 0.5s",
            }}
            onClick={() => {
              setBoxVisible(!boxVisible);
            }}
          >
            {boxVisible ? (
              <KeyboardArrowRight
                color="primary"
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
            ) : (
              <KeyboardArrowLeft
                color="primary"
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
            )}
          </Box>
          <Box
            className="orderBtn"
            sx={{
              position: "fixed",
              right: boxVisible ? "0" : "-360px",
              top: "81px",
              border: "1px solid #ddd",
              zIndex: "1000",
              width: "360px",
              height: "200vh",
              backgroundColor: "white",
              transition: "right 0.5s",
            }}
          >
            {/* 가격 */}
            <Box>
              <Box
                sx={{
                  width: "100%",
                  height: "260px",
                  padding: "12px 0",
                  marginBottom: "24px",
                  background: `center url(${imgUrl}) no-repeat`,
                  backgroundSize: "cover",
                }}
              ></Box>
              <Typography
                sx={{
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  paddingBottom: "24px",
                  fontWeight: "530",
                }}
              >
                견적서
              </Typography>
              <Table sx={{ marginBottom: "24px" }} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>인쇄비</TableCell>
                    <TableCell sx={{ border: "none" }} align="right">
                      {formatNumber(printPrice)}원
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>후가공</TableCell>
                    <TableCell sx={{ border: "none" }} align="right">
                      {formatNumber(optionPrice)}원
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>부가세</TableCell>
                    <TableCell sx={{ border: "none" }} align="right">
                      {formatNumber(taxPrice)}원
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "" }}>총합</TableCell>
                    <TableCell sx={{ border: "" }} align="right">
                      {formatNumber(printPrice + optionPrice + taxPrice)}원
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <S.ProdDetailDesignBtns>
              <S.Btn
                width="200px"
                style={{ margin: "0 12px", height: "50px" }}
                onClick={() => {
                  if (
                    [
                      "일반지명함",
                      "고급지명함",
                      "펄지명함",
                      "전단지",
                      "엽서",
                      "포스터",
                      "리플릿",
                    ].includes(prod)
                  ) {
                    handleSendDesign();
                  } else {
                    setSnackbar({
                      children:
                        "현재 이 제품은 셀프 디자인 서비스 준비 중입니다😂",
                      severity: "info",
                    });
                  }
                }}
              >
                직접 디자인하기
              </S.Btn>
              <S.Btn
                width="200px"
                style={{ margin: "0 12px", height: "50px" }}
                onClick={handleUploadDesign}
              >
                파일 업로드
              </S.Btn>
            </S.ProdDetailDesignBtns>
            {designCheck && (
              <>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, 0.664)",
                  }}
                >
                  {designFileName}
                </Typography>
                <S.ProdDetailDesignBtns>
                  <S.Btn
                    style={{ margin: "0 12px", height: "50px", width: "200px" }}
                    onClick={() => {
                      window.open(designFile);
                    }}
                  >
                    업로드 파일 확인
                  </S.Btn>
                </S.ProdDetailDesignBtns>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.ProdDetailPayButton onClick={handleSendOrder}>
                주문하기
              </S.ProdDetailPayButton>
              <S.ProdDetailPayButton onClick={handleSendCart}>
                장바구니에 담기
              </S.ProdDetailPayButton>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Divider />
          <Table
            sx={{
              marginBottom: "24px",
            }}
            size="small"
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ border: "none", fontFamily: "Noto Sans KR" }}>
                  인쇄비
                </TableCell>
                <TableCell
                  sx={{ border: "none", fontFamily: "Noto Sans KR" }}
                  align="right"
                >
                  {formatNumber(printPrice)}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none", fontFamily: "Noto Sans KR" }}>
                  후가공
                </TableCell>
                <TableCell
                  sx={{ border: "none", fontFamily: "Noto Sans KR" }}
                  align="right"
                >
                  {formatNumber(optionPrice)}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none", fontFamily: "Noto Sans KR" }}>
                  부가세
                </TableCell>
                <TableCell
                  sx={{ border: "none", fontFamily: "Noto Sans KR" }}
                  align="right"
                >
                  {formatNumber(taxPrice)}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "", fontFamily: "Noto Sans KR" }}>
                  총합
                </TableCell>
                <TableCell
                  sx={{ border: "", fontFamily: "Noto Sans KR" }}
                  align="right"
                >
                  {formatNumber(printPrice + optionPrice + taxPrice)}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box p={1.5}>
            <Button
              variant="contained"
              startIcon={<UploadFileOutlined />}
              onClick={handleUploadDesign}
              fullWidth
            >
              파일업로드
            </Button>
          </Box>

          {designCheck && (
            <>
              <Button
                endIcon={<Pageview />}
                sx={{
                  width: "100%",
                  margin: "0 auto",
                }}
                onClick={() => {
                  window.open(designFile);
                }}
              >
                {designFileName}
              </Button>
              <Box p={1.5}>
                <Button
                  variant="contained"
                  startIcon={<ShoppingCart />}
                  onClick={handleSendCart}
                  fullWidth
                >
                  장바구니
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Payment />}
                  onClick={handleSendOrder}
                  sx={{ marginTop: "12px" }}
                  fullWidth
                >
                  주문하기
                </Button>
              </Box>
            </>
          )}
          {/* 
          <S.ProdDetailPayButton onClick={handleSendOrder}>
            주문하기
          </S.ProdDetailPayButton>
          <S.ProdDetailPayButton onClick={handleSendCart}>
            장바구니에 담기
          </S.ProdDetailPayButton> */}
        </>
      )}
    </>
  );
};

export default ProdPrice;
